import { handleJSON, handleText } from './common';
import { BooleanResponse } from '../model/BooleanResponse';
import { CaptchaResponse } from '../model/CaptchaResponse';
import { LoginRequest } from '../model/LoginRequest';
import { User } from '../model/User';

export class AuthApi {
    async validateReCaptcha(token: string, signal?: AbortSignal): Promise<BooleanResponse> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/captcha/validateReCaptcha', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: token,
                signal
            });

            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return {
                    result: false
                };
            }
            throw e;
        }
    }

    async generateCaptcha(signal?: AbortSignal): Promise<CaptchaResponse> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/captcha/generate', {
                method: 'POST',
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return { graphics: null, audio: null };
            }
            throw e;
        }
    }

    async anonymousLogin(captchaAnswer: string): Promise<boolean> {
        const res = await fetch(ENV.BASE_API_PATH + '/auth/anonymous_login', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: captchaAnswer,
            method: 'POST'
        });
        const textRes = await handleText(res);
        return textRes === 'CAPTCHA_VALID';
    }

    async login(loginRequest: LoginRequest): Promise<string> {
        const res = await fetch(ENV.BASE_API_PATH + '/auth/login', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                ...loginRequest
            }),
            method: 'POST'
        });
        return await handleText(res);
    }

    async logout(): Promise<string | void> {
        const res = await fetch(ENV.BASE_API_PATH + '/auth/logout', {
            method: 'POST'
        });
        return await handleText(res);
    }

    async user(signal?: AbortSignal): Promise<User | null> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/auth/user', {
                method: 'POST',
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }
}
