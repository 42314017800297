import { SUBSTITUTE_DATE } from './contants';
import { isBlank } from './string';

export const formatddmmyyyyToIsoDate = (date: string | null) => {
    if (date) {
        const [day, month, year] = date.split('.');
        return `${year}-${month}-${day}`;
    } else return null;
};

export const formatIsoDateToddmmyyyy = (dateValue: string | undefined): string | undefined => {
    if (isBlank(dateValue)) {
        return undefined;
    }
    if (dateValue?.length === 10) {
        const splited = dateValue.split('-');
        return `${splited[2]}.${splited[1]}.${splited[0]}`;
    }
};

export const parseToDate = (s: string | undefined | null): Date | null => {
    if (s === undefined || s === null || s.trim() === SUBSTITUTE_DATE) {
        return null;
    }

    if (s.length === 10) {
        const dateParts = s.split('.');
        return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], 0, 0, 0, 0);
    } else if (s.length === 19) {
        const dateTimeParts = s.split(' ');
        const dateParts = dateTimeParts[0].split('.');
        const timeParts = dateTimeParts[1].split(':');
        return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], +timeParts[2], 0);
    } else {
        throw new Error('Invalid date format');
    }
};

export const isPastDate = (s: string | undefined | null): boolean => {
    const date = parseToDate(s);
    if (date) {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        return date.getTime() < now.getTime();
    }
    return false;
};

/** Return true if date is filled and it is not '01.01.1800' */
export const isDateFilled = (date?: string): boolean => {
    return !!date && date !== SUBSTITUTE_DATE;
};

/** Return value if date is filled or if date is not '01.01.1800' */
export const filterSubstituteDate = (date?: string): string | undefined => {
    if (!!date && date.trim() !== SUBSTITUTE_DATE) {
        return date.trim();
    }
};
