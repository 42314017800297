import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { OrganizationResult, OrganizationType } from '../api';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { TableHeaderCol } from '@mc-ui/idsk-react-components';
import { decode } from '../utils/string';
import { CURRENT, WITH_HISTORY } from '../utils/contants';
import { Link } from 'react-router-dom';
import OrganizationResultsFilter from '../components/organizationSearch/OrganizationResultsFilter';
import { FilterField, OrganizationResultKeys } from '../utils/organizationResult';
import { OrganizationUnitType } from '../api/model/OrganizationUnitType';
import { filterSubstituteDate } from '../utils/date';

const defaultFilterValues = {
    orgIdentifierValue: '',
    orgNameFullName: '',
    addrMunicipality: '',
    orgEstablishmentDate: '',
    orgTerminationDate: '',
    historical: false
};

const getOrganizationDetailLink = (orgResult: OrganizationResult, showHistorical: boolean) => {
    if (orgResult.organizationType === OrganizationType.ORG_UNIT) {
        if (orgResult.organizationUnitType === OrganizationUnitType.OPERATION) {
            return (
                <Link
                    to={`/organization/${orgResult.mainOrganizationId}/${showHistorical ? WITH_HISTORY : CURRENT}/operation/${
                        orgResult.id
                    }`}
                    className='govuk-link'
                >
                    {decode(orgResult.orgNameFullName)}
                </Link>
            );
        } else {
            return (
                <Link
                    to={`/organization/${orgResult.mainOrganizationId}/${showHistorical ? WITH_HISTORY : CURRENT}/organizationUnit/${
                        orgResult.id
                    }`}
                    className='govuk-link'
                >
                    {decode(orgResult.orgNameFullName)}
                </Link>
            );
        }
    } else {
        return (
            <Link to={`/organization/${orgResult.id}/${showHistorical ? WITH_HISTORY : CURRENT}`} className='govuk-link'>
                {decode(orgResult.orgNameFullName)}
            </Link>
        );
    }
};

type useOrganizationResultsFilterReturnObject = {
    methods: UseFormReturn<Partial<OrganizationResult>, any, undefined>;
    headerCols: TableHeaderCol<OrganizationResult>[];
    filterContent: JSX.Element;
    filterCount: number;
    removeAllHandler: () => void;
    removeOneHandler: (key: string) => void;
    filter: FilterField[];
    filterForm: Partial<OrganizationResult>;
};

type useOrganizationResultsFilterHook = (
    showHistorical: boolean,
    wide: boolean,
    initialFilter?: FilterField[],
    initialValues?: Partial<OrganizationResult>
) => useOrganizationResultsFilterReturnObject;

const useOrganizationResultsFilter: useOrganizationResultsFilterHook = (showHistorical, wide, initialFilter, initialValues) => {
    const { t } = useTranslation();

    const [initFilter] = useState(
        initialFilter
            ? initialFilter.filter((ff) => {
                  if (showHistorical === false) {
                      return true;
                  }
                  return ff.key !== 'historical';
              })
            : []
    );
    const [initValues] = useState(
        initialValues
            ? {
                  ...initialValues,
                  historical: showHistorical ? initialValues.historical : undefined
              }
            : {}
    );

    const [filter, setFilter] = useState<FilterField[]>(initFilter);
    const [filterForm, setFilterForm] = useState<Partial<OrganizationResult>>(initValues);

    const methods = useForm<Partial<OrganizationResult>>({
        defaultValues: defaultFilterValues,
        values: initValues
    });

    const headerCols = useMemo<TableHeaderCol<OrganizationResult>[]>(() => {
        if (wide) {
            return [
                {
                    label: t('orgSearchResults.table.header.columns.orgIdentifierValue'),
                    name: 'orgIdentifierValue',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return decode(row.orgIdentifierValue);
                    }
                },
                {
                    label: t('orgSearchResults.table.header.columns.orgNameFullName'),
                    name: 'orgNameFullName',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return getOrganizationDetailLink(row, showHistorical);
                    }
                },
                {
                    label: t('orgSearchResults.table.header.columns.addrMunicipality'),
                    name: 'addrMunicipality',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return decode(row.addrMunicipality);
                    }
                },
                {
                    label: t('orgSearchResults.table.header.columns.orgEstablishmentDate'),
                    name: 'orgEstablishmentDate',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return filterSubstituteDate(row.orgEstablishmentDate);
                    }
                },
                {
                    label: t('orgSearchResults.table.header.columns.orgTerminationDate'),
                    name: 'orgTerminationDate',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return filterSubstituteDate(row.orgTerminationDate);
                    }
                },
                {
                    label: t('orgSearchResults.table.header.columns.sourceRegister'),
                    name: 'sourceRegister',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return decode(row.sourceRegister);
                    }
                },
                {
                    label: t('orgSearchResults.table.header.columns.orgLastActualizationDate'),
                    name: 'orgLastActualizationDate',
                    numeric: false,
                    sortable: true
                }
            ];
        } else {
            return [
                {
                    label: t('orgSearchResults.table.header.columns.orgIdentifierValue'),
                    name: 'orgIdentifierValue',
                    numeric: false,
                    sortable: true
                },
                {
                    label: t('orgSearchResults.table.header.columns.orgNameFullName'),
                    name: 'orgNameFullName',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return getOrganizationDetailLink(row, showHistorical);
                    }
                },
                {
                    label: t('orgSearchResults.table.header.columns.orgTerminationDate'),
                    name: 'orgTerminationDate',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return filterSubstituteDate(row.orgTerminationDate);
                    }
                }
            ];
        }
    }, [wide, t, showHistorical]);

    const { watch, reset, setValue } = methods;

    const filterCount = Object.values(watch()).filter((v) => !!v).length;

    const onFilter = (filter: FilterField[], filterForm: Partial<OrganizationResult>) => {
        setFilter(filter);
        setFilterForm(filterForm);
    };

    const filterContent = (
        <FormProvider {...methods}>
            <OrganizationResultsFilter showHistorical={showHistorical} filterCount={filterCount} onFilter={onFilter} />
        </FormProvider>
    );

    const removeAllHandler = useCallback(() => {
        setFilter([]);
        reset(defaultFilterValues);
    }, [reset]);

    const removeOneHandler = useCallback(
        (key: string) => {
            setFilter((prev) => {
                return prev.filter((f) => f.key !== key);
            });
            setValue(key as OrganizationResultKeys, '');
        },
        [setValue]
    );

    return {
        methods,
        headerCols,
        filterContent,
        filterCount,
        removeAllHandler,
        removeOneHandler,
        filter,
        filterForm
    };
};

export default useOrganizationResultsFilter;
