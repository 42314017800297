import { BooleanResponse } from '../model';
import { ExtractRequestResponse } from '../model/ExtractRequestResponse';
import { ExtractType } from '../model/ExtractType';
import { RequestExtract } from '../model/RequestExtract';
import { RequestForSigning } from '../model/RequestForSigning';
import { SignedRequest } from '../model/SignedRequest';
import { StringResponse } from '../model/StringResponse';
import { handleJSON } from './common';

export type SubmittedRequestResponse = {
    response: 'SUCCESSFUL' | 'FAILED_TO_SAVE_OUTGOING';
};

export class RequestExtractApi {
    async generateExtract(organizationId: number, type: ExtractType, signal?: AbortSignal): Promise<ExtractRequestResponse> {
        const res = await fetch(ENV.BASE_API_PATH + '/requestExtract/generateExtractAsync', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                organizationId,
                type
            }),
            signal
        });
        return await handleJSON(res);
    }

    async extractStatus(taskId: number, signal?: AbortSignal): Promise<StringResponse> {
        const res = await fetch(ENV.BASE_API_PATH + '/requestExtract/extractStatus/' + taskId, {
            method: 'POST',
            signal
        });
        return await handleJSON(res);
    }

    async isGenericRegister(orgUnitId: number, signal?: AbortSignal): Promise<BooleanResponse> {
        const res = await fetch(ENV.BASE_API_PATH + '/requestExtract/isGenericRegister', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                orgUnitId: orgUnitId.toString()
            }),
            signal
        });
        return await handleJSON(res);
    }

    async fillRequest(organizationId: number, signal?: AbortSignal): Promise<RequestExtract> {
        const res = await fetch(ENV.BASE_API_PATH + '/requestExtract/fillRequest', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                organizationId: organizationId.toString()
            }),
            signal
        });
        return await handleJSON(res);
    }

    async sendRequestToEDesk(requestExtract: RequestExtract, signal?: AbortSignal): Promise<SubmittedRequestResponse> {
        const res = await fetch(ENV.BASE_API_PATH + '/requestExtract/sendRequestToEDesk/query', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(requestExtract),
            signal
        });
        return await handleJSON(res);
    }

    async prepareRequestForESignature(requestExtract: RequestExtract, signal?: AbortSignal): Promise<RequestForSigning> {
        const res = await fetch(ENV.BASE_API_PATH + '/requestExtract/prepareRequestForESignature/query', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(requestExtract),
            signal
        });
        return await handleJSON(res);
    }

    async sendSignedRequestToEDesk(signetRequestExtract: SignedRequest, signal?: AbortSignal): Promise<SubmittedRequestResponse> {
        const res = await fetch(ENV.BASE_API_PATH + '/requestExtract/sendSignedRequestToEDesk/query', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(signetRequestExtract),
            signal
        });
        return await handleJSON(res);
    }
}
