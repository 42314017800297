import { useContext, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { Organization, SearchApi, UserRole } from '../api';
import { AuthContext } from '../store/auth-context';
import { WITH_HISTORY } from '../utils/contants';
import { isNotBlank } from '../utils/string';

const searchApi = new SearchApi();

type useOrganizationReturnObject = {
    isLoading: boolean;
    organization: Organization | null;
    hasError: boolean;
};

type useOrganizationHook = (isUnit?: boolean) => useOrganizationReturnObject;

const useOrganization: useOrganizationHook = (isUnit = false) => {
    const queryClient = useQueryClient();
    const { organizationUnitId, organizationId, withHistory } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [hasError, setHasError] = useState<boolean>(false);
    const { protect, hasRole } = useContext(AuthContext);

    const isRpoRead = hasRole(UserRole.RPO_READ);

    useEffect(() => {
        const hasId = isUnit ? isNotBlank(organizationUnitId) : isNotBlank(organizationId);
        if (hasId) {
            const numericId = Number(isUnit ? organizationUnitId : organizationId);
            if (!isNaN(numericId)) {
                setIsLoading(true);
                setHasError(false);
                protect(() =>
                    queryClient
                        .fetchQuery({
                            queryKey: ['organization', numericId, withHistory === WITH_HISTORY, !isRpoRead],
                            queryFn: ({ queryKey, signal }) => {
                                return searchApi.getOrganizationByIdPublic(
                                    queryKey[1] as number,
                                    queryKey[2] as boolean,
                                    queryKey[3] as boolean,
                                    signal
                                );
                            }
                        })
                        .then((org) => {
                            setOrganization(org);
                        })
                        .catch((e) => {
                            if (!e.revert) {
                                setHasError(true);
                            }
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                );
            }
        }
    }, [protect, organizationId, organizationUnitId, withHistory, isUnit, queryClient, isRpoRead]);

    return {
        isLoading,
        organization,
        hasError
    };
};

export default useOrganization;
