import { OrganizationSearchCriteria } from '../api';
import { PoRequestSearchCriteria } from '../api/model/PoRequestSearchCriteria';

export const objectToSearchParams = <T extends object>(criteria: T): URLSearchParams => {
    const params = new URLSearchParams();
    Object.keys(criteria).forEach((key) => {
        const val = criteria[key as keyof T];
        if (val) {
            params.append(key, val.toString());
        }
    });
    return params;
};

export const searchParamsToOrganizationSearchCriteria = (params: URLSearchParams): OrganizationSearchCriteria => {
    const legalFormIdString = params.get('legalFormId');

    const physicalAddressRegNumberString = params.get('physicalAddressRegNumber');
    const legalStatusString = params.get('legalStatus');
    const stakeholderTypeIdString = params.get('stakeholderTypeId');
    const statutoryTypeIdString = params.get('statutoryTypeId');
    const zozcinString = params.get('zozcin');
    const sknace5String = params.get('sknace5');
    const esu2010String = params.get('esu2010');

    const criteria: OrganizationSearchCriteria = {
        organizationIdentifier: params.get('organizationIdentifier') ?? undefined,
        organizationFullName: params.get('organizationFullName') ?? undefined,
        legalFormId: legalFormIdString ? +legalFormIdString : undefined,
        fullTextSearch: params.get('fullTextSearch') === 'true',
        showHistorical: params.get('showHistorical') === 'true',
        showOrganizationUnit: params.get('showOrganizationUnit') === 'true',
        corporateBodyAlternativeName: params.get('corporateBodyAlternativeName') ?? undefined,
        physicalAddressMunicipality: params.get('physicalAddressMunicipality') ?? undefined,
        physicalAddressStreet: params.get('physicalAddressStreet') ?? undefined,
        physicalAddressBuildingNum: params.get('physicalAddressBuildingNum') ?? undefined,
        stakeholderName: params.get('stakeholderName') ?? undefined,
        stakeholderSurname: params.get('stakeholderSurname') ?? undefined,
        statutoryName: params.get('statutoryName') ?? undefined,
        statutorySurname: params.get('statutorySurname') ?? undefined,
        establishedFrom: params.get('establishedFrom') ?? undefined,
        establishedTo: params.get('establishedTo') ?? undefined,
        terminatedFrom: params.get('terminatedFrom') ?? undefined,
        terminatedTo: params.get('terminatedTo') ?? undefined,
        physicalAddressRegNumber: physicalAddressRegNumberString ? +physicalAddressRegNumberString : undefined,
        legalStatus: legalStatusString ? +legalStatusString : undefined,
        stakeholderTypeId: stakeholderTypeIdString ? +stakeholderTypeIdString : undefined,
        statutoryTypeId: statutoryTypeIdString ? +statutoryTypeIdString : undefined,
        zozcin: !zozcinString ? undefined : zozcinString.split(',').map((v) => +v),
        sknace5: !sknace5String ? undefined : sknace5String.split(',').map((v) => +v),
        esu2010: !esu2010String ? undefined : esu2010String.split(',').map((v) => +v)
    };

    return criteria;
};

export const searchParamsToPoRequestSearchCriteriaQuery = (params: URLSearchParams): PoRequestSearchCriteria['query'] => {
    const poOrganizationIdentifier = params.get('poOrganizationIdentifier');
    const poRequestStatusId = params.get('poRequestStatusId');
    const poRequestTypeId = params.get('poRequestTypeId');
    const upvsICO = params.get('upvsICO');
    const poName = params.get('poName');
    const poRequestNumber = params.get('poRequestNumber');
    const poRequestNumberYear = params.get('poRequestNumberYear');

    const criteria: PoRequestSearchCriteria['query'] = {
        poOrganizationIdentifier: poOrganizationIdentifier ?? '',
        poRequestStatusId: poRequestStatusId ? +poRequestStatusId : NaN,
        poRequestTypeId: poRequestTypeId ? +poRequestTypeId : undefined,
        upvsICO: upvsICO ?? undefined,
        poName: poName ?? undefined,
        poRequestNumber: poRequestNumber ? +poRequestNumber : undefined,
        poRequestNumberYear: poRequestNumberYear ? +poRequestNumberYear : undefined
    };

    return criteria;
};
