import { Autocomplete as IdskAutocomplete } from '@mc-ui/idsk-react-components';

import { withReactHookFormControlled } from '../hoc/withReactHookFormControlled';

export const Autocomplete = withReactHookFormControlled(IdskAutocomplete, {
    hideOptions: 'autocomplete.hideOptions',
    loading: 'autocomplete.loading',
    noResults: 'autocomplete.noResults',
    openOptions: 'autocomplete.openOptions'
});
