import { Button, Filter, GridCol, GridRow } from '@mc-ui/idsk-react-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from '../form/Input';
import { DatePicker } from '../form/DatePicker';
import { Checkbox } from '../form/Checkbox';
import { useTranslation } from 'react-i18next';
import { OrganizationResult } from '../../api';
import { FilterField, OrganizationResultKeys } from '../../utils/organizationResult';

const OrganizationResultsFilter: React.FC<{
    showHistorical: boolean;
    onFilter: (criteria: FilterField[], data: Partial<OrganizationResult>) => void;
    filterCount: number;
}> = ({ onFilter, filterCount, showHistorical }) => {
    const { t } = useTranslation();
    const { handleSubmit } = useFormContext();

    const onFilterHandler = handleSubmit((data: Partial<OrganizationResult>) => {
        const criteria: FilterField[] = Object.keys(data)
            .filter((k) => !!data[k as OrganizationResultKeys])
            .map((k) => {
                if (k === 'historical') {
                    return {
                        key: k as OrganizationResultKeys,
                        value: t('orgSearchResults.table.filter.hideHistorical')
                    };
                } else {
                    return {
                        key: k as OrganizationResultKeys,
                        value: data[k as OrganizationResultKeys]!.toString()
                    };
                }
            });
        onFilter(criteria, data);
    });

    return (
        <form noValidate onSubmit={onFilterHandler}>
            <GridRow additionalClass='idsk-table-filter__filter-inputs'>
                <GridCol size='1/2'>
                    <Input label={t('orgSearchResults.table.header.columns.orgIdentifierValue')} name='orgIdentifierValue' />
                </GridCol>
                <GridCol size='1/2'>
                    <Input label={t('orgSearchResults.table.header.columns.orgNameFullName')} name='orgNameFullName' />
                </GridCol>
                <GridCol size='1/2'>
                    <Input label={t('orgSearchResults.table.header.columns.addrMunicipality')} name='addrMunicipality' />
                </GridCol>
                {/* show checkbox to hide historical only if it was searched with historical data */}
                {showHistorical && (
                    <GridCol size='1/2' additionalClass='rpo-checkbox-padding'>
                        <Checkbox label={t('orgSearchResults.table.filter.hideHistorical')} name='historical' />
                    </GridCol>
                )}
            </GridRow>
            <Filter
                title={t('orgSearchResults.table.filter.datesSectionTitle')}
                openLabel={t('orgSearchResults.table.filter.openSectionLabel')}
                closeLabel={t('orgSearchResults.table.filter.closeSectionLabel')}
                section={true}
            >
                <GridRow additionalClass='idsk-table-filter__filter-inputs'>
                    <GridCol size='1/2'>
                        <DatePicker label={t('orgSearchResults.table.header.columns.orgEstablishmentDate')} name='orgEstablishmentDate' />
                    </GridCol>
                    <GridCol size='1/2'>
                        <DatePicker label={t('orgSearchResults.table.header.columns.orgTerminationDate')} name='orgTerminationDate' />
                    </GridCol>
                </GridRow>
            </Filter>
            <Button type='submit' variant='primary' disabled={filterCount === 0}>
                <span>
                    Filter (<span className='count'>{filterCount}</span>)
                </span>
            </Button>
        </form>
    );
};

export default OrganizationResultsFilter;
