import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mc-ui/idsk-react-components';

const License: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <hr />
            <Typography type='h2'>{t('license.title')}</Typography>
            <Typography type='normal'>{t('license.content')}</Typography>
            <Typography type='normal'>{t('license.content2')}</Typography>
        </>
    );
};

export default License;
