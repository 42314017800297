import { MandatoryPersonCriteria } from '../model/MandatoryPersonCriteria';
import { MandatoryPersonNameResults } from '../model/MandatoryPersonNameResults';
import { handleJSON } from './common';

export class MandatoryPersonApi {
    async getMandatoryPersonNames(criteria: MandatoryPersonCriteria, signal?: AbortSignal): Promise<MandatoryPersonNameResults> {
        const res = await fetch(ENV.BASE_API_PATH + '/mandatoryPerson/mandatoryPersonNames/query', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                ...criteria,
                query: {
                    ...criteria.query,
                    name: criteria.query.name ? criteria.query.name : '*'
                }
            }),
            signal
        });
        return await handleJSON(res);
    }
}
