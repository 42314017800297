import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, GridCol, GridRow, Inset, Typography } from '@mc-ui/idsk-react-components';

import Loader from '../../components/common/Loader';
import { CURRENT, WITH_HISTORY } from '../../utils/contants';
import OrganizationAttributes from '../../components/organizationSearch/OrganizationAttributes';
import useOrganization from '../../hooks/useOrganization';
import { decode, isNotBlank } from '../../utils/string';
import { AuthContext } from '../../store/auth-context';
import { OrganizationType, UserRole } from '../../api';
import { RequestExtractApi } from '../../api/apis/RequestExtractApi';
import { ExtractType } from '../../api/model/ExtractType';

const requestExtractApi = new RequestExtractApi();

type OrganizationDetailProps = {
    isUnit?: false;
    isOperation?: false;
};

type OrganizationUnitDetailProps = {
    isUnit: true;
    isOperation?: false;
};

type OrganizationOperationDetailProps = {
    isUnit?: false;
    isOperation: true;
};

const OrganizationDetail: React.FC<OrganizationDetailProps | OrganizationUnitDetailProps | OrganizationOperationDetailProps> = ({
    isUnit = false,
    isOperation = false
}) => {
    const { withHistory } = useParams();
    const { hasRole, user } = useContext(AuthContext);

    const [generatingExtract, setGeneratingExtract] = useState(false);
    const [generatingExtractError, setGeneratingExtractError] = useState(false);
    const [isGenericRegister, setIsGenericRegister] = useState(false);
    const extractAbort = useRef<AbortController>();
    const isRpoOvm = hasRole(UserRole.RPO_OVM);
    const isRpoOvmHistory = hasRole(UserRole.RPO_OVM_HISTORY);

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const { isLoading, organization, hasError } = useOrganization(isUnit);

    const handleHistory = useCallback(() => {
        // just modify existing path
        const newPath = pathname.includes(WITH_HISTORY) ? pathname.replace(WITH_HISTORY, CURRENT) : pathname.replace(CURRENT, WITH_HISTORY);
        navigate(newPath);
    }, [navigate, pathname]);

    const handleOrganizationUnits = useCallback(() => {
        navigate('organizationUnit'); // relative path
    }, [navigate]);

    const handleOperations = useCallback(() => {
        navigate('operation'); // relative path
    }, [navigate]);

    const handleMainOrganization = useCallback(() => {
        navigate('../../'); // relative path
    }, [navigate]);

    const handleExtractRequest = useCallback(() => {
        navigate('extractRequest'); // relative path
    }, [navigate]);

    const noIdentifierAvailable = organization?.ipo.length === 0;
    const validIdentifier = organization?.ipo[0]?.value ?? '';
    const validIdentifierNumeric = isNotBlank(validIdentifier) && !isNaN(+validIdentifier);
    const hasOrganizationUnit = organization?.hasOrganizationUnit ?? false;
    const hasOperations = organization?.hasOperations ?? false;
    const mainOrganizationId = organization?.mainOrganizationId ?? null;

    // TODO overit preco pada requestExtractApi.generateExtract a otestovat ked to bude fungovat
    const handleExtract = useCallback(
        async (history: boolean) => {
            try {
                extractAbort.current = new AbortController();
                setGeneratingExtract(true);
                setGeneratingExtractError(false);
                let extractType: ExtractType | undefined;
                if (organization?.organizationType === OrganizationType.MAIN) {
                    if (history) {
                        extractType = ExtractType.EXTRACT_WITH_HISTORY;
                    } else {
                        extractType = ExtractType.EXTRACT;
                    }
                }
                if (organization?.organizationType === OrganizationType.ORG_UNIT) {
                    if (history) {
                        extractType = ExtractType.EXTRACT_OU_WITH_HISTORY;
                    } else {
                        extractType = ExtractType.EXTRACT_OU;
                    }
                }
                if (organization && extractType) {
                    // process and save taskId or show error
                    const extractTask = await requestExtractApi.generateExtract(organization.id, extractType, extractAbort.current.signal);

                    if (!extractAbort.current.signal.aborted && extractTask.longTimeGeneration) {
                        await new Promise(async (resolve, reject) => {
                            const wait = async (taskId: number) => {
                                setTimeout(async () => {
                                    const response = await requestExtractApi.extractStatus(taskId, extractAbort.current?.signal);
                                    if (extractAbort.current?.signal.aborted) {
                                        reject({ name: 'AbortError' });
                                    } else if (response.response === 'FINISHED') {
                                        resolve(true);
                                    } else if (response.response === 'FAILED') {
                                        reject();
                                    } else {
                                        wait(taskId);
                                    }
                                }, 2000);
                            };

                            wait(extractTask.taskId);
                        });
                    }
                    // TODO overit ci funguje toto stahovanie
                    if (!extractAbort.current.signal.aborted) {
                        const tempLink = document.createElement('a');
                        tempLink.href = `${ENV.BASE_API_PATH}/requestExtract/downloadExtractById/${extractTask.id}/${validIdentifier}`;
                        tempLink.click();
                    }
                }
            } catch (e: any) {
                if (e?.name !== 'AbortError') {
                    setGeneratingExtractError(true);
                }
            } finally {
                setGeneratingExtract(false);
                extractAbort.current = undefined;
            }
        },
        [organization, validIdentifier]
    );

    const title = useMemo(() => {
        if (isOperation) {
            return t('orgDetail.operationDetail');
        }
        if (isUnit) {
            return t('orgDetail.orgUnitDetail');
        }
        if (noIdentifierAvailable) {
            return t('orgDetail.orgIdentifierValueMissing');
        }
        return t('orgDetail.orgIdentifierValue') + ' ' + decode(validIdentifier);
    }, [isOperation, isUnit, t, validIdentifier, noIdentifierAvailable]);

    const cancelExtractHandler = useCallback(() => {
        if (extractAbort) {
            extractAbort.current?.abort();
        }
        setGeneratingExtract(false);
        setGeneratingExtractError(false);
    }, [extractAbort]);

    useEffect(() => {
        const controller = new AbortController();
        try {
            if (organization?.organizationType === OrganizationType.ORG_UNIT && user?.isUPVS === true) {
                requestExtractApi
                    .isGenericRegister(organization.id, controller.signal)
                    .then((res) => res.result)
                    .then(setIsGenericRegister);
            } else {
                setIsGenericRegister(false);
            }
        } catch (e: any) {
            if (e.name !== 'AbortError') {
                throw e;
            }
        }
        return () => {
            controller.abort();
        };
    }, [organization, user]);

    return (
        <Loader
            title={title}
            isLoading={isLoading || generatingExtract}
            hasError={hasError || generatingExtractError}
            onCancel={generatingExtract || generatingExtractError ? cancelExtractHandler : '/'}
        >
            <Typography type='h1'>{title}</Typography>
            {withHistory === WITH_HISTORY && <Inset>{t('orgDetail.historicalIsInformativeOnly')}</Inset>}
            <GridRow>
                <GridCol size='full'>
                    <div className='rpo-org-detail__action-btns'>
                        <div className='rpo-flex-1'></div>
                        {!isUnit && !isOperation && hasOrganizationUnit && (
                            <Button variant='secondary' type='button' onClick={handleOrganizationUnits}>
                                {t('orgDetail.organizationUnits')}
                            </Button>
                        )}
                        {!isUnit && !isOperation && hasOperations && (
                            <Button variant='secondary' type='button' onClick={handleOperations}>
                                {t('orgDetail.operations')}
                            </Button>
                        )}
                        {(isUnit || isOperation) && mainOrganizationId !== null && (
                            <Button variant='secondary' type='button' onClick={handleMainOrganization}>
                                {t('orgDetail.mainOrganization')}
                            </Button>
                        )}
                        {validIdentifierNumeric &&
                            user?.isUPVS === true &&
                            (organization?.organizationType === OrganizationType.MAIN || isGenericRegister) && (
                                <Button variant='secondary' type='button' onClick={handleExtractRequest}>
                                    {t('orgDetail.extractRequest')}
                                </Button>
                            )}
                        {isRpoOvm &&
                            (organization?.organizationType === OrganizationType.MAIN ||
                                organization?.organizationType === OrganizationType.ORG_UNIT) && (
                                <Button variant='secondary' type='button' onClick={() => handleExtract(false)}>
                                    {t('orgDetail.extract')}
                                </Button>
                            )}
                        {isRpoOvmHistory &&
                            (organization?.organizationType === OrganizationType.MAIN ||
                                organization?.organizationType === OrganizationType.ORG_UNIT) && (
                                <Button variant='secondary' type='button' onClick={() => handleExtract(true)}>
                                    {t('orgDetail.extractWithHistory')}
                                </Button>
                            )}
                        <Button variant='secondary' type='button' onClick={handleHistory}>
                            {t(withHistory === WITH_HISTORY ? 'orgDetail.hideHistorical' : 'orgDetail.showHistorical')}
                        </Button>
                    </div>
                </GridCol>
            </GridRow>
            <OrganizationAttributes organization={organization} />
        </Loader>
    );
};

export default OrganizationDetail;
