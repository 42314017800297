import {
    Button,
    ButtonGroup,
    GridCol,
    GridRow,
    RadioGroup,
    SuccessInfo,
    SummaryCard,
    SummaryList,
    Typography
} from '@mc-ui/idsk-react-components';
import React, { ReactElement, useCallback, useId, useMemo, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Input } from '../../components/form/Input';
import { RadioItem } from '../../components/form/RadioItem';
import { PoRequest } from '../../api/model/PoRequest';
import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';
import * as yup from 'yup';
import { PoRequestApi } from '../../api/apis/PoRequestApi';
import { decode, formatBytes, mimeToExt } from '../../utils/string';
import Loader from '../../components/common/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import { faCircleNotch, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import useSigner from '../../hooks/useSigner';
import { AttachmentApi } from '../../api/apis/AttachmentApi';
import useCachedCodelist, { CodelistAcronymName } from '../../hooks/useCachedCodelist';

const attachmentSchema = yup.object({
    id: yup.number().emptyAsUndefined(),
    name: yup.string().trim().defined(),
    path: yup.string().trim().defined(),
    typeId: yup.number().emptyAsUndefined(),
    dateOfCreate: yup.string().trim().emptyAsUndefined(),
    temporary: yup.boolean()
});

const formDefaultValues = {
    poName: '',
    poOrganizationIdentifier: '',
    poNameSurname: '',
    poAccessTypeId: NaN,
    poAccessName: '',
    poUserName: '',
    poUserSurname: '',
    poTempAttachments: []
};

const poRequestApi = new PoRequestApi();
const attachmentApi = new AttachmentApi();

export type RequestType = 'registration' | 'deregistration' | 'reset';

type RequestProps = {
    type: RequestType;
};

const Request: React.FC<RequestProps> = ({ type }) => {
    const { t } = useTranslation();
    const { requestId } = useParams();

    if (requestId && isNaN(+requestId)) {
        throw new Error(t('mandatoryPerson.invalidId'));
    }

    const {
        id: cliAccessTypeAdministatorId,
        isFetching: isFetchingATA,
        isError: isErrorATA
    } = useCachedCodelist(CodelistAcronymName.ACCESS_TYPE, '1');
    const {
        id: cliAccessTypeSystemUserId,
        isFetching: isFetchingATS,
        isError: isErrorATS
    } = useCachedCodelist(CodelistAcronymName.ACCESS_TYPE, '0');
    const {
        id: cliRegisterApplStatusCompletingId,
        isFetching: isFetchingRASC,
        isError: isErrorRASC
    } = useCachedCodelist(CodelistAcronymName.REGISTER_APPL_STATUS, '3');
    const {
        id: cliAttachmentTypeRequestId,
        isFetching: isFetchingATR,
        isError: isErrorATR
    } = useCachedCodelist(CodelistAcronymName.ATTACHMENT_TYPE, '0');

    const isCodelistCodeFetching = isFetchingATA || isFetchingATS || isFetchingRASC || isFetchingATR;
    const isCodelistCodeError = isErrorATA || isErrorATS || isErrorRASC || isErrorATR;

    const schema = useMemo(() => {
        return yup.object({
            poName: yup.string().trim().defined().required(),
            poOrganizationIdentifier: yup.string().trim().defined().required(),
            poNameSurname: yup.string().trim().defined().required(),
            poAccessTypeId: yup.number().defined().required(),
            poUserName:
                type === 'registration'
                    ? yup
                          .string()
                          .trim()
                          .when('poAccessTypeId', {
                              is: cliAccessTypeAdministatorId,
                              then: (schema) => schema.required(),
                              otherwise: (schema) => schema.emptyAsUndefined()
                          })
                    : yup.string().trim().emptyAsUndefined(),
            poUserSurname:
                type === 'registration'
                    ? yup
                          .string()
                          .trim()
                          .when('poAccessTypeId', {
                              is: cliAccessTypeAdministatorId,
                              then: (schema) => schema.required(),
                              otherwise: (schema) => schema.emptyAsUndefined()
                          })
                    : yup.string().trim().emptyAsUndefined(),
            poAccessName: type === 'registration' ? yup.string().trim().emptyAsUndefined() : yup.string().trim().defined().required(),
            poTempAttachments: yup.array().of(attachmentSchema).emptyAsUndefined()
        });
    }, [cliAccessTypeAdministatorId, type]);

    const { signAsice, ditecErrorToString } = useSigner();
    const id = useId();
    const fileUploadRef = useRef<HTMLInputElement>(null);
    const fileInputId = 'fileinput' + id;
    const attUploadMsgId = 'uploadattachmentmsg' + id;
    const [loadingError, setLoadingError] = useState<string>();

    const [applicationNumberWithYear, setApplicationNumberWithYear] = useState<null | string>(null);
    const [isSigning, setIsSigning] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [defaultValues, setDefaultValues] = useState<PoRequest>(formDefaultValues);

    const [hasError, setHasError] = useState<boolean | string | ReactElement>(false);
    const navigate = useNavigate();

    const methods = useForm<PoRequest>({
        resolver: yupResolver(schema),
        defaultValues: async () => {
            if (requestId) {
                const poRequest = await poRequestApi.getPoRequest(+requestId).catch((e) => {
                    setLoadingError(t('mandatoryPerson.requestLoadingFailed'));
                });
                if (!poRequest) {
                    throw new Error(t('mandatoryPerson.requestLoadingFailed'));
                }
                const loaded = {
                    ...defaultValues,
                    ...poRequest,
                    poName: decode(poRequest?.poName ?? defaultValues.poName),
                    poOrganizationIdentifier: decode(poRequest?.poOrganizationIdentifier ?? defaultValues.poOrganizationIdentifier),
                    poAddress: decode(poRequest?.poAddress ?? defaultValues.poAddress), // Na gui sa nezobrazuje
                    poNameSurname: decode(poRequest?.poNameSurname ?? defaultValues.poNameSurname),
                    poAccessName: decode(poRequest?.poAccessName ?? defaultValues.poAccessName),
                    poUserName: decode(poRequest?.poUserName ?? defaultValues.poUserName),
                    poUserSurname: decode(poRequest?.poUserSurname ?? defaultValues.poUserSurname),
                    poNote: decode(poRequest?.poNote ?? defaultValues.poNote), // Na gui sa nezobrazuje
                    poTempAttachments:
                        poRequest.poTempAttachments?.map((att) => ({
                            ...att,
                            name: decode(att.name)
                        })) ?? defaultValues.poTempAttachments
                };

                setDefaultValues(loaded);
                return loaded;
            } else {
                const personData = await poRequestApi.getPersonDataUpvs().catch((e) => {
                    setLoadingError(t('mandatoryPerson.loadingFailed'));
                });
                const loaded = {
                    ...defaultValues,
                    poOrganizationIdentifier: decode(personData?.identifier ?? defaultValues.poOrganizationIdentifier),
                    poName: decode(personData?.pofullName ?? defaultValues.poName),
                    poNameSurname: decode(personData?.formattedName ?? defaultValues.poNameSurname)
                };
                setDefaultValues(loaded);
                return loaded;
            }
        }
    });

    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { errors, isLoading }
    } = methods;

    const { fields, append, remove } = useFieldArray({
        name: 'poTempAttachments',
        control,
        keyName: 'id'
    });

    const onSubmit = handleSubmit(async (data) => {
        if (cliRegisterApplStatusCompletingId) {
            try {
                setIsSigning(true);
                const saveFn =
                    !!requestId && cliRegisterApplStatusCompletingId === defaultValues.statusFK
                        ? poRequestApi.completeRequest
                        : type === 'registration'
                          ? poRequestApi.createRegistration
                          : type === 'deregistration'
                            ? poRequestApi.createDeregistration
                            : type === 'reset'
                              ? poRequestApi.createReset
                              : null;
                if (saveFn) {
                    const createKuvRegReturnObject = await saveFn(data);
                    if (createKuvRegReturnObject) {
                        setApplicationNumberWithYear(createKuvRegReturnObject.applicationNumberWithYear);
                        const signedXML = await signAsice(
                            {
                                xml: createKuvRegReturnObject.xmlForSigner,
                                xsd: createKuvRegReturnObject.xsdForSigner,
                                xslt: createKuvRegReturnObject.xsltForSigner,
                                namespaceUri: createKuvRegReturnObject.objectFormatIdentifier,
                                xsdUrl: createKuvRegReturnObject.xsdReferenceUri,
                                xsltUrl: createKuvRegReturnObject.xsltReferenceUri,
                                version: createKuvRegReturnObject.versionForm
                            },
                            t(`mandatoryPerson.${type}.title`)
                        );
                        await poRequestApi.signed({
                            id: createKuvRegReturnObject?.id,
                            signedXML
                        });
                        setIsSubmitted(true);
                    }
                } else {
                    throw new Error('Invalid request type!');
                }
            } catch (e: any) {
                setHasError(ditecErrorToString(e));
            } finally {
                setIsSigning(false);
            }
        }
    });

    const onReset = useCallback(() => {
        reset(defaultValues);
    }, [reset, defaultValues]);

    const poAccessTypeId = watch('poAccessTypeId');
    const poAccessTypeError = get(errors, 'poAccessTypeId');
    const poAccessTypeErrorMsg = poAccessTypeError && t('error.poAccessTypeRequired');

    const handleUpload = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files && cliAttachmentTypeRequestId) {
                try {
                    setIsUploading(true);
                    const uploadedFiles = Array.from(event.target.files).map((file) => {
                        return poRequestApi.uploadFile(file).then((f) => {
                            if (!f) {
                                return null;
                            }
                            return {
                                ...f,
                                temporary: true,
                                typeId: cliAttachmentTypeRequestId,
                                mime: file.type,
                                size: file.size,
                                id: new Date().getTime(),
                                name: decode(f.name)
                            };
                        });
                    });
                    const res = await Promise.all(uploadedFiles);

                    res.forEach((f) => {
                        if (f) {
                            append(f);
                        }
                    });

                    if (fileUploadRef && fileUploadRef.current) {
                        fileUploadRef.current.value = '';
                    }
                } finally {
                    setIsUploading(false);
                }
            }
        },
        [append, cliAttachmentTypeRequestId]
    );

    const removeAttachmentHandler = useCallback(
        (idx: number) => {
            remove(idx);
        },
        [remove]
    );

    const isSigningMessage = useMemo(() => {
        if (isSigning) {
            return (
                <>
                    <Typography type='normal'>
                        <Trans i18nKey='extractRequest.loadingSignerAppMsg'>
                            Počkajte pokiaľ sa spustí aplikácia pre kvalifikovaný elektronický podpis, potom postupujte podľa pokynov v
                            aplikácií. V prípade že aplikáciu ešte nemáte vo vašom počítači nainštalovanú, môžete tak urobiť podľa pokynov
                            na nasledujúcej stránke
                            <a className='govuk-body govuk-link' target='_blank' rel='noreferrer' href={ditec.config.downloadPage.url}>
                                {ditec.config.downloadPage.title}
                            </a>
                            . Po nainštalovaní budete musieť začať proces žiadosti odznova.
                        </Trans>
                    </Typography>
                    {applicationNumberWithYear && (
                        <Typography>
                            <Trans
                                i18nKey='mandatoryPerson.requestNumber'
                                values={{
                                    number: applicationNumberWithYear
                                }}
                            >
                                Referenčné číslo vašej žiadosti je
                                <b>{applicationNumberWithYear}</b>
                            </Trans>
                        </Typography>
                    )}
                </>
            );
        }
        return false;
    }, [isSigning, applicationNumberWithYear]);

    if (isSubmitted) {
        return (
            <>
                <SuccessInfo title={t('mandatoryPerson.submitted')}>
                    <span>
                        <Trans
                            i18nKey='mandatoryPerson.requestNumber'
                            values={{
                                number: applicationNumberWithYear
                            }}
                        >
                            Referenčné číslo vašej žiadosti je
                            <b>{applicationNumberWithYear}</b>
                        </Trans>
                    </span>
                    <br />
                    <Button onClick={() => navigate('/mandatoryPerson')} variant='link' additionalClasses='mc-success-info--button'>
                        {t('mandatoryPerson.close')}
                    </Button>
                </SuccessInfo>
                <Typography type='small'>
                    <FontAwesomeIcon icon={faInfoCircle} /> {t('extractRequest.messageInEdeskInfo')}
                </Typography>
            </>
        );
    }

    return (
        <Loader
            title={t(`mandatoryPerson.${type}.title`)}
            isLoading={isLoading || isSigningMessage || isCodelistCodeFetching}
            hasError={loadingError || hasError || isCodelistCodeError}
            onCancel={{
                pathname: '/mandatoryPerson'
            }}
        >
            <FormProvider {...methods}>
                <form noValidate onSubmit={onSubmit}>
                    <Typography type='h1'>{t(`mandatoryPerson.${type}.title`)}</Typography>
                    <Typography type='normal'>{t(`mandatoryPerson.${type}.description`)}</Typography>

                    {requestId !== undefined && (
                        <GridRow>
                            <GridCol size='1/2'>
                                <Input name='poRequestNumber' label={t('mandatoryPerson.poRequestNumber')} disabled />
                            </GridCol>
                        </GridRow>
                    )}

                    <div className='rpo-block'>
                        <GridRow>
                            <GridCol size='1/2'>
                                <Input name='poName' label={t('mandatoryPerson.poName')} disabled />
                            </GridCol>
                        </GridRow>
                        <GridRow>
                            <GridCol size='1/2'>
                                <Input
                                    name='poOrganizationIdentifier'
                                    label={t('mandatoryPerson.poOrganizationIdentifier')}
                                    hint={t('mandatoryPerson.poOrganizationIdentifierHint')}
                                    disabled
                                />
                            </GridCol>
                        </GridRow>
                        <GridRow>
                            <GridCol size='1/2'>
                                <Input name='poNameSurname' label={t('mandatoryPerson.poNameSurname')} disabled />
                            </GridCol>
                        </GridRow>
                    </div>

                    <Typography type='normal'>
                        <span className='rpo-warn'>*</span> <span className='rpo-secondary'>{t('mandatoryPerson.hint')}</span>
                    </Typography>

                    {type !== 'registration' && (
                        <GridRow>
                            <GridCol size='1/2'>
                                <Input
                                    name='poAccessName'
                                    label={t('mandatoryPerson.poAccessName')}
                                    required
                                    disabled={!!requestId && cliRegisterApplStatusCompletingId !== defaultValues.statusFK}
                                />
                            </GridCol>
                        </GridRow>
                    )}

                    {cliAccessTypeAdministatorId && cliRegisterApplStatusCompletingId && cliAccessTypeSystemUserId && (
                        <GridRow>
                            <GridCol size='1/2'>
                                <RadioGroup
                                    legend={t('mandatoryPerson.poAccessType')}
                                    legendHeadingType='h2'
                                    required
                                    error={poAccessTypeErrorMsg}
                                    legendSize='m'
                                >
                                    <RadioItem
                                        label={t('mandatoryPerson.poAccessTypeAdmin')}
                                        name='poAccessTypeId'
                                        value={cliAccessTypeAdministatorId}
                                        disabled={!!requestId}
                                    />
                                    {type === 'registration' && poAccessTypeId?.toString() === `${cliAccessTypeAdministatorId}` && (
                                        <div>
                                            <GridRow>
                                                <GridCol size='full'>
                                                    <Input
                                                        name='poUserName'
                                                        label={t('mandatoryPerson.poUserName')}
                                                        formGroupClasses='govuk-form-group rpo-conditional-group'
                                                        errorGroupClasses='govuk-form-group--error'
                                                        required
                                                        disabled={
                                                            !!requestId && cliRegisterApplStatusCompletingId !== defaultValues.statusFK
                                                        }
                                                    />
                                                </GridCol>
                                            </GridRow>
                                            <GridRow>
                                                <GridCol size='full'>
                                                    <Input
                                                        name='poUserSurname'
                                                        label={t('mandatoryPerson.poUserSurname')}
                                                        formGroupClasses='govuk-form-group rpo-conditional-group'
                                                        errorGroupClasses='govuk-form-group--error'
                                                        required
                                                        disabled={
                                                            !!requestId && cliRegisterApplStatusCompletingId !== defaultValues.statusFK
                                                        }
                                                    />
                                                </GridCol>
                                            </GridRow>
                                        </div>
                                    )}
                                    <RadioItem
                                        label={t('mandatoryPerson.poAccessTypeSystem')}
                                        name='poAccessTypeId'
                                        value={cliAccessTypeSystemUserId}
                                        disabled={!!requestId}
                                    />
                                </RadioGroup>
                            </GridCol>
                        </GridRow>
                    )}

                    <GridRow>
                        <GridCol size='1/2' additionalClass='rpo-mb-33'>
                            <Typography type='h3' forcedElementType='h2' additionalClasses='rpo-attachment-title'>
                                {t('mandatoryPerson.attachments.title')}
                            </Typography>
                            <label className='govuk-label' htmlFor={fileInputId}>
                                {t('mandatoryPerson.attachments.selectFile')}
                            </label>
                            <Typography type='normal' additionalClasses='rpo-grey'>
                                {t('mandatoryPerson.attachments.allowedFiles')}
                            </Typography>
                            <input
                                type='file'
                                className='govuk-file-upload'
                                id={fileInputId}
                                onChange={handleUpload}
                                accept='.xls,.xlsx,.pdf'
                                ref={fileUploadRef}
                                multiple
                                disabled={isUploading || (!!requestId && cliRegisterApplStatusCompletingId !== defaultValues.statusFK)}
                                aria-describedby={isUploading ? attUploadMsgId : undefined}
                            />
                        </GridCol>
                        {fields.length > 0 && (
                            <GridCol size='2/3' additionalClass='rpo-attachment' key={fields.length}>
                                <SummaryCard title={t('mandatoryPerson.attachments.listTitle')} titleType='h2'>
                                    <SummaryList
                                        listItems={fields.map((f, idx) => ({
                                            key: f.name,
                                            value: mimeToExt(f.mime).toUpperCase() + ', ' + formatBytes(f.size),
                                            action: f.temporary
                                                ? [
                                                      <Button type='button' variant='link' onClick={() => removeAttachmentHandler(idx)}>
                                                          {t('mandatoryPerson.attachments.remove')}
                                                      </Button>
                                                  ]
                                                : [
                                                      <Button type='button' variant='link' onClick={() => attachmentApi.download(idx)}>
                                                          {t('mandatoryPerson.attachments.download')}
                                                      </Button>
                                                  ]
                                        }))}
                                    />
                                    {isUploading && (
                                        <div className='govuk-summary-list rpo-attachment-uploading-msg'>
                                            <span id={attUploadMsgId}> {t('mandatoryPerson.attachments.uploading')}</span>
                                            <FontAwesomeIcon
                                                icon={faCircleNotch}
                                                className='rpo-loader-content__icon rpo-loader-content__icon__small'
                                            />
                                        </div>
                                    )}
                                </SummaryCard>
                            </GridCol>
                        )}
                    </GridRow>

                    <GridRow>
                        <GridCol size='full'>
                            {(!requestId || cliRegisterApplStatusCompletingId === defaultValues.statusFK) && (
                                <ButtonGroup>
                                    <Button type='submit'>{t('mandatoryPerson.signAndSend')}</Button>
                                    <Button type='button' onClick={onReset} variant='secondary'>
                                        {t('mandatoryPerson.cancelBtn')}
                                    </Button>
                                </ButtonGroup>
                            )}
                        </GridCol>
                    </GridRow>
                </form>
            </FormProvider>
        </Loader>
    );
};

export default Request;
