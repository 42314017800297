import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import {
    Column,
    DoubleColumn,
    Footer as IdskFooter,
    FooterContent,
    FooterDescription,
    FooterSimpleDescription,
    FooterButtonUp
} from '@mc-ui/idsk-react-components';

import SusrLogo from '../../assets/susr-log.svg';
import { SystemApi } from '../../api/apis';
import { useQueryClient } from '@tanstack/react-query';

const systemApi = new SystemApi();

const Footer: React.FC = () => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const queryClient = useQueryClient();
    const [version, setVersion] = useState('');

    useEffect(() => {
        queryClient
            .fetchQuery({
                queryKey: ['version'],
                queryFn: ({ signal }) => systemApi.getSystemVersion(signal)
            })
            .then((version) => {
                setVersion(version);
            })
            .catch((e) => console.error(e));
    }, [queryClient]);

    return (
        <IdskFooter>
            <FooterButtonUp>{t('footer.upBtn')}</FooterButtonUp>
            <FooterContent>
                <Column
                    title={t('footer.login')}
                    as={Link}
                    links={[
                        {
                            title: t('footer.loginWithUsername'),
                            to: '/login'
                        },
                        ...(ENV.UPVS_LOGIN_URL
                            ? [
                                  {
                                      title: t('footer.loginEID'),
                                      to: {},
                                      onClick: () => (window.location.href = ENV.UPVS_LOGIN_URL!)
                                  }
                              ]
                            : [])
                    ]}
                />
                <DoubleColumn
                    title={t('footer.serviceInfo')}
                    as='a'
                    leftLinks={[
                        // {
                        //     title: t('footer.enduserServices'),
                        //     href: ENV.END_SERVICES_INFO_URL,
                        //     target: '_blank',
                        //     rel: 'noreferrer'
                        // },
                        {
                            title: t('footer.restApiServices'),
                            href: ENV.REST_API_INFO_URL,
                            target: '_blank',
                            rel: 'noreferrer'
                        }
                    ]}
                    rightLinks={
                        [
                            // {
                            //     title: t('footer.generalInfo'),
                            //     href: ENV.GENERAL_INFO_URL,
                            //     target: '_blank',
                            //     rel: 'noreferrer'
                            // }
                        ]
                    }
                />
                <FooterDescription
                    links={{
                        links: [
                            {
                                title: t('footer.help'),
                                to: language === 'en' ? ENV.HELP_PDF_EN_URL : ENV.HELP_PDF_SK_URL,
                                target: '_blank',
                                rel: 'noreferrer'
                            },
                            {
                                title: t('footer.cookies'),
                                to: '/cookies'
                            },
                            {
                                title: t('footer.contacts'),
                                to: ENV.CONTACTS_URL,
                                target: '_blank',
                                rel: 'noreferrer'
                            },
                            {
                                title: t('footer.accessibilityStatement'),
                                to: '/declaration'
                            },
                            {
                                title: t('footer.contentManager'),
                                to: ENV.CONTENT_MANAGER_URL,
                                target: '_blank',
                                rel: 'noreferrer'
                            }
                        ],
                        as: Link
                    }}
                    logo={{
                        title: t('footer.orgName'),
                        alt: t('footer.orgName'),
                        src: SusrLogo,
                        href: ENV.SUSR_URL
                    }}
                >
                    <>{t('footer.operatorMsg')}</>
                    <>
                        <Trans i18nKey='footer.designMsg'>
                            Vytvorené v súlade s
                            <a href={ENV.IDSK_URL} target='_blank' className='govuk-link' rel='noreferrer'>
                                Jednotným dizajn manuálom elektronických služieb.
                            </a>
                        </Trans>
                    </>
                </FooterDescription>
                <FooterSimpleDescription>{t('footer.version', { version: version })}</FooterSimpleDescription>
            </FooterContent>
        </IdskFooter>
    );
};

export default Footer;
