import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from '@mc-ui/idsk-react-components';

import Captcha from './Captcha';
import { AuthApi } from '../../api/apis';

type CaptchaDialogProps = {
    resolve: (value: boolean | PromiseLike<boolean>) => void;
    reject: (reason?: any) => void;
};

type CaptchaDialogForm = {
    captcha: string;
};

const schema = yup.object({
    captcha: yup.string().defined().required()
});

const CaptchaDialog: React.FC<CaptchaDialogProps> = ({ reject, resolve }) => {
    const authApi = new AuthApi();
    const { t } = useTranslation();

    const methods = useForm<CaptchaDialogForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            captcha: ''
        }
    });

    const {
        handleSubmit,
        setError,
        formState: { isSubmitting }
    } = methods;

    const onSubmit = handleSubmit(async ({ captcha }) => {
        await authApi.anonymousLogin(captcha).then((res) => {
            if (res === true) {
                resolve(true);
            } else {
                setError('captcha', {
                    type: 'invalidCaptcha'
                });
            }
        });
    });

    const cancelHandler = useCallback(() => {
        reject('Cancelled by user');
    }, [reject]);

    return (
        <div className='rpo-captcha-dialog__backdrop'>
            <dialog open className='rpo-captcha-dialog'>
                <FormProvider {...methods}>
                    <form noValidate onSubmit={onSubmit}>
                        <Captcha name='captcha' fullscreen={true} />
                        <div className='rpo-captcha-dialog__btns'>
                            <Button type='submit' disabled={isSubmitting}>
                                <b>{t('captcha.dialog.submit')}</b>
                            </Button>
                            <Button type='button' variant='warning' onClick={cancelHandler}>
                                <b>{t('captcha.dialog.cancel')}</b>
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            </dialog>
        </div>
    );
};

export default CaptchaDialog;
