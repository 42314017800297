import React, { PropsWithChildren, useMemo } from 'react';
import { Link, Outlet, ScrollRestoration, useMatches } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs, Container } from '@mc-ui/idsk-react-components';

import Footer from './Footer';
import Header from './Header';
import CookieProvider from '../../store/cookie-context';
import { RESTORE_STATE } from '../../utils/contants';
import useOrganization from '../../hooks/useOrganization';
import { decode } from '../../utils/string';

const Main: React.FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation();
    const matches = useMatches();

    const { organization } = useOrganization(false);

    const breadcrumbs = useMemo(
        () =>
            matches
                .filter((m) => !!m.handle)
                .map(({ handle, pathname }) => {
                    const breadcrumb = (
                        handle as {
                            breadcrumb: {
                                title: string;
                            };
                        }
                    ).breadcrumb;

                    let title = '';
                    if (breadcrumb.title === 'organizationDetail') {
                        const noIdentifierAvailable = organization?.ipo.length === 0;
                        const validIdentifier = organization?.ipo[0]?.value;
                        title = validIdentifier
                            ? t('orgDetail.orgIdentifierValue') + ' ' + decode(validIdentifier)
                            : noIdentifierAvailable
                              ? t('orgDetail.orgIdentifierValueMissing')
                              : '...';
                    } else {
                        title = t(`breadcrumbs.${breadcrumb.title}`);
                    }

                    return {
                        title,
                        children:
                            pathname === '/' ? (
                                <div>
                                    <FontAwesomeIcon
                                        icon={faHouse}
                                        style={{
                                            paddingRight: '10px'
                                        }}
                                    />
                                    {t(`breadcrumbs.${breadcrumb.title}`)}
                                </div>
                            ) : undefined,
                        to: pathname,
                        state: RESTORE_STATE
                    };
                }),
        [t, matches, organization]
    );

    return (
        <CookieProvider>
            <Header />
            <div>
                {breadcrumbs.length > 1 && (
                    <Container>
                        <Breadcrumbs as={Link} breadcrumbs={breadcrumbs} />
                    </Container>
                )}
                <Container withMainWrapper={true}>
                    {children ? children : <Outlet />}
                    <ScrollRestoration />
                </Container>
            </div>
            <div className='rpo-flex-1'></div>
            <Footer />
        </CookieProvider>
    );
};

export default Main;
