import { format } from 'date-fns';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

enum DitecErrorType {
    ERROR_CANCELLED = 1,
    ERROR_GENERAL = -200,
    ERROR_LAUNCH_FAILED = -202,
    ERROR_LAUNCH_FORBIDDEN = -203,
    ERROR_NOT_INSTALLED = -201
}

const generateTimeId = () => {
    return format(new Date(), 'yyyyMMddHHmmssSSS');
};

type useSignerReturnObject = {
    signAsice: (
        request: {
            xml: string;
            xsd: string;
            xslt: string;
            namespaceUri: string;
            xsdUrl: string;
            xsltUrl: string;
            version: string;
        },
        title: string
    ) => Promise<string>;
    ditecErrorToString: (e: any) => string | true | JSX.Element;
};

type useSignerHook = () => useSignerReturnObject;

const useSigner: useSignerHook = () => {
    const { t } = useTranslation();

    const signAsice = useCallback(
        async (
            request: {
                xml: string;
                xsd: string;
                xslt: string;
                namespaceUri: string;
                xsdUrl: string;
                xsltUrl: string;
                version: string;
            },
            title: string
        ): Promise<string> => {
            const promise = new Promise<string>((resolve, reject) => {
                try {
                    const onErrorFn = (e: DitecError) => {
                        if (ditec.utils.isDitecError(e)) {
                            reject({
                                code: e.code,
                                name: e.name,
                                message: e.message,
                                detail: e.detail
                            });
                        } else {
                            reject('GENERAL_ERROR');
                        }
                    };

                    const onGetSignatureWithASiCEnvelopeBase64Callback = {
                        onSuccess: function (signedData: string) {
                            resolve(signedData);
                        },
                        onError: onErrorFn
                    };

                    const onGetSignerIdentificationCallback = {
                        onSuccess: function () {
                            ditec.dSigXadesBpJs.getSignatureWithASiCEnvelopeBase64(onGetSignatureWithASiCEnvelopeBase64Callback);
                        },
                        onError: onErrorFn
                    };

                    const onSignCallback = {
                        onSuccess: function () {
                            ditec.dSigXadesBpJs.getSignerIdentification(onGetSignerIdentificationCallback);
                        },
                        onError: onErrorFn
                    };

                    const onAddXmlObjectCallback = {
                        onSuccess: function () {
                            const signatureId = 'Signature' + generateTimeId();
                            ditec.dSigXadesBpJs.sign(
                                signatureId,
                                ditec.dSigXadesBpJs.SHA256,
                                'urn:oid:1.3.158.36061701.1.2.2',
                                onSignCallback
                            );
                        },
                        onError: onErrorFn
                    };

                    const onInitializeCallback = {
                        onSuccess: function () {
                            const objectId = 'Object' + generateTimeId() + '.xml';
                            ditec.dSigXadesBpJs.addXmlObject(
                                objectId,
                                title,
                                request.namespaceUri,
                                request.xml,
                                request.namespaceUri,
                                request.version,
                                request.xsd,
                                request.xsdUrl,
                                request.xslt,
                                request.xsltUrl,
                                ditec.dSigXadesBpJs.XML_MEDIA_DESTINATION_TYPE_DESC_TXT,
                                ditec.dSigXadesBpJs.LANG_SK,
                                'ÚPVS',
                                true,
                                ditec.dSigXadesBpJs.XML_XDC_NAMESPACE_URI_V1_1,
                                onAddXmlObjectCallback
                            );
                        },
                        onError: onErrorFn
                    };

                    const onDeployCallback = {
                        onSuccess: function () {
                            ditec.dSigXadesBpJs.initialize(onInitializeCallback);
                        },
                        onError: onErrorFn
                    };

                    ditec.dSigXadesBpJs.deploy(null, onDeployCallback);
                } catch (e) {
                    reject(e);
                }
            });

            return promise;
        },
        []
    );

    const ditecErrorToString = useCallback(
        (e: any) => {
            if (ditec.utils.isDitecError(e)) {
                switch (e.code) {
                    case DitecErrorType.ERROR_CANCELLED:
                        return t('extractRequest.ERROR_CANCELLED');
                    case DitecErrorType.ERROR_LAUNCH_FAILED:
                        return t('extractRequest.ERROR_LAUNCH_FAILED');
                    case DitecErrorType.ERROR_LAUNCH_FORBIDDEN:
                        return t('extractRequest.ERROR_LAUNCH_FORBIDDEN');
                    case DitecErrorType.ERROR_NOT_INSTALLED:
                        return (
                            <Trans i18nKey='extractRequest.ERROR_NOT_INSTALLED'>
                                Aplikácia pre kvalifikovaný elektronický podpis nie je nainštalovaná. Prosím nainštalujte aplikáciu podľa
                                pokynov na nasledujúcej stránke
                                <a className='govuk-body govuk-link' target='_blank' rel='noreferrer' href={ditec.config.downloadPage.url}>
                                    {ditec.config.downloadPage.title}
                                </a>
                                .
                            </Trans>
                        );
                    case DitecErrorType.ERROR_GENERAL:
                    default:
                        return true;
                }
            } else {
                return true;
            }
        },
        [t]
    );

    return {
        signAsice,
        ditecErrorToString
    };
};

export default useSigner;
