import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { GridRow, GridCol, Filter, Button, Notice, CheckboxGroup } from '@mc-ui/idsk-react-components';

import { AuthContext } from '../../store/auth-context';
import { Input } from '../form/Input';
import { CheckboxItem } from '../form/CheckboxItem';
import { Autocomplete } from '../form/Autocomplete';
import Captcha from '../captcha/Captcha';
import { objectToSearchParams } from '../../utils/searchParams';
import { RESTORE_STATE } from '../../utils/contants';
import { AuthApi, CodelistApi, OrganizationSearchCriteria, UserRole } from '../../api';
import { DatePicker } from '../form/DatePicker';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { codelistCodeToLabel } from '../../utils/string';

type FetchCodelistCodes = (
    label: string,
    page: number,
    pageSize: number,
    signal: AbortSignal
) => Promise<
    {
        value: string;
        label: string;
    }[]
>;

type FetchCodelistCode = (
    value: string,
    signal: AbortSignal
) => Promise<{
    value: string;
    label: string;
}>;

const ArrayAutocomplete: React.FC<{
    control: any;
    name: string;
    fetchCodelistCodes: FetchCodelistCodes;
    fetchCodelistCode: FetchCodelistCode;
}> = ({ name, control, fetchCodelistCode, fetchCodelistCodes }) => {
    const { t } = useTranslation();

    const { fields, append, remove } = useFieldArray({
        control,
        name
    });

    return (
        <>
            {fields.map((field, index) => (
                <GridRow key={field.id}>
                    <GridCol size='1/2' additionalClass='rpo-form-array'>
                        <Autocomplete
                            label={t(`orgSearch.${name}.title`)}
                            name={`${name}.${index}.code`}
                            fetchPage={fetchCodelistCodes}
                            fetchSingle={fetchCodelistCode}
                        />
                        {index === 0 && (
                            <button type='button' onClick={() => append({ code: null })} aria-label={t(`orgSearch.${name}.add`)}>
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        )}
                        {index > 0 && (
                            <button type='button' onClick={() => remove(index)} aria-label={t(`orgSearch.${name}.remove`)}>
                                <FontAwesomeIcon icon={faMinus} />
                            </button>
                        )}
                    </GridCol>
                </GridRow>
            ))}
        </>
    );
};

const authApi = new AuthApi();
const codelistApi = new CodelistApi();

type CriteriaWithCaptcha = Omit<OrganizationSearchCriteria, 'zozcin' | 'sknace5' | 'esu2010'> & {
    zozcin?: { code?: number }[];
    sknace5?: { code?: number }[];
    esu2010?: { code?: number }[];
    captcha: string;
};

const defaultFormValues: CriteriaWithCaptcha = {
    organizationIdentifier: '',
    organizationFullName: '',
    legalFormId: undefined,
    fullTextSearch: true,
    showHistorical: true,
    showOrganizationUnit: true,
    corporateBodyAlternativeName: '',
    physicalAddressMunicipality: '',
    physicalAddressStreet: '',
    physicalAddressBuildingNum: '',
    stakeholderName: '',
    stakeholderSurname: '',
    statutoryName: '',
    statutorySurname: '',
    establishedFrom: '',
    establishedTo: '',
    terminatedFrom: '',
    terminatedTo: '',
    physicalAddressRegNumber: undefined,
    legalStatus: undefined,
    stakeholderTypeId: undefined,
    statutoryTypeId: undefined,
    zozcin: [{ code: undefined }],
    sknace5: [{ code: undefined }],
    esu2010: [{ code: undefined }],
    captcha: ''
};

const prevState: { criteria?: CriteriaWithCaptcha } = {};

const OrganizationSearch: React.FC = () => {
    const { captchaVerified, setCaptchaVerified, hasRole } = useContext(AuthContext);
    const isRpoRead = hasRole(UserRole.RPO_READ);
    const { t } = useTranslation();
    const { state } = useLocation();
    const errorRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const codeItemSchema = yup.object({
        code: yup.number().emptyAsUndefined()
    });

    const schema = yup
        .object({
            organizationIdentifier: yup
                .string()
                .trim()
                .matches(/^[0-9]{12}$|^[0-9]{8}$|^(Neuvedené)$|^[*]{0}$/gm, {
                    name: 'ipoOrUndefined'
                })
                .emptyAsUndefined(),
            organizationFullName: yup
                .string()
                .trim()
                .max(1023, ({ max }) => max)
                .emptyAsUndefined(),
            legalFormId: yup.number().emptyAsUndefined(),
            fullTextSearch: yup.boolean().default(true).defined().required(),
            showHistorical: yup.boolean().default(true).defined().required(),
            showOrganizationUnit: yup.boolean().default(true).defined().required(),
            corporateBodyAlternativeName: yup
                .string()
                .trim()
                .max(255, ({ max }) => max)
                .emptyAsUndefined(),
            physicalAddressMunicipality: yup
                .string()
                .trim()
                .max(2047, ({ max }) => max)
                .emptyAsUndefined(),
            physicalAddressStreet: yup
                .string()
                .trim()
                .max(100, ({ max }) => max)
                .emptyAsUndefined(),
            physicalAddressBuildingNum: yup
                .string()
                .trim()
                .max(20, ({ max }) => max)
                .emptyAsUndefined(),
            stakeholderName: yup
                .string()
                .trim()
                .max(255, ({ max }) => max)
                .emptyAsUndefined(),
            stakeholderSurname: yup
                .string()
                .trim()
                .max(255, ({ max }) => max)
                .emptyAsUndefined(),
            statutoryName: yup
                .string()
                .trim()
                .max(255, ({ max }) => max)
                .emptyAsUndefined(),
            statutorySurname: yup
                .string()
                .trim()
                .max(255, ({ max }) => max)
                .emptyAsUndefined(),
            establishedFrom: yup
                .string()
                .trim()
                .max(10, ({ max }) => max)
                .emptyAsUndefined(),
            establishedTo: yup
                .string()
                .trim()
                .max(10, ({ max }) => max)
                .emptyAsUndefined(),
            terminatedFrom: yup
                .string()
                .trim()
                .max(10, ({ max }) => max)
                .emptyAsUndefined(),
            terminatedTo: yup
                .string()
                .trim()
                .max(10, ({ max }) => max)
                .emptyAsUndefined(),
            physicalAddressRegNumber: yup
                .number()
                .typeError('number')
                .max(99999999999, ({ max }) => max)
                .emptyAsUndefined(),
            legalStatus: yup.number().emptyAsUndefined(),
            stakeholderTypeId: yup.number().emptyAsUndefined(),
            statutoryTypeId: yup.number().emptyAsUndefined(),
            zozcin: yup
                .array()
                .of(
                    yup.object({
                        code: yup.number()
                    })
                )
                .max(1000)
                .emptyAsUndefined(),
            sknace5: yup.array().of(codeItemSchema).max(1000).emptyAsUndefined(),
            esu2010: yup.array().of(codeItemSchema).max(1000).emptyAsUndefined(),
            captcha:
                captchaVerified || isRpoRead
                    ? yup.string().defined()
                    : yup
                          .string()
                          .defined()
                          .max(255, ({ max }) => max)
                          .required()
        })
        .defined()
        .required()
        .test('atLeastOne', (data) => {
            if (
                data.organizationFullName?.trim() ||
                data.organizationIdentifier?.trim() ||
                data.legalFormId !== undefined ||
                data.corporateBodyAlternativeName?.trim() ||
                data.physicalAddressMunicipality?.trim() ||
                data.physicalAddressStreet?.trim() ||
                data.physicalAddressBuildingNum?.trim() ||
                data.stakeholderName?.trim() ||
                data.stakeholderSurname?.trim() ||
                data.statutoryName?.trim() ||
                data.statutorySurname?.trim() ||
                data.establishedFrom?.trim() ||
                data.establishedTo?.trim() ||
                data.terminatedFrom?.trim() ||
                data.terminatedTo?.trim() ||
                data.physicalAddressRegNumber !== undefined ||
                data.legalStatus !== undefined ||
                data.stakeholderTypeId !== undefined ||
                data.statutoryTypeId !== undefined ||
                (data.zozcin !== undefined && data.zozcin.length > 0) ||
                (data.sknace5 !== undefined && data.sknace5.length > 0) ||
                (data.esu2010 !== undefined && data.esu2010.length > 0)
            ) {
                return true;
            }
            return false;
        });

    const methods = useForm<CriteriaWithCaptcha>({
        resolver: yupResolver(schema),
        defaultValues: state === RESTORE_STATE && prevState.criteria ? prevState.criteria : defaultFormValues
    });
    const {
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        getValues,
        control
    } = methods;

    const onSubmit = handleSubmit(async ({ captcha, ...data }) => {
        const { zozcin, esu2010, sknace5, ...other } = data;
        const searchParams = objectToSearchParams({
            ...other,
            zozcin: zozcin?.filter(({ code }) => code !== undefined).map(({ code }) => code!),
            esu2010: esu2010?.filter(({ code }) => code !== undefined).map(({ code }) => code!),
            sknace5: sknace5?.filter(({ code }) => code !== undefined).map(({ code }) => code!)
        });
        if (captchaVerified || isRpoRead) {
            navigate('/organization?' + searchParams.toString());
        } else {
            await authApi.anonymousLogin(captcha).then((res) => {
                if (res === true) {
                    setCaptchaVerified();
                    navigate('/organization?' + searchParams.toString());
                } else {
                    setError('captcha', {
                        type: 'invalidCaptcha'
                    });
                }
            });
        }
    });

    const fetchCodelistCodes = useCallback(
        (
            codelistAcronymName:
                | 'LEGAL_FORM'
                | 'LEGAL_STATUS_TYPE'
                | 'STAKEHOLDER_TYPE'
                | 'STAT_BODY_TYPE'
                | 'ZOZCIN'
                | 'ESU2010'
                | 'SKNACE5'
        ): FetchCodelistCodes =>
            async (label, page, pageSize, signal) => {
                const data = await codelistApi.getCodelistCodesPublic(
                    {
                        count: pageSize,
                        start: page * pageSize,
                        sort: [],
                        query: {
                            codelistAcronymName,
                            label,
                            skippedIds: []
                        }
                    },
                    !isRpoRead,
                    signal
                );
                // TODO chybova hlaska ak nejde server ?
                return (
                    data.results?.map((clc) => {
                        return {
                            value: clc.codeId?.toString() ?? '',
                            label: codelistCodeToLabel(clc)
                        };
                    }) ?? []
                );
            },
        [isRpoRead]
    );

    const fetchCodelistCode = useCallback<FetchCodelistCode>(async (value, signal) => {
        const clc = await codelistApi.getCodelistCodeById(+value, signal);
        // TODO chybova hlaska ak nejde server ?
        return {
            value: clc?.codeId.toString() ?? '',
            label: codelistCodeToLabel(clc)
        };
    }, []);

    // @ts-ignore
    const someRequired = errors[''];

    const invalidElements = Object.keys(errors)
        .filter((key) => key !== '')
        .map((key) => {
            const error = errors[key as keyof typeof errors]!;
            return {
                ref: error.ref as React.RefObject<HTMLElement>,
                title: t(`error.${error.type}`, {
                    label: t(`orgSearch.${key}`),
                    message: t(`error.${error.message}`, {
                        defaultValue: error.message
                    })
                })
            };
        });

    useEffect(() => {
        if (someRequired) {
            errorRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [someRequired]);

    useEffect(() => {
        return () => {
            prevState.criteria = getValues();
        };
    }, [getValues]);

    return (
        <FormProvider {...methods}>
            {(someRequired || invalidElements.length > 0) && (
                <div ref={errorRef}>
                    <Notice
                        title={t('orgSearch.formErrorTitle', {
                            count: someRequired ? 1 : invalidElements.length
                        })}
                        type='error'
                        invalidElements={invalidElements}
                    >
                        {someRequired && t(`error.${someRequired.type}`)}
                    </Notice>
                </div>
            )}
            <form noValidate onSubmit={onSubmit}>
                <GridRow>
                    <GridCol size='1/2'>
                        <Input
                            name='organizationIdentifier'
                            label={t('orgSearch.organizationIdentifier')}
                            hint={t('orgSearch.organizationIdentifierHint')}
                        />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol size='1/2'>
                        <Input
                            name='organizationFullName'
                            label={t('orgSearch.organizationName')}
                            hint={t('orgSearch.organizationNameHint')}
                        />
                    </GridCol>
                </GridRow>
                <Filter
                    title={t('orgSearch.filterTitle')}
                    openLabel={t('orgSearch.filterOpenLabel')}
                    closeLabel={t('orgSearch.filterCloseLabel')}
                >
                    <GridRow>
                        <GridCol size='1/2'>
                            <Autocomplete
                                label={t('orgSearch.legalForm')}
                                name='legalFormId'
                                fetchPage={fetchCodelistCodes('LEGAL_FORM')}
                                fetchSingle={fetchCodelistCode}
                            />
                        </GridCol>
                    </GridRow>

                    {isRpoRead && (
                        <>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Input name='corporateBodyAlternativeName' label={t('orgSearch.corporateBodyAlternativeName')} />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Input name='physicalAddressMunicipality' label={t('orgSearch.physicalAddressMunicipality')} />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Input name='physicalAddressStreet' label={t('orgSearch.physicalAddressStreet')} />
                                </GridCol>
                                <GridCol size='1/4'>
                                    <Input name='physicalAddressRegNumber' label={t('orgSearch.physicalAddressRegNumber')} />
                                </GridCol>
                                <GridCol size='1/4'>
                                    <Input name='physicalAddressBuildingNum' label={t('orgSearch.physicalAddressBuildingNum')} />
                                </GridCol>
                            </GridRow>
                            <ArrayAutocomplete
                                control={control}
                                name='zozcin'
                                fetchCodelistCode={fetchCodelistCode}
                                fetchCodelistCodes={fetchCodelistCodes('ZOZCIN')}
                            />
                            <GridRow>
                                <GridCol size='1/2'>
                                    <DatePicker label={t('orgSearch.establishedFrom')} name='establishedFrom' />
                                </GridCol>
                                <GridCol size='1/2'>
                                    <DatePicker label={t('orgSearch.establishedTo')} name='establishedTo' />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <DatePicker label={t('orgSearch.terminatedFrom')} name='terminatedFrom' />
                                </GridCol>
                                <GridCol size='1/2'>
                                    <DatePicker label={t('orgSearch.terminatedTo')} name='terminatedTo' />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Autocomplete
                                        label={t('orgSearch.legalStatus')}
                                        name='legalStatus'
                                        fetchPage={fetchCodelistCodes('LEGAL_STATUS_TYPE')}
                                        fetchSingle={fetchCodelistCode}
                                    />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Input name='stakeholderName' label={t('orgSearch.stakeholderName')} />
                                </GridCol>
                                <GridCol size='1/2'>
                                    <Input name='stakeholderSurname' label={t('orgSearch.stakeholderSurname')} />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Autocomplete
                                        label={t('orgSearch.stakeholderTypeId')}
                                        name='stakeholderTypeId'
                                        fetchPage={fetchCodelistCodes('STAKEHOLDER_TYPE')}
                                        fetchSingle={fetchCodelistCode}
                                    />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Input name='statutoryName' label={t('orgSearch.statutoryName')} />
                                </GridCol>
                                <GridCol size='1/2'>
                                    <Input name='statutorySurname' label={t('orgSearch.statutorySurname')} />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Autocomplete
                                        label={t('orgSearch.statutoryTypeId')}
                                        name='statutoryTypeId'
                                        fetchPage={fetchCodelistCodes('STAT_BODY_TYPE')}
                                        fetchSingle={fetchCodelistCode}
                                    />
                                </GridCol>
                            </GridRow>
                            <ArrayAutocomplete
                                control={control}
                                name='sknace5'
                                fetchCodelistCode={fetchCodelistCode}
                                fetchCodelistCodes={fetchCodelistCodes('SKNACE5')}
                            />
                            <ArrayAutocomplete
                                control={control}
                                name='esu2010'
                                fetchCodelistCode={fetchCodelistCode}
                                fetchCodelistCodes={fetchCodelistCodes('ESU2010')}
                            />
                        </>
                    )}

                    <GridRow>
                        <GridCol size='full'>
                            <CheckboxGroup formGroupClasses='govuk-checkbox-form-group'>
                                <CheckboxItem label={t('orgSearch.showHistorical')} name='showHistorical' />
                                <CheckboxItem label={t('orgSearch.showOrganizationUnit')} name='showOrganizationUnit' />
                                <CheckboxItem label={t('orgSearch.fullTextSearch')} name='fullTextSearch' />
                            </CheckboxGroup>
                        </GridCol>
                    </GridRow>
                </Filter>

                {!isRpoRead && !captchaVerified && <Captcha name='captcha' />}

                <Button type='submit' disabled={isSubmitting} additionalClasses='rpo-no-margin'>
                    {t('orgSearch.searchBtn')}
                </Button>
            </form>
        </FormProvider>
    );
};

export default OrganizationSearch;
