import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AuthApi, LoginRequest, ExtendedUser, UserRole } from '../api';
import Initialization from '../components/layout/Initialization';
import CaptchaDialog from '../components/captcha/CaptchaDialog';
import { useQueryClient } from '@tanstack/react-query';

const RECAPTCHA_EL_NAME = 'recaptcha';
const TIMEOUT = 1000 * 60 * (ENV.RECAPTCHA_VALID_FOR ?? 15);
const TIMEOUT_OFFSET = 60000; // Reload 60000 ms before timeout

const captchaStatus: {
    status: 'ready' | 'failed' | 'initializing';
} = {
    status: 'initializing'
};

if (ENV.RECAPTCHA_ENABLED && ENV.RECAPTCHA_URL && ENV.RECAPTCHA_KEY) {
    if (document.getElementById(RECAPTCHA_EL_NAME) === null) {
        const script = document.createElement('script');
        script.id = RECAPTCHA_EL_NAME;
        script.src = ENV.RECAPTCHA_URL + ENV.RECAPTCHA_KEY;
        script.onload = () => {
            captchaStatus.status = 'ready';
        };
        script.onerror = () => {
            captchaStatus.status = 'failed';
        };
        document.body.appendChild(script);
    }
}

const waitForGrecaptcha = async (signal?: AbortSignal) => {
    if (!ENV.RECAPTCHA_ENABLED) {
        throw new Error('reCAPTCHA is disabled!');
    }
    return new Promise<void>((resolve, reject) => {
        const wait = (sig?: AbortSignal) => {
            if (captchaStatus.status === 'failed') {
                reject('reCAPTCHA loading failed!');
            } else if (window.grecaptcha && captchaStatus.status === 'ready') {
                resolve();
            } else {
                setInterval(() => {
                    if (!sig?.aborted) {
                        wait(sig);
                    } else {
                        reject({ name: 'AbortError' });
                    }
                }, 1000);
            }
        };
        wait(signal);
    });
};

const authApi = new AuthApi();

type AuthContextValue = {
    /** Returns true when initialization is done */
    initialized: boolean;
    /** Returns true in this scenarios: user validated as human by reCaptcha, user passed custom challenge, user is logged in, reCaptcha is disabled */
    captchaVerified: boolean;
    /** Allows to set that user is verified (after custom challenge in form) */
    setCaptchaVerified: () => void;
    /** Check if user is verified, if needed it shows captcha challenge in dialog, than it call callback and returns it's result as Promise */
    protect: <T>(callback: (captchaVerified: boolean) => Promise<T>) => Promise<T> | undefined;
    /** Returns user object if logged in */
    user: ExtendedUser | null;
    /** Returns true if logged in */
    isAuthenticated: boolean;
    /** Returns true if user is authenticated and has at least one specified role or all roles based on all attribute */
    hasRole: (userRole: UserRole | UserRole[], all?: boolean) => boolean;
    /** Function to log in */
    login: (loginRequest: LoginRequest) => Promise<string>;
    /** Function to log out */
    logout: () => Promise<void>;
};

export const AuthContext = React.createContext<AuthContextValue>({} as AuthContextValue);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const queryClient = useQueryClient();
    const [authInitialized, setAuthInitialized] = useState(false);
    const [captchaInitialized, setCaptchaInitialized] = useState(
        !ENV.RECAPTCHA_ENABLED // if disabled than skip initialization and set to initialized already
    );

    const [user, setUser] = useState<ExtendedUser | null>(null);
    const [captchaValidTo, setCaptchaValidTo] = useState<number | null>(null);
    const [forceUpdate, setForceUpdate] = useState(1);

    const challenge = useRef<Promise<boolean>>();

    const [challengeExecutor, setChallengeExecutor] = useState<
        [resolve: (value: boolean | PromiseLike<boolean>) => void, reject: (reason?: any) => void] | null
    >(null);

    const triggerforcedUpdate = useCallback(() => {
        setForceUpdate((prev) => {
            if (prev >= Number.MAX_SAFE_INTEGER) {
                return 1;
            }
            return prev + 1;
        });
    }, []);

    useEffect(() => {
        const onFocus = (e: Event) => {
            // trigger captcha check on window refocus only if captcha available
            captchaStatus.status === 'ready' && triggerforcedUpdate();
        };
        window.addEventListener('focus', onFocus);
        return () => window.removeEventListener('focus', onFocus);
    }, [triggerforcedUpdate]);

    const verifyCaptcha = useCallback(async (signal?: AbortSignal) => {
        try {
            if (!ENV.RECAPTCHA_ENABLED) {
                throw new Error('reCAPTCHA is disabled!');
            }
            if (captchaStatus.status === 'failed') {
                throw new Error('reCAPTCHA loading failed!');
            }

            await waitForGrecaptcha(signal); // check if captcha available and if not wait for it to be loaded

            const result = await new Promise<boolean>((resolve, reject) => {
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(ENV.RECAPTCHA_KEY!, { action: 'submit' }).then(
                        async (token: string) => {
                            if (!signal?.aborted) {
                                try {
                                    const result = await authApi.validateReCaptcha(token, signal);
                                    if (result.result === true && !signal?.aborted) {
                                        resolve(true);
                                    } else {
                                        resolve(false);
                                    }
                                } catch (error) {
                                    console.error('AuthProvider - verifyCaptcha - FAILED', error);
                                    reject(
                                        new Error('validateReCaptcha failed', {
                                            cause: error
                                        })
                                    );
                                }
                            } else {
                                reject({ name: 'AbortError' });
                            }
                        },
                        (error) => {
                            reject(
                                new Error('reCaptcha failed', {
                                    cause: error
                                })
                            );
                        }
                    );
                });
            });

            return result;
        } catch (e: any) {
            if (e && e.name === 'AbortError') {
                return false;
            }
            throw e;
        }
    }, []);

    const fetchUser = useCallback(async (signal?: AbortSignal) => {
        try {
            const user = await authApi.user(signal);
            if (!signal?.aborted) {
                if (user) {
                    if (!user.login.startsWith('upvs_')) {
                        // Temporary until backoffice redesign
                        window.location.href = ENV.OLD_RPO_URL; // redirect to old GUI if logged in byt username and password
                    }
                    setUser((prev) => {
                        if (
                            prev &&
                            prev.login === user.login &&
                            prev.external === user.external &&
                            prev.roles.length === user.roles.length &&
                            prev.roles.find((r) => !user.roles.includes(r)) !== null
                        ) {
                            return prev;
                        }
                        return {
                            ...user,
                            isUPVS: user.login.startsWith('upvs_')
                        };
                    });
                    setCaptchaValidTo(null); // if logged in, remove captcha validity!
                } else {
                    setUser(null);
                }
                setAuthInitialized(true);
            }
        } catch (error) {
            // Error occured while loading authenticated user info, proceeding as unauthenticated
            console.error('AuthProvider - fetchUser - FAILED', error);
            setUser(null);
            setAuthInitialized(true);
        }
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        fetchUser(controller.signal);
        return () => controller.abort();
    }, [fetchUser]);

    useEffect(() => {
        if (ENV.RECAPTCHA_ENABLED) {
            const controller = new AbortController();
            let timeoutId: NodeJS.Timeout | undefined;

            // validating captcha only if not logged in and captcha enabled!
            if (authInitialized && user === null) {
                const now = new Date().getTime();
                if (captchaValidTo === null || captchaValidTo - TIMEOUT_OFFSET <= now) {
                    verifyCaptcha(controller.signal)
                        .then((res) => {
                            if (!controller.signal.aborted) {
                                if (res === true) {
                                    setCaptchaValidTo(now + TIMEOUT);
                                    timeoutId = setTimeout(() => {
                                        triggerforcedUpdate();
                                    }, TIMEOUT - TIMEOUT_OFFSET);
                                } else {
                                    // reCapcha failed to verify person
                                    setCaptchaValidTo(null);
                                }
                            }
                        })
                        .catch((error) => {
                            // something failed, set person as unverified
                            console.error('AuthProvider - useEffect - FAILED', error);
                            setCaptchaValidTo(null);
                        })
                        .finally(() => {
                            setCaptchaInitialized(true);
                        });
                } else {
                    timeoutId = setTimeout(
                        () => {
                            triggerforcedUpdate();
                        },
                        captchaValidTo - (now + TIMEOUT_OFFSET)
                    );
                }
            } else if (authInitialized && user) {
                setCaptchaInitialized(true);
                setCaptchaValidTo(null);
            }

            return () => {
                controller.abort();
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
            };
        }
    }, [authInitialized, user, verifyCaptcha, captchaValidTo, forceUpdate, triggerforcedUpdate]);

    const login = useCallback(
        async (loginRequest: LoginRequest) => {
            const auth = await authApi.login(loginRequest);
            if (auth) {
                await fetchUser();
                queryClient.clear(); // clears all cached data from browser, prevents issues if user has loaded some resources which have limited data for anonymouse search
            }
            return auth;
        },
        [fetchUser, queryClient]
    );

    const logout = useCallback(async () => {
        const url = await authApi.logout();
        queryClient.clear(); // clears all cached data from browser
        setUser(null);
        setCaptchaValidTo(null); // just in case
        if (url) {
            window.location.assign(url);
        }
    }, [queryClient]);

    const initialized = authInitialized && captchaInitialized;
    const captchaVerified = ENV.RECAPTCHA_ENABLED ? captchaValidTo !== null && captchaValidTo > new Date().getTime() : true;

    const challengeVerifiedHandler = useCallback(() => {
        if (user === null) {
            const now = new Date().getTime();
            setCaptchaValidTo(now + TIMEOUT);
        }
    }, [user]);

    // just to provide still same instance so refresh of app is limited to minimum
    const dummyHandler = useCallback(() => {}, []);

    const isAuthenticated = !!user;

    const setCaptchaVerified = ENV.RECAPTCHA_ENABLED ? challengeVerifiedHandler : dummyHandler;

    const protect = useCallback(
        <T,>(callback: (captchaVerified: boolean) => Promise<T>): Promise<T> | undefined => {
            if (captchaVerified || isAuthenticated) {
                return callback(true);
            } else {
                // do challenge
                if (!challenge.current) {
                    challenge.current = new Promise<boolean>((resolve, reject) => {
                        setChallengeExecutor([resolve, reject]);
                    })
                        .then((res) => {
                            setCaptchaVerified();
                            return res;
                        })
                        .catch((e) => {
                            console.error(e);
                            return false;
                        })
                        .finally(() => {
                            challenge.current = undefined;
                            setChallengeExecutor(null);
                        });
                }

                return challenge.current.then((res) => {
                    return callback(res);
                });
            }
        },
        [captchaVerified, setCaptchaVerified, isAuthenticated]
    );

    const hasRole = useCallback(
        (userRole: UserRole | UserRole[], all = false) => {
            if (!user) {
                return false;
            }
            if (all && Array.isArray(userRole)) {
                return userRole.every((r) => user.roles.includes(r));
            } else if (Array.isArray(userRole)) {
                return userRole.some((r) => user.roles.includes(r));
            } else {
                return user.roles.includes(userRole);
            }
        },
        [user]
    );

    const context: AuthContextValue = useMemo(() => {
        return {
            initialized,
            protect,
            captchaVerified,
            setCaptchaVerified,
            user,
            isAuthenticated,
            login,
            logout,
            hasRole
        };
    }, [initialized, captchaVerified, setCaptchaVerified, user, isAuthenticated, login, logout, protect, hasRole]);

    return (
        <AuthContext.Provider value={context}>
            <Initialization visible={!initialized} />
            {initialized && challengeExecutor && <CaptchaDialog resolve={challengeExecutor[0]} reject={challengeExecutor[1]} />}
            {initialized && children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
