import { RESTORE_STATE } from './contants';

export const restoreState = <S, T extends keyof S, D>(state: any, prevState: S, key: T, defaultValue: D): NonNullable<S[T]> | D => {
    if (state === RESTORE_STATE) {
        const restored = prevState[key];
        if (restored !== undefined && restored !== null) {
            return restored;
        }
    }
    return defaultValue;
};
