import React, { useEffect, useMemo, useState, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { Cookies } from '@mc-ui/idsk-react-components';

const ANALYTICS_COOKIES_NAME = 'analytics';

const gtag = (command: any, param: any) => {
    if (ENV.G_ANALYTICS_ENABLED && typeof window !== 'undefined') {
        if (typeof window.gtag === 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function gtag() {
                window.dataLayer.push(arguments);
            };
        }
        window.gtag(command, param);
    }
};

type CookieContextValue = {
    analyticsAllowed: boolean;
    setAnalyticsAllowed: (allowed: boolean) => void;
};

export const CookieContext = React.createContext<CookieContextValue>({} as CookieContextValue);

const CookieProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation();
    const [analyticsAllowed, setAnalyticsAllowed] = useState(localStorage.getItem(ANALYTICS_COOKIES_NAME) === 'true');

    useEffect(() => {
        if (ENV.G_ANALYTICS_ENABLED && ENV.G_ANALYTICS_URL && ENV.G_ANALYTICS_KEY) {
            localStorage.setItem(ANALYTICS_COOKIES_NAME, `${analyticsAllowed}`);
            if (analyticsAllowed) {
                // @ts-ignore
                window['ga-disable-' + ENV.G_ANALYTICS_KEY] = false;
                if (document.getElementById(ANALYTICS_COOKIES_NAME) === null) {
                    const script = document.createElement('script');
                    script.id = ANALYTICS_COOKIES_NAME;
                    script.src = ENV.G_ANALYTICS_URL + ENV.G_ANALYTICS_KEY;
                    script.async = true;
                    document.body.appendChild(script);
                    script.onload = () => {
                        gtag('js', new Date());
                        gtag('config', ENV.G_ANALYTICS_KEY);
                    };
                } else if (window.dataLayer === undefined || window.dataLayer.length === 0) {
                    gtag('js', new Date());
                    gtag('config', ENV.G_ANALYTICS_KEY);
                }
            } else {
                /* This should remove cookies */
                // @ts-ignore
                window['ga-disable-' + ENV.G_ANALYTICS_KEY] = true;
                window.dataLayer = [];

                const splitedHostname = window.location.hostname.split('.');
                const domain =
                    splitedHostname.length > 2
                        ? `.${splitedHostname[splitedHostname.length - 2]}.${splitedHostname[splitedHostname.length - 1]}`
                        : window.location.hostname;

                document.cookie
                    .split(';')
                    .filter((c) => c.trim().startsWith('_ga'))
                    .forEach((c) => {
                        document.cookie =
                            c.trim().split('=')[0] + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=' + domain + ';';
                    });
            }
        }
    }, [analyticsAllowed]);

    const context: CookieContextValue = useMemo(() => {
        return { analyticsAllowed, setAnalyticsAllowed };
    }, [analyticsAllowed, setAnalyticsAllowed]);

    return (
        <CookieContext.Provider value={context}>
            {ENV.G_ANALYTICS_ENABLED && (
                <Cookies
                    settingsBtnProps={{ to: '/cookies' }}
                    settingsBtnAs={Link}
                    otherCookiesList={[ANALYTICS_COOKIES_NAME]}
                    onAccept={() => setAnalyticsAllowed(true)}
                    onReject={() => setAnalyticsAllowed(false)}
                    localisation={{
                        acceptBtn: t('cookies.acceptBtn'),
                        acceptedContent: (a) => (
                            <Trans i18nKey='cookies.acceptedContent'>
                                Akceptovali ste dodatočné súbory cookies. Kedykoľvek ich môžete zmeniť v {a}.
                            </Trans>
                        ),
                        acceptedSettingsBtn: t('cookies.acceptedSettingsBtn'),
                        bannerContent: [t('cookies.bannerContent1'), t('cookies.bannerContent2')],
                        bannerTitle: t('cookies.bannerTitle'),
                        hideBtn: t('cookies.hideBtn'),
                        rejectBtn: t('cookies.rejectBtn'),
                        rejectedContent: (a) => (
                            <Trans i18nKey='cookies.rejectedContent'>
                                Neakceptovali ste analytické súbory, túto voľbu môžete kedykoľvek zmeniť v {a}.
                            </Trans>
                        ),
                        rejectedSettingsBtn: t('cookies.rejectedSettingsBtn'),
                        settingsBtn: t('cookies.settingsBtn')
                    }}
                />
            )}
            {children}
        </CookieContext.Provider>
    );
};

export default CookieProvider;
