import React, { useContext, useMemo } from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import '@mc-ui/idsk-react-components/dist/esm/index.css';
import './i18n/i18n';

import './yup-config';
import * as yup from 'yup';
import { Outlet, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import AuthProvider, { AuthContext } from './store/auth-context';
import Main from './components/layout/Main';
import ErrorPage from './pages/ErrorPage';
import Cookies from './pages/Cookies';
import Home from './pages/Home';
import Login from './pages/Login';
import OrganizationSearchResults from './pages/organizationSearch/OrganizationSearchResults';
import OrganizationDetail from './pages/organizationSearch/OrganizationDetail';
import OrganizationUnits from './pages/organizationSearch/OrganizationUnits';
import PermissionsPage from './pages/PermissionsPage';
import { UserRole } from './api';
import SearchRequest from './pages/mandatoryPerson/SearchRequest';
import Request from './pages/mandatoryPerson/Request';
import ExtractRequest from './pages/organizationSearch/ExtractRequest';
import RequestSearchResults from './pages/mandatoryPerson/RequestSearchResults';
import Declaration from './pages/Declaration';
import PoRequestExtract from './pages/PoRequestExtract';

// Definicia typu premenných prostredia
export const environmentSchema = yup.object({
    BASE_API_PATH: yup.string().defined().required(),
    RECAPTCHA_ENABLED: yup.boolean().defined().required(),
    RECAPTCHA_KEY: yup
        .string()
        .defined()
        .when('RECAPTCHA_ENABLED', {
            is: true,
            then: (schema) => schema.required().nonNullable(),
            otherwise: (schema) => schema.nullable()
        })
        .nullable(),
    RECAPTCHA_URL: yup
        .string()
        .defined()
        .when('RECAPTCHA_ENABLED', {
            is: true,
            then: (schema) => schema.required().nonNullable(),
            otherwise: (schema) => schema.nullable()
        })
        .nullable(),
    RECAPTCHA_VALID_FOR: yup
        .number()
        .defined()
        .when('RECAPTCHA_ENABLED', {
            is: true,
            then: (schema) => schema.required().nonNullable(),
            otherwise: (schema) => schema.nullable()
        })
        .nullable(),
    G_ANALYTICS_ENABLED: yup.boolean().defined().required(),
    G_ANALYTICS_KEY: yup
        .string()
        .defined()
        .when('G_ANALYTICS_ENABLED', {
            is: true,
            then: (schema) => schema.required().nonNullable(),
            otherwise: (schema) => schema.nullable()
        })
        .nullable(),
    G_ANALYTICS_URL: yup
        .string()
        .defined()
        .when('G_ANALYTICS_ENABLED', {
            is: true,
            then: (schema) => schema.required().nonNullable(),
            otherwise: (schema) => schema.nullable()
        })
        .nullable(),
    UPVS_LOGIN_URL: yup
        .string()
        .defined()
        .when('G_ANALYTICS_ENABLED', {
            is: true,
            then: (schema) => schema.required().nonNullable(),
            otherwise: (schema) => schema.nullable()
        })
        .nullable(),
    OLD_RPO_URL: yup.string().defined().required(),
    END_SERVICES_INFO_URL: yup.string().defined().required(),
    REST_API_INFO_URL: yup.string().defined().required(),
    GENERAL_INFO_URL: yup.string().defined().required(),
    HELP_PDF_SK_URL: yup.string().defined().required(),
    HELP_PDF_EN_URL: yup.string().defined().required(),
    CONTACTS_URL: yup.string().defined().required(),
    CONTENT_MANAGER_URL: yup.string().defined().required(),
    SUSR_URL: yup.string().defined().required(),
    IDSK_URL: yup.string().defined().required(),
    OVM_URL: yup.string().defined().required(),
    STATIC_BANNER_TAG_SK: yup.string().defined().nullable(),
    STATIC_BANNER_TAG_EN: yup.string().defined().nullable(),
    STATIC_BANNER_SK: yup.string().defined().nullable(),
    STATIC_BANNER_EN: yup.string().defined().nullable()
});

// Validácia správnosti premenných prostredia
environmentSchema.validateSync(ENV);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const protect = (guardFn: () => boolean, routes: RouteObject, fallbackPath?: string) => {
    if (guardFn()) {
        return routes;
    } else {
        return {
            path: fallbackPath ?? routes.path,
            element: <PermissionsPage />
        };
    }
};

const BASE_PATH = process.env.PUBLIC_URL && process.env.PUBLIC_URL.trim().length > 0 ? process.env.PUBLIC_URL : '/';

const Router: React.FC = () => {
    const { isAuthenticated, hasRole, user } = useContext(AuthContext);

    const isRpoRead = hasRole(UserRole.RPO_READ);
    const isRpoKuvPoRepresentative = hasRole(UserRole.RPO_KUV_PO_REPRESENTATIVE);
    const isUpvs = user?.isUPVS === true;

    const organizationRoutes = useMemo<RouteObject>(() => {
        return {
            path: 'organization',
            element: <Outlet />,
            handle: {
                breadcrumb: {
                    title: 'organization'
                }
            },
            children: [
                {
                    index: true,
                    element: <OrganizationSearchResults />
                },
                {
                    path: ':organizationId/:withHistory',
                    element: <Outlet />,
                    handle: {
                        breadcrumb: {
                            title: 'organizationDetail'
                        }
                    },
                    children: [
                        {
                            index: true,
                            element: <OrganizationDetail />
                        },
                        protect(() => isUpvs, {
                            path: 'extractRequest',
                            element: <ExtractRequest />,
                            handle: {
                                breadcrumb: {
                                    title: 'extractRequest'
                                }
                            }
                        }),
                        {
                            path: 'organizationUnit',
                            element: <Outlet />,
                            handle: {
                                breadcrumb: {
                                    title: 'organizationUnits'
                                }
                            },
                            children: [
                                {
                                    index: true,
                                    element: <OrganizationUnits />
                                },
                                {
                                    path: ':organizationUnitId',
                                    element: <Outlet />,
                                    handle: {
                                        breadcrumb: {
                                            title: 'organizationUnit'
                                        }
                                    },
                                    children: [
                                        {
                                            index: true,
                                            element: <OrganizationDetail isUnit={true} />
                                        },
                                        protect(() => isUpvs, {
                                            path: 'extractRequest',
                                            element: <ExtractRequest />,
                                            handle: {
                                                breadcrumb: {
                                                    title: 'extractRequest'
                                                }
                                            }
                                        })
                                    ]
                                }
                            ]
                        },
                        {
                            path: 'operation',
                            element: <Outlet />,
                            handle: {
                                breadcrumb: {
                                    title: 'operations'
                                }
                            },
                            children: [
                                {
                                    index: true,
                                    element: <OrganizationUnits isOperation={true} />
                                },
                                {
                                    path: ':organizationUnitId',
                                    element: <Outlet />,
                                    handle: {
                                        breadcrumb: {
                                            title: 'operation'
                                        }
                                    },
                                    children: [
                                        {
                                            index: true,
                                            element: <OrganizationDetail isOperation={true} />
                                        },
                                        protect(() => isUpvs, {
                                            path: 'extractRequest',
                                            element: <ExtractRequest />,
                                            handle: {
                                                breadcrumb: {
                                                    title: 'extractRequest'
                                                }
                                            }
                                        })
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        };
    }, [isUpvs]);

    const poRequestExtract = useMemo<RouteObject>(() => {
        return {
            path: 'poRequestExtract',
            element: <PoRequestExtract />,
            handle: {
                breadcrumb: {
                    title: 'poRequestExtract'
                }
            }
        };
    }, []);

    const mandatoryPersonRoutes = useMemo<RouteObject>(() => {
        return {
            path: 'mandatoryPerson',
            element: <Outlet />,
            handle: {
                breadcrumb: {
                    title: 'mandatoryPerson'
                }
            },
            children: [
                {
                    index: true,
                    element: <SearchRequest />
                },
                {
                    path: 'results',
                    element: <Outlet />,
                    handle: {
                        breadcrumb: {
                            title: 'mpSearchResults'
                        }
                    },
                    children: [
                        {
                            index: true,
                            element: <RequestSearchResults />
                        },
                        {
                            path: 'registration/:requestId?',
                            element: <Request type='registration' key='registration' />,
                            handle: {
                                breadcrumb: {
                                    title: 'registrationRequest'
                                }
                            }
                        },
                        {
                            path: 'deregistration/:requestId?',
                            element: <Request type='deregistration' key='deregistration' />,
                            handle: {
                                breadcrumb: {
                                    title: 'deregistrationRequest'
                                }
                            }
                        },
                        {
                            path: 'reset/:requestId?',
                            element: <Request type='reset' key='reset' />,
                            handle: {
                                breadcrumb: {
                                    title: 'resetRequest'
                                }
                            }
                        }
                    ]
                },
                {
                    path: 'registration/:requestId?',
                    element: <Request type='registration' key='new_registration' />,
                    handle: {
                        breadcrumb: {
                            title: 'registrationRequest'
                        }
                    }
                },
                {
                    path: 'deregistration/:requestId?',
                    element: <Request type='deregistration' key='new_deregistration' />,
                    handle: {
                        breadcrumb: {
                            title: 'deregistrationRequest'
                        }
                    }
                },
                {
                    path: 'reset/:requestId?',
                    element: <Request type='reset' key='new_reset' />,
                    handle: {
                        breadcrumb: {
                            title: 'resetRequest'
                        }
                    }
                }
            ]
        };
    }, []);

    const router = useMemo<ReturnType<typeof createBrowserRouter>>(
        () =>
            createBrowserRouter(
                [
                    {
                        path: '/',
                        element: <Main />,
                        errorElement: (
                            <Main>
                                <ErrorPage />
                            </Main>
                        ),
                        handle: {
                            breadcrumb: {
                                title: 'home'
                            }
                        },
                        children: [
                            {
                                index: true,
                                element: <Home />
                            },
                            {
                                path: 'login',
                                element: <Login />,
                                handle: {
                                    breadcrumb: {
                                        title: 'login'
                                    }
                                }
                            },
                            {
                                path: 'cookies',
                                element: <Cookies />,
                                handle: {
                                    breadcrumb: {
                                        title: 'cookies'
                                    }
                                }
                            },
                            {
                                path: 'declaration',
                                element: <Declaration />,
                                handle: {
                                    breadcrumb: {
                                        title: 'declaration'
                                    }
                                }
                            },
                            protect(() => !isAuthenticated || isRpoRead, organizationRoutes, 'organization/*'),
                            protect(() => isRpoKuvPoRepresentative, mandatoryPersonRoutes, 'mandatoryPerson/*'),
                            protect(() => isRpoKuvPoRepresentative, poRequestExtract, 'poRequestExtract')
                        ]
                    }
                ],
                {
                    basename: BASE_PATH
                }
            ),
        [isAuthenticated, isRpoRead, isRpoKuvPoRepresentative, organizationRoutes, mandatoryPersonRoutes, poRequestExtract]
    );

    return <RouterProvider router={router} />;
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity
        }
    }
});

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <Router />
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </React.StrictMode>
);
