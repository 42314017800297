import { useCallback } from 'react';
import { CodelistApi } from '../api';
import { useQuery } from '@tanstack/react-query';

const codelistApi = new CodelistApi();

export enum CodelistAcronymName {
    ACCESS_TYPE = 'ACCESS_TYPE',
    ATTACHMENT_TYPE = 'ATTACHMENT_TYPE',
    REGISTER_APPL_STATUS = 'REGISTER_APPL_STATUS',
    REGISTER_APPL_TYPE = 'REGISTER_APPL_TYPE'
}

type useCachedCodelistReturnObject = {
    isFetching: boolean;
    isError: boolean;
    id: number | undefined;
};

type useCachedCodelistHook = (codelistAcronymName: CodelistAcronymName, code: string) => useCachedCodelistReturnObject;

const useCachedCodelist: useCachedCodelistHook = (codelistAcronymName, code) => {
    const fetchCodelistCodeId = useCallback((codelistAcronymName: CodelistAcronymName, code: string, signal?: AbortSignal) => {
        return codelistApi
            .getCodelistCodesPublic(
                {
                    query: {
                        skippedIds: [],
                        codelistAcronymName: codelistAcronymName,
                        code: code,
                        validOnly: true
                    },
                    count: 1,
                    sort: [],
                    start: 0
                },
                false,
                signal
            )
            .then((res) => {
                if (res.results && res.results.length === 1) {
                    return res.results[0].codeId;
                }
                return undefined;
            });
    }, []);

    const {
        data: id,
        isFetching,
        isError
    } = useQuery({
        queryKey: ['codelistItem', 'ID', codelistAcronymName, code] as const,
        queryFn: ({ queryKey, signal }) => fetchCodelistCodeId(queryKey[2], queryKey[3], signal),
        retry: false
    });

    return {
        id,
        isFetching,
        isError
    };
};

export default useCachedCodelist;
