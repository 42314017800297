import React, { PropsWithChildren, ReactElement, useId, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, To } from 'react-router-dom';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, GridCol, GridRow, Notice, Typography, TypographyProps } from '@mc-ui/idsk-react-components';

type LoaderErrorProps = {
    onCancel?: To | (() => void);
    onCancelState?: any;
    errorMessage?: ReactElement | string;
    id: string;
};

const LoaderError: React.FC<LoaderErrorProps> = ({ onCancel, onCancelState, errorMessage, id }) => {
    const { t } = useTranslation();

    const message = useMemo(() => {
        if (errorMessage && typeof errorMessage !== 'boolean') {
            return errorMessage;
        }
        return t('loader.error.message');
    }, [errorMessage, t]);

    return (
        <GridRow>
            <GridCol size='2/3'>
                <Notice title={t('loader.error.title')} type='error'>
                    <span id={id}>
                        {message}{' '}
                        {onCancel &&
                            (typeof onCancel === 'function' ? (
                                <Button onClick={onCancel} type='button' variant='link' additionalClasses='govuk-body'>
                                    {t('loader.error.goBack')}
                                </Button>
                            ) : (
                                <Link to={onCancel} state={onCancelState} className='govuk-link'>
                                    {t('loader.error.goBack')}
                                </Link>
                            ))}
                    </span>
                </Notice>
            </GridCol>
        </GridRow>
    );
};

type LoaderQuestionProps = {
    hasTitle: boolean;
    id: string;
    text: ReactElement | string;
    onConfirm: To | (() => void);
    onConfirmState?: any;
    onCancel: To | (() => void);
    onCancelState?: any;
    confirmTitle: string;
    cancelTitle: string;
};

const LoaderQuestion: React.FC<LoaderQuestionProps> = ({
    hasTitle,
    id,
    text,
    onConfirm,
    onConfirmState,
    onCancel,
    onCancelState,
    confirmTitle,
    cancelTitle
}) => {
    const { t } = useTranslation();

    return (
        <div className='rpo-loader-content' id={id}>
            <Typography type='h1' forcedElementType={hasTitle ? 'h2' : 'h1'}>
                {t('loader.title')}
            </Typography>
            <Typography type='normal'>{text}</Typography>
            <div className='rpo-flex rpo-flex-gap'>
                {typeof onConfirm === 'function' ? (
                    <Button onClick={onConfirm} type='button' variant='primary' additionalClasses='govuk-body'>
                        {confirmTitle}
                    </Button>
                ) : (
                    <Button type='button' variant='primary' additionalClasses='govuk-body' as={Link} to={onConfirm} state={onConfirmState}>
                        {confirmTitle}
                    </Button>
                )}

                {typeof onCancel === 'function' ? (
                    <Button onClick={onCancel} type='button' variant='secondary' additionalClasses='govuk-body'>
                        {cancelTitle}
                    </Button>
                ) : (
                    <Button type='button' variant='secondary' additionalClasses='govuk-body' as={Link} to={onCancel} state={onCancelState}>
                        {cancelTitle}
                    </Button>
                )}
            </div>
        </div>
    );
};

type LoaderBusyProps = {
    hasTitle: boolean;
    id: string;
    onCancel?: To | (() => void);
    onCancelState?: any;
    loaderMessage?: ReactElement | string;
};

const LoaderBusy: React.FC<LoaderBusyProps> = ({ hasTitle, id, onCancel, onCancelState, loaderMessage }) => {
    const { t } = useTranslation();

    return (
        <div className='rpo-loader-content' id={id}>
            <FontAwesomeIcon icon={faCircleNotch} className='rpo-loader-content__icon' />
            <Typography type='h1' forcedElementType={hasTitle ? 'h2' : 'h1'}>
                {t('loader.title')}
            </Typography>
            <Typography type='normal'>
                {t('loader.inprogress')}{' '}
                {onCancel &&
                    (typeof onCancel === 'function' ? (
                        <Button onClick={onCancel} type='button' variant='link' additionalClasses='govuk-body'>
                            {t('loader.cancel')}
                        </Button>
                    ) : (
                        <Link to={onCancel} state={onCancelState} className='govuk-link'>
                            {t('loader.cancel')}
                        </Link>
                    ))}
            </Typography>
            {typeof loaderMessage !== 'boolean' && <>{loaderMessage}</>}
        </div>
    );
};

type LoaderProps = PropsWithChildren & {
    isLoading: boolean | ReactElement | string;
    hasError?: boolean | ReactElement | string;
    question?: {
        text: ReactElement | string;
        onConfirm: To | (() => void);
        onConfirmState?: any;
        onCancel: To | (() => void);
        onCancelState?: any;
        confirmTitle: string;
        cancelTitle: string;
    };
    title?: string;
    titleProps?: Partial<TypographyProps>;
    onCancel?: To | (() => void);
    onCancelState?: any;
};

const Loader: React.FC<LoaderProps> = ({
    children,
    isLoading,
    hasError = false,
    question,
    title,
    titleProps = {
        type: 'h1'
    },
    onCancel,
    onCancelState
}) => {
    const id = useId();

    if (isLoading === false && !hasError && !question) {
        return <>{children}</>;
    }

    return (
        <div role='alert' aria-busy='true' aria-describedby={id}>
            {title && <Typography {...titleProps}>{title}</Typography>}
            {hasError ? (
                <LoaderError
                    onCancel={onCancel}
                    onCancelState={onCancelState}
                    errorMessage={typeof hasError !== 'boolean' ? hasError : undefined}
                    id={id}
                />
            ) : !question ? (
                <LoaderBusy
                    onCancel={onCancel}
                    onCancelState={onCancelState}
                    loaderMessage={typeof isLoading !== 'boolean' ? isLoading : undefined}
                    id={id}
                    hasTitle={!!title}
                />
            ) : (
                <LoaderQuestion {...question} id={id} hasTitle={!!title} />
            )}
        </div>
    );
};

export default Loader;
