import { OrganizationResult } from '../api';
import { isPastDate, parseToDate } from './date';
import { decode } from './string';

export type OrganizationResultKeys = keyof OrganizationResult;
export type SortOrder = 'asc' | 'desc' | undefined;
export type FilterField = {
    key: OrganizationResultKeys;
    value: string;
};

export const filterOrganizationResults = (data: OrganizationResult[], filter: FilterField[]): OrganizationResult[] => {
    return data.filter((d) => {
        if (filter.length === 0) {
            return true;
        } else {
            return filter.every((c) => {
                if (!d[c.key] && c.key !== 'historical') {
                    return false;
                }
                switch (c.key) {
                    case 'orgNameFullName':
                    case 'orgIdentifierValue':
                    case 'addrMunicipality':
                        const stringValue = decode(d[c.key].toString().toLowerCase())
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '');
                        const critValue = c.value
                            ?.toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '');
                        if (stringValue.includes(critValue)) {
                            return true;
                        }
                        break;
                    case 'orgEstablishmentDate':
                        const dateFrom = parseToDate(d[c.key].toString())!;
                        if (dateFrom === null) {
                            return false;
                        }
                        const critFrom = new Date(c.value);
                        return critFrom.getTime() <= dateFrom.getTime();
                    case 'orgTerminationDate':
                        const dateTo = parseToDate(d[c.key].toString())!;
                        if (dateTo === null) {
                            return false;
                        }
                        const critTo = new Date(c.value);
                        return dateTo!.getTime() <= critTo.getTime();
                    case 'historical': // TODO overit
                        return !isPastDate(d['orgTerminationDate']);
                }
                return false;
            });
        }
    });
};

export const sortOrganizationResults = (data: OrganizationResult[], sort: string | null): OrganizationResult[] => {
    return data.slice().sort((a, b) => {
        if (sort) {
            const [name, order] = sort.split(',') as [OrganizationResultKeys, SortOrder];
            switch (name) {
                case 'orgNameFullName':
                case 'orgIdentifierValue':
                case 'addrMunicipality':
                case 'sourceRegister':
                    const textA = decode(a[name]);
                    const textB = decode(b[name]);
                    if (order === 'asc') {
                        return textA.localeCompare(textB, undefined, {
                            sensitivity: 'base'
                        });
                    } else {
                        return textB.localeCompare(textA, undefined, {
                            sensitivity: 'base'
                        });
                    }
                case 'orgEstablishmentDate':
                case 'orgLastActualizationDate':
                case 'orgTerminationDate':
                    const dateA = parseToDate(a[name]);
                    const dateB = parseToDate(b[name]);
                    if (order === 'asc') {
                        return (dateA?.getTime() ?? 0) - (dateB?.getTime() ?? 0);
                    } else {
                        return (dateB?.getTime() ?? 0) - (dateA?.getTime() ?? 0);
                    }
            }
        }
        return 0;
    });
};
