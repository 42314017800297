import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { PoRequestApi } from '../../api/apis/PoRequestApi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { searchParamsToPoRequestSearchCriteriaQuery } from '../../utils/searchParams';
import { PoRequestResult } from '../../api/model/PoRequestResult';
import Loader from '../../components/common/Loader';
import { useTranslation } from 'react-i18next';
import { Button, Pagination, Select, Table, TableHeaderCol, Typography } from '@mc-ui/idsk-react-components';
import { decode } from '../../utils/string';
import { PAGE_SIZES, RESTORE_STATE } from '../../utils/contants';
import { sortAdapter } from '../../api/apis/common';
import { PoRequestSearchCriteria } from '../../api/model/PoRequestSearchCriteria';
import { restoreState } from '../../utils/state';
import { RequestType } from './Request';
import useCachedCodelist, { CodelistAcronymName } from '../../hooks/useCachedCodelist';

const poRequestApi = new PoRequestApi();

type State = {
    search?: string;
    sort?: string | null;
    page?: number;
    pageSize?: number;
    wide?: boolean;
};

const prevState: State = {};

const RequestSearchResults = () => {
    const navigate = useNavigate();
    const { state, search: locationSearch } = useLocation();
    const search = restoreState(state, prevState, 'search', locationSearch);
    const searchCriteria = useMemo(() => searchParamsToPoRequestSearchCriteriaQuery(new URLSearchParams(search)), [search]);
    const [hasError, setHasError] = useState<boolean>(false);
    const [data, setData] = useState<PoRequestResult[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [wide, setWide] = useState(restoreState(state, prevState, 'wide', false));
    const [sort, setSort] = useState<string | null>(restoreState(state, prevState, 'sort', null));
    const [page, setPage] = useState(restoreState(state, prevState, 'page', 0));
    const [pageSize, setPageSize] = useState<number>(restoreState(state, prevState, 'pageSize', +PAGE_SIZES[0].value));
    const uniqueId = useId();
    const tableId = 'table' + uniqueId;

    const { t } = useTranslation();

    const {
        id: cliRegisterApplTypeRegistrationId,
        isFetching: isFetchingRegistration,
        isError: isErrorRegistration
    } = useCachedCodelist(CodelistAcronymName.REGISTER_APPL_TYPE, '0');
    const {
        id: cliRegisterApplTypeDeregistrationId,
        isFetching: isFetchingDeregistration,
        isError: isErrorDeregistration
    } = useCachedCodelist(CodelistAcronymName.REGISTER_APPL_TYPE, '1');
    const {
        id: cliRegisterApplTypeResetId,
        isFetching: isFetchingReset,
        isError: isErrorReset
    } = useCachedCodelist(CodelistAcronymName.REGISTER_APPL_TYPE, '2');

    const isCodelistCodeFetching = isFetchingRegistration || isFetchingDeregistration || isFetchingReset;
    const isCodelistCodeError = isErrorRegistration || isErrorDeregistration || isErrorReset;

    const getDetailLink = useCallback(
        (request: PoRequestResult) => {
            let requestType: RequestType | undefined;

            switch (request.poRequestTypeId) {
                case cliRegisterApplTypeRegistrationId: {
                    requestType = 'registration';
                    break;
                }
                case cliRegisterApplTypeDeregistrationId: {
                    requestType = 'deregistration';
                    break;
                }
                case cliRegisterApplTypeResetId: {
                    requestType = 'reset';
                    break;
                }
            }

            if (!requestType) {
                return decode(request.poRequestNumberWithYear);
            }

            return (
                <Link to={`/mandatoryPerson/results/${requestType}/${request.id}`} className='govuk-link'>
                    {decode(request.poRequestNumberWithYear)}
                </Link>
            );
        },
        [cliRegisterApplTypeDeregistrationId, cliRegisterApplTypeRegistrationId, cliRegisterApplTypeResetId]
    );

    const headerCols = useMemo<TableHeaderCol<PoRequestResult>[]>(() => {
        if (wide) {
            return [
                {
                    label: t('mandatoryPerson.poOrganizationIdentifierHint'),
                    name: 'poOrganizationIdentifier',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return decode(row.poOrganizationIdentifier);
                    }
                },
                {
                    label: t('mandatoryPerson.poName'),
                    name: 'poName',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return decode(row.poName);
                    }
                },
                {
                    label: t('mandatoryPerson.poRequestNumber'),
                    name: 'poRequestNumberWithYear',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return getDetailLink(row);
                    }
                },
                {
                    label: t('mandatoryPerson.poRequestType'),
                    name: 'poRequestType',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return decode(row.poRequestType);
                    }
                },
                {
                    label: t('mandatoryPerson.poSendDate'),
                    name: 'poSendDate',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return decode(row.poSendDate);
                    }
                },
                {
                    label: t('mandatoryPerson.poStatus'),
                    name: 'poStatus',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return decode(row.poStatus);
                    }
                },
                {
                    label: t('mandatoryPerson.poPocessingDate'),
                    name: 'poPocessingDate',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return decode(row.poPocessingDate);
                    }
                }
            ];
        } else {
            return [
                {
                    label: t('mandatoryPerson.poOrganizationIdentifierHint'),
                    name: 'poOrganizationIdentifier',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return decode(row.poOrganizationIdentifier);
                    }
                },
                {
                    label: t('mandatoryPerson.poRequestNumber'),
                    name: 'poRequestNumberWithYear',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return getDetailLink(row);
                    }
                },
                {
                    label: t('mandatoryPerson.poRequestType'),
                    name: 'poRequestType',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return decode(row.poRequestType);
                    }
                },
                {
                    label: t('mandatoryPerson.poStatus'),
                    name: 'poStatus',
                    numeric: false,
                    sortable: true,
                    formatter(value, row) {
                        return decode(row.poStatus);
                    }
                }
            ];
        }
    }, [getDetailLink, t, wide]);

    const onFullscreenHandler = useCallback(() => {
        setWide((p) => !p);
    }, []);

    useEffect(() => {
        return () => {
            prevState.search = search;
            prevState.sort = sort;
            prevState.page = page;
            prevState.pageSize = pageSize;
            prevState.wide = wide;
        };
    }, [search, sort, page, pageSize, wide]);

    useEffect(() => {
        if (state === RESTORE_STATE && locationSearch === '' && prevState.search && prevState.search !== '') {
            navigate(
                {
                    search: prevState.search
                },
                {
                    replace: true,
                    state
                }
            );
        } else if (locationSearch === '') {
            navigate('/');
        }
    }, [state, locationSearch, navigate]);

    useEffect(() => {
        if (searchCriteria.poOrganizationIdentifier && searchCriteria.poRequestStatusId) {
            setHasError(false);
            const controller = new AbortController();
            poRequestApi
                .searchPoRequest(
                    {
                        start: page * pageSize,
                        count: pageSize,
                        sort: sortAdapter<PoRequestSearchCriteria['query']>(sort ? [sort] : []),
                        query: searchCriteria
                    },
                    controller.signal
                )
                .then((data) => {
                    setData(data.results);
                    setIsLoading(false);
                })
                .catch((e: any) => {
                    if (e?.name !== 'AbortError') {
                        setHasError(true);
                    }
                });

            return () => controller.abort();
        } else {
            navigate('/mandatoryPerson');
        }
    }, [page, pageSize, sort, searchCriteria, navigate]);

    return (
        <Loader
            title={t('table.heading.title')}
            isLoading={isLoading || isCodelistCodeFetching}
            hasError={hasError || isCodelistCodeError}
            onCancel='/mandatoryPerson'
        >
            <Table
                tableId={tableId}
                heading={{
                    title: t('table.heading.title'),
                    headingType: 'h1'
                }}
                contentAbove={
                    <div className='rpo-search-results__page-size '>
                        <Select
                            value={pageSize}
                            onChange={(e) => setPageSize(+e.target.value)}
                            options={PAGE_SIZES}
                            label={t('table.pagination.pageSize')}
                        />
                        <Button variant='secondary' onClick={onFullscreenHandler} aria-expanded={wide} aria-controls={tableId}>
                            {t(wide ? 'table.heading.narrow' : 'table.heading.fullscreen')}
                        </Button>
                        <div className='rpo-flex-1'></div>
                        <Typography type='normal' forcedElementType='div'>
                            {data.length > 0
                                ? t('table.pagination.shownRecords', {
                                      recordStart: page * pageSize + 1,
                                      recordEnd: page * pageSize + data.length,
                                      records: isLoading ? t('table.pagination.loading') : data.length
                                  })
                                : t('table.pagination.noRecords')}
                        </Typography>
                    </div>
                }
                header={{
                    cols: headerCols,
                    localisation: {
                        ascendingLabel: t('table.header.ascendingLabel'),
                        descendingLabel: t('table.header.descendingLabel'),
                        unsortedLabel: t('table.header.unsortedLabel')
                    },
                    onSort: setSort,
                    sorted: sort
                }}
                meta={{
                    actions: (
                        <Pagination
                            page={page}
                            onPageChange={setPage}
                            numOfPages={Math.ceil(data.length / pageSize)}
                            localisation={{
                                prev: t('table.pagination.prev'),
                                next: t('table.pagination.next'),
                                goto: t('table.pagination.goto')
                            }}
                        />
                    )
                }}
                data={data}
                id='id'
                wide={wide}
            />
        </Loader>
    );
};

export default RequestSearchResults;
