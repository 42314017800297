import { handleJSON } from './common';
import { NoticeDetail } from '../model/NoticeDetail';

export class NoticeApi {
    async getActualNoticeDetails(signal?: AbortSignal): Promise<Array<NoticeDetail>> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/notice/getActualNotices', {
                method: 'POST',
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return [];
            }
            throw e;
        }
    }
}
