import React from 'react';

import { Notice } from '@mc-ui/idsk-react-components';
import { useTranslation } from 'react-i18next';

const PermissionsPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Notice title={t('error.permissions')} type='error'>
            {t('error.permissionsMessage')}
        </Notice>
    );
};

export default PermissionsPage;
