import React, { useMemo } from 'react';
import { useRouteError } from 'react-router-dom';

import { Notice } from '@mc-ui/idsk-react-components';
import { useTranslation } from 'react-i18next';

export default function ErrorPage() {
    const { t } = useTranslation();
    const error = useRouteError() as any;

    const title = useMemo(() => {
        if (typeof error === 'object' && error.status === 404) {
            return t('error.pageNotFound');
        }
        return t('error.generalError');
    }, [t, error]);

    return (
        <Notice title={title} type='error'>
            {error.statusText ? `${error.status} ${error.statusText}` : error.message}
        </Notice>
    );
}
