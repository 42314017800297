export const PAGE_SIZES = [
    {
        label: '20',
        value: '20'
    },
    {
        label: '50',
        value: '50'
    },
    {
        label: '100',
        value: '100'
    },
    {
        label: '500',
        value: '500'
    }
];

export const RESTORE_STATE = 'restore';
export const WITH_HISTORY = 'withHistory';
export const CURRENT = 'current';

export const SUBSTITUTE_DATE = '01.01.1800';

export const SIGN_AND_SUBMIT = 'signAndSubmit';
