import { Address, CodelistCode, PersonName } from '../api';
import * as mime from 'react-native-mime-types';

/** Returns undefined if value was null, undefined, blank or returns string */
const toStringOrUndefined = (val: string | undefined | null | string[] | number, separator: string = ', '): string | undefined => {
    if (
        val === undefined ||
        val === null ||
        (Array.isArray(val) && val.length === 0) ||
        (typeof val === 'string' && val.trim().length === 0)
    ) {
        return undefined;
    }
    if (Array.isArray(val)) {
        const res = val
            .map((v) => toStringOrUndefined(v, separator))
            .filter((v) => v !== undefined)
            .join(separator);
        return toStringOrUndefined(res);
    }
    if (typeof val === 'number') {
        return val.toString();
    }
    return val.trim();
};

/** Join to strings with separator, if first string is blank, it returns only second string without separator */
const append = (s1: string, s2: string | undefined, separator: string = ''): string => {
    if (s2 !== undefined) {
        if (s1.trim().length === 0) {
            return s2;
        } else {
            return s1 + separator + s2;
        }
    } else {
        return s1;
    }
};

/** Decode html entities to readable string */
export const decode = (s: string | undefined | null): string => {
    if (s === undefined || s === null) {
        return '';
    }
    const el = document.createElement('div');
    el.innerHTML = s;
    return el.textContent?.trim() ?? '';
};

export const addressToString = (address?: Address): string | undefined => {
    if (address === undefined) {
        return;
    }
    const formatedAddress = toStringOrUndefined(address.formatedAddress);
    if (formatedAddress) {
        return decode(formatedAddress);
    }

    const street = toStringOrUndefined(address.street);
    const buildingNumber = toStringOrUndefined(address.buildingNumber);
    const _regNumber = toStringOrUndefined(address.regNumber);
    const regNumber = _regNumber !== '0' || !buildingNumber ? _regNumber : undefined;
    const psc = toStringOrUndefined(address.psc);
    const municipality = toStringOrUndefined(address.municipality);
    const country = toStringOrUndefined(address.country);
    const district = toStringOrUndefined(address.district);

    let label = '';
    if (street) {
        label = append(label, street);
        label = append(label, regNumber, ' ');
        label = append(label, buildingNumber, regNumber ? '/' : ' ');
        label = append(label, psc, ', ');
        label = append(label, municipality, ', ');
        label = append(label, district, ', ');
    } else {
        label = append(label, municipality);
        label = append(label, district, ', ');
        label = append(label, regNumber, ' ');
        label = append(label, buildingNumber, regNumber ? '/' : ' ');
        label = append(label, psc, ', ');
    }
    if (country) {
        if (label.trim() !== '') {
            label = append(label, country, ', ');
        }
    }
    return decode(label);
};

export const personNameToString = (personName?: PersonName): string | undefined => {
    if (personName === undefined) {
        return;
    }
    const formatedName = toStringOrUndefined(personName.formatedName);
    if (formatedName) {
        return decode(formatedName);
    }

    const prefixes = toStringOrUndefined(personName.prefixes, ' ');
    const givenNames = toStringOrUndefined(personName.givenNames, ' ');
    const familyNames = toStringOrUndefined(personName.familyNames, ' ');
    const givenFamilyNames = toStringOrUndefined(personName.givenFamilyNames, ' ');
    const postfixes = toStringOrUndefined(personName.postfixes, ', ');

    let label = '';
    label = append(label, prefixes);
    label = append(label, givenNames, ' ');
    label = append(label, familyNames, ' ');
    label = append(label, givenFamilyNames, ' ');
    label = append(label, postfixes, ', ');

    return decode(label);
};

export const isNotBlank = (s?: string | null) => {
    if (s === undefined) {
        return false;
    }
    if (s === null) {
        return false;
    }
    if (s.trim() === '') {
        return false;
    }
    return true;
};

export const isBlank = (s?: string | null) => {
    return !isNotBlank(s);
};

export const codelistCodeToLabel = (clc: CodelistCode | null) => {
    if (clc === null) {
        return ' ';
    }
    const codeAcronym = (clc.codeAcronym ?? '').trim();
    const codeName = (clc.codeName ?? '').trim();
    const label = codeAcronym && codeName ? `${codeAcronym} – ${codeName}` : codeName ? codeName : codeAcronym ? codeAcronym : ' ';
    return label;
};

export const numberToString = (value: number, maxDecimals: number = 2): string => {
    const formattedValue = value.toFixed(maxDecimals);
    return parseFloat(formattedValue) === Math.floor(value) ? value.toFixed(0) : formattedValue.replace(/\.?0+$/, '');
};

export const formatBytes = (bytes: number) => {
    const num = +bytes;
    if (num < 1024) {
        return num + ' B';
    } else if (num < 1024 * 1024) {
        const sizeInKB = num / 1024;
        return numberToString(sizeInKB) + ' kB';
    } else {
        const sizeInMB = num / (1024 * 1024);
        return numberToString(sizeInMB) + ' MB';
    }
};

export const mimeToExt = (mimeType: string) => {
    const extension = mime.extension(mimeType);
    return extension ? extension : mimeType;
};
