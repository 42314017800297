import { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faRotate, faVolumeHigh } from '@fortawesome/free-solid-svg-icons';

import { GridCol, GridRow, Typography } from '@mc-ui/idsk-react-components';

import { Input } from '../form/Input';
import { AuthApi } from '../../api/apis';

const authApi = new AuthApi();

type CaptchaProps = {
    name: string;
    fullscreen?: boolean;
};

const Captcha: React.FC<CaptchaProps> = ({ name, fullscreen = false }) => {
    const { t } = useTranslation();
    const imageRef = useRef<HTMLImageElement>(null);
    const audioRef = useRef<HTMLAudioElement>(null);

    const [isPlaying, setIsPlaying] = useState(false);

    const loadCaptcha = useCallback(
        (signal?: AbortSignal) =>
            authApi
                .generateCaptcha(signal)
                .then((data) => {
                    if (!signal?.aborted) {
                        if (imageRef.current) {
                            imageRef.current.setAttribute('src', 'data:image/png;base64, ' + data.graphics);
                        }
                        if (audioRef.current) {
                            audioRef.current.setAttribute('src', 'data:audio/mp3;base64, ' + data.audio);
                        }
                    }
                })
                .catch((e) => {
                    // TODO chybova hlaska ak nejde server ?
                }),
        []
    );

    const playCapcha = useCallback(async () => {
        if (audioRef.current) {
            if (audioRef.current.paused) {
                audioRef.current.currentTime = 0;
                audioRef.current.play();
            } else {
                audioRef.current.pause();
            }
        }
    }, [audioRef]);

    useEffect(() => {
        const controller = new AbortController();
        loadCaptcha(controller.signal);
        return () => controller.abort();
    }, [loadCaptcha]);

    return (
        <fieldset className='rpo-captcha'>
            <Typography type='normal' forcedElementType='legend'>
                {t('captcha.title')}
            </Typography>
            <div className='rpo-captcha__content'>
                <img ref={imageRef} alt={t('captcha.title')} />
                <audio
                    ref={audioRef}
                    onPlay={() => setIsPlaying(true)}
                    onEnded={() => setIsPlaying(false)}
                    onPause={() => setIsPlaying(false)}
                />
                <div className='rpo-captcha__controls'>
                    <button type='button' onClick={() => loadCaptcha()} aria-label={t('captcha.reload')}>
                        <FontAwesomeIcon icon={faRotate} />
                    </button>
                    <button type='button' onClick={playCapcha} aria-label={isPlaying ? t('captcha.stop') : t('captcha.play')}>
                        <FontAwesomeIcon icon={isPlaying ? faPause : faVolumeHigh} />
                    </button>
                </div>
            </div>
            <GridRow>
                <GridCol size={fullscreen ? 'full' : '1/2'}>
                    <Input required label={t('captcha.inputTitle')} hint={t('captcha.inputHint')} name={name} />
                </GridCol>
            </GridRow>
        </fieldset>
    );
};

export default Captcha;
