import React, { useCallback, useContext, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Banner, BasicMain, Brand, Header as IdskHeader, MenuProps, Tricolor } from '@mc-ui/idsk-react-components';

import { AuthContext } from '../../store/auth-context';
import { UserRole } from '../../api';

const StaticBanner: React.FC = () => {
    const {
        i18n: { language }
    } = useTranslation();

    if (language === 'en' && ENV.STATIC_BANNER_EN) {
        return (
            <Banner type='gray' tag={ENV.STATIC_BANNER_TAG_EN ?? undefined}>
                {ENV.STATIC_BANNER_EN}
            </Banner>
        );
    }
    if (language === 'sk' && ENV.STATIC_BANNER_SK) {
        return (
            <Banner type='gray' tag={ENV.STATIC_BANNER_TAG_SK ?? undefined}>
                {ENV.STATIC_BANNER_SK}
            </Banner>
        );
    }
    return null;
};

const Header: React.FC = () => {
    const { user, logout, hasRole } = useContext(AuthContext);
    const navigate = useNavigate();
    const {
        t,
        i18n: {
            language,
            changeLanguage,
            options: { resources }
        }
    } = useTranslation();

    const availableLangs = useMemo(
        () =>
            resources
                ? Object.keys(resources).map((l) => {
                      return {
                          code: l,
                          name: t('header.brand.' + l),
                          selected: language === l
                      };
                  })
                : [],
        [t, resources, language]
    );

    const mainLink = useMemo(() => {
        return {
            as: Link,
            to: '/',
            title: t('header.main.appName')
        };
    }, [t]);

    const onLoginHandler = useCallback(() => (ENV.UPVS_LOGIN_URL ? () => (window.location.href = ENV.UPVS_LOGIN_URL!) : undefined), []);

    const onLogoutHandler = useCallback(() => {
        logout();
        navigate('/');
    }, [logout, navigate]);

    const authenticatedUser = useMemo(() => {
        if (user) {
            return {
                username: user.login
            };
        }
    }, [user]);

    const menu = useMemo<Pick<MenuProps<typeof Link>, 'routes' | 'as'> | undefined>(() => {
        const isKuvPoRepresentative = hasRole(UserRole.RPO_KUV_PO_REPRESENTATIVE);
        if (!isKuvPoRepresentative) {
            return;
        }
        return {
            routes: [
                {
                    title: t('header.menu.organizationSearch'),
                    to: '/'
                },
                {
                    title: t('header.menu.mandatoryPerson.requests'),
                    children: [
                        {
                            title: t('header.menu.mandatoryPerson.registrationRequest'),
                            to: '/mandatoryPerson/registration'
                        },
                        {
                            title: t('header.menu.mandatoryPerson.deregistrationRequest'),
                            to: '/mandatoryPerson/deregistration'
                        },
                        {
                            title: t('header.menu.mandatoryPerson.resetRequest'),
                            to: '/mandatoryPerson/reset'
                        },
                        {
                            title: t('header.menu.mandatoryPerson.searchRequests'),
                            to: '/mandatoryPerson'
                        }
                    ]
                },
                {
                    title: t('header.menu.extractFromRKUV'),
                    to: '/poRequestExtract'
                }
            ],
            as: Link
        };
    }, [hasRole, t]);

    return (
        <IdskHeader>
            <StaticBanner />
            <Tricolor />
            <Brand
                brandBtn={t('header.brand.btn')}
                brandBtnMobile={t('header.brand.btnMobile')}
                brandText={t('header.brand.text')}
                brandTextMobile={t('header.brand.textMobile')}
                langs={availableLangs}
                messageLeft={{
                    body: t('header.brand.message.left'),
                    title: t('header.brand.message.leftTitle')
                }}
                messageRight={{
                    body: t('header.brand.message.right'),
                    title: t('header.brand.message.rightTitle')
                }}
                onLangChange={changeLanguage}
                localisation={{
                    showInfoLabel: t('header.brand.showInfoLabel'),
                    showLangMenuLabel: t('header.brand.showLangMenuLabel'),
                    hideInfoLabel: t('header.brand.hideInfoLabel'),
                    hideLangMenuLabel: t('header.brand.hideLangMenuLabel')
                }}
            />
            <BasicMain
                headingType='span'
                link={mainLink}
                onLogin={onLoginHandler()}
                onLogout={onLogoutHandler}
                localisation={{
                    loginBtnTitle: t('header.main.loginEID'),
                    headingLinkTitle: t('header.main.headingLinkTitle'),
                    hideMenuAriaLabel: t('header.main.hideMenuAriaLabel'),
                    hideMenuTitle: t('header.main.hideMenuTitle'),
                    logoutBtnMobileTitle: t('header.main.logoutBtnMobileTitle'),
                    logoutBtnTitle: t('header.main.logoutBtnTitle'),
                    profileBtnTitle: t('header.main.profileBtnTitle'),
                    profileIconAlt: t('header.main.profileIconAlt'),
                    profileIconLoggedAlt: t('header.main.profileIconLoggedAlt'),
                    showMenuAriaLabel: t('header.main.showMenuAriaLabel'),
                    showMenuTitle: t('header.main.showMenuTitle'),
                    mainMenuAriaLabel: t('header.main.mainMenuAriaLabel'),
                    subMenuAriaLabel: t('header.main.subMenuAriaLabel')
                }}
                user={authenticatedUser}
                menu={menu}
            />
        </IdskHeader>
    );
};

export default Header;
