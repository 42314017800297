import React, { ReactElement, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Filter, GridCol, GridRow, Inset, SuccessInfo, Typography } from '@mc-ui/idsk-react-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import Loader from '../../components/common/Loader';
import { Input } from '../../components/form/Input';
import { RequestExtract } from '../../api/model/RequestExtract';
import { RequestExtractApi, SubmittedRequestResponse } from '../../api/apis/RequestExtractApi';
import { useNavigate, useParams } from 'react-router-dom';
import { decode } from '../../utils/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { SIGN_AND_SUBMIT } from '../../utils/contants';
import useSigner from '../../hooks/useSigner';

const requestExtractApi = new RequestExtractApi();

const defaultValue: RequestExtract = {
    legalEntityName: '',
    legalEntityIco: '',
    legalEntityRegister: '',
    physicalPersonPrefix: '',
    physicalPersonFirstName: '',
    physicalPersonFamilyName: '',
    physicalPersonPostfix: '',
    residenceStreetName: '',
    residenceRegNumberBuildingNumber: '',
    residenceMunicipality: '',
    residencePsc: '',
    residenceCountry: '',
    email: '',
    sourceRegister: '',
    registrationOffice: '',
    registrationNumber: '',
    protocolBusinessName: '',
    protocolIco: '',
    protocolFullAddress: '',
    deliveryType: '',

    // Non form attributes
    organizationId: undefined,
    protocolLegalFrom: undefined,
    protocolMunicipality: undefined,
    messageID: undefined,
    correlationID: undefined,
    businessID: undefined,
    objectID: undefined,
    canBeSigned: false
};

const ExtractRequest: React.FC = () => {
    const { t } = useTranslation();
    const { signAsice, ditecErrorToString } = useSigner();
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSigning, setIsSigning] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState<SubmittedRequestResponse['response'] | false>(false);
    const [hasError, setHasError] = useState<boolean | string | ReactElement>(false);
    const { organizationUnitId, organizationId } = useParams();
    const navigate = useNavigate();

    const [requestExtract, setRequestExtract] = useState<RequestExtract | null>(null);

    const schema = yup.object({
        legalEntityName: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        legalEntityIco: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        legalEntityRegister: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        physicalPersonPrefix: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        physicalPersonFirstName: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        physicalPersonFamilyName: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        physicalPersonPostfix: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        residenceStreetName: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        residenceRegNumberBuildingNumber: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        residenceMunicipality: yup
            .string()
            .defined()
            .trim()
            .max(250, ({ max }) => max)
            .required(),
        residencePsc: yup
            .string()
            .trim()
            .max(6, ({ max }) => max)
            .emptyAsUndefined(),
        residenceCountry: yup
            .string()
            .defined()
            .trim()
            .max(250, ({ max }) => max)
            .required(),
        email: yup
            .string()
            .defined()
            .trim()
            .matches(/^[0-9A-Za-z-._+%]+@[0-9A-Za-z-._+%]+[.]{1}[A-Za-z]{2,}$/gm, {
                name: 'email'
            })
            .max(250, ({ max }) => max)
            .required(),
        sourceRegister: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        registrationOffice: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        registrationNumber: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        protocolBusinessName: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        protocolIco: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        protocolFullAddress: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        deliveryType: yup
            .string()
            .trim()
            .max(250, ({ max }) => max)
            .emptyAsUndefined(),
        // hidden fields
        organizationId: yup.number().emptyAsUndefined(),
        protocolLegalFrom: yup.string().trim().emptyAsUndefined(),
        protocolMunicipality: yup.string().trim().emptyAsUndefined(),
        messageID: yup.string().trim().emptyAsUndefined(),
        correlationID: yup.string().trim().emptyAsUndefined(),
        businessID: yup.string().trim().emptyAsUndefined(),
        objectID: yup.string().trim().emptyAsUndefined(),
        canBeSigned: yup.boolean().default(undefined)
    });

    const orgId = +(organizationUnitId ?? organizationId)!;

    const methods = useForm<RequestExtract>({
        resolver: yupResolver(schema),
        defaultValues: async () =>
            await requestExtractApi
                .fillRequest(orgId)
                .then((re) => {
                    const keys = Object.keys(re) as (keyof typeof re)[];
                    keys.forEach((k) => {
                        const value = re[k];
                        if (value && typeof value === 'string') {
                            // @ts-ignore
                            re[k] = decode(value);
                        }
                    });
                    re.deliveryType = t('extractRequest.deliveryTypeValue');
                    setRequestExtract(re);
                    setIsLoading(false);
                    return re;
                })
                .catch((e) => {
                    setHasError(true);
                    return defaultValue;
                })
    });
    const { handleSubmit, register } = methods;

    const onSubmit = handleSubmit(async (requestExtract, event) => {
        setIsSigning(false);
        setIsSubmitting(false);
        if (event?.target.value === SIGN_AND_SUBMIT) {
            setIsSigning(true);
            try {
                const requestForSigning = await requestExtractApi.prepareRequestForESignature(requestExtract);
                const signedXML = await signAsice(requestForSigning, t('extractRequest.title'));
                const result = await requestExtractApi.sendSignedRequestToEDesk({
                    request: requestForSigning.request,
                    signedXML
                });
                setIsSubmitted(result.response);
            } catch (e: any) {
                setHasError(ditecErrorToString(e));
            } finally {
                setIsSigning(false);
            }
        } else {
            setIsSubmitting(true);
            try {
                const result = await requestExtractApi.sendRequestToEDesk(requestExtract);
                setIsSubmitted(result.response);
            } catch (e) {
                setHasError(true);
            } finally {
                setIsSubmitting(false);
            }
        }
    });

    const isSigningMessage = useMemo(() => {
        if (isSigning) {
            return (
                <Typography type='normal'>
                    <Trans i18nKey='extractRequest.loadingSignerAppMsg'>
                        Počkajte pokiaľ sa spustí aplikácia pre kvalifikovaný elektronický podpis, potom postupujte podľa pokynov v
                        aplikácií. V prípade že aplikáciu ešte nemáte vo vašom počítači nainštalovanú, môžete tak urobiť podľa pokynov na
                        nasledujúcej stránke
                        <a className='govuk-body govuk-link' target='_blank' rel='noreferrer' href={ditec.config.downloadPage.url}>
                            {ditec.config.downloadPage.title}
                        </a>
                        . Po nainštalovaní budete musieť začať proces žiadosti odznova.
                    </Trans>
                </Typography>
            );
        }
        return false;
    }, [isSigning]);

    if (isSubmitted) {
        return (
            <>
                <SuccessInfo title={t('extractRequest.submitted.' + isSubmitted)}>
                    <Button onClick={() => navigate('../')} variant='link' additionalClasses='mc-success-info--button'>
                        {t('extractRequest.backToDetail')}
                    </Button>
                </SuccessInfo>
                <Typography type='small'>
                    <FontAwesomeIcon icon={faInfoCircle} /> {t('extractRequest.messageInEdeskInfo')}
                </Typography>
            </>
        );
    }

    return (
        <Loader
            title={t('extractRequest.title')}
            isLoading={isLoading || isSubmitting || isSigningMessage}
            hasError={hasError}
            onCancel={{
                pathname: '../'
            }}
        >
            <Typography type='h1'>{t('extractRequest.title')}</Typography>

            <Inset>
                <Typography type='bold'>{t('extractRequest.subtitle')}</Typography>
                {t('extractRequest.note')}
            </Inset>

            <FormProvider {...methods}>
                <form noValidate onSubmit={onSubmit}>
                    <Filter
                        title={t('extractRequest.edeskReqApplicant')}
                        initiallyOpen={true}
                        openLabel={t('extractRequest.expand')}
                        closeLabel={t('extractRequest.collapse')}
                    >
                        <Filter
                            title={t('extractRequest.edeskReqLegalEntity')}
                            initiallyOpen={true}
                            section={true}
                            openLabel={t('extractRequest.expandSection')}
                            closeLabel={t('extractRequest.collapseSection')}
                            sectionCategoryName={t('extractRequest.edeskReqLegalEntity')}
                        >
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input name='legalEntityName' label={t('extractRequest.legalEntityName')} />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input name='legalEntityIco' label={t('extractRequest.legalEntityIco')} />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input name='legalEntityRegister' label={t('extractRequest.legalEntityRegister')} />
                                </GridCol>
                            </GridRow>
                        </Filter>
                        <Filter
                            title={t('extractRequest.edeskReqPhysicalPerson')}
                            initiallyOpen={true}
                            section={true}
                            openLabel={t('extractRequest.expandSection')}
                            closeLabel={t('extractRequest.collapseSection')}
                            sectionCategoryName={t('extractRequest.edeskReqPhysicalPerson')}
                        >
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input name='physicalPersonPrefix' label={t('extractRequest.physicalPersonPrefix')} />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input
                                        name='physicalPersonFirstName'
                                        label={t('extractRequest.physicalPersonFirstName')}
                                        readOnly={!!requestExtract?.physicalPersonFirstName}
                                    />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input
                                        name='physicalPersonFamilyName'
                                        label={t('extractRequest.physicalPersonFamilyName')}
                                        readOnly={!!requestExtract?.physicalPersonFamilyName}
                                    />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input name='physicalPersonPostfix' label={t('extractRequest.physicalPersonPostfix')} />
                                </GridCol>
                            </GridRow>
                        </Filter>
                        <Filter
                            title={t('extractRequest.edeskReqResidence')}
                            initiallyOpen={true}
                            section={true}
                            openLabel={t('extractRequest.expandSection')}
                            closeLabel={t('extractRequest.collapseSection')}
                            sectionCategoryName={t('extractRequest.edeskReqResidence')}
                        >
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input name='residenceStreetName' label={t('extractRequest.residenceStreetName')} />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input
                                        name='residenceRegNumberBuildingNumber'
                                        label={t('extractRequest.residenceRegNumberBuildingNumber')}
                                    />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input name='residenceMunicipality' label={t('extractRequest.residenceMunicipality')} />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input name='residencePsc' label={t('extractRequest.residencePsc')} />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input name='residenceCountry' label={t('extractRequest.residenceCountry')} />
                                </GridCol>
                            </GridRow>
                        </Filter>
                        <GridRow>
                            <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                <Input name='email' label={t('extractRequest.email')} />
                            </GridCol>
                        </GridRow>
                    </Filter>
                    <Filter
                        title={t('extractRequest.edeskReqInfoAboutOrg')}
                        initiallyOpen={true}
                        openLabel={t('extractRequest.expand')}
                        closeLabel={t('extractRequest.collapse')}
                    >
                        <Filter
                            title={t('extractRequest.edeskReqLegalEntityRegister')}
                            initiallyOpen={true}
                            section={true}
                            openLabel={t('extractRequest.expandSection')}
                            closeLabel={t('extractRequest.collapseSection')}
                            sectionCategoryName={t('extractRequest.edeskReqLegalEntityRegister')}
                        >
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input readOnly name='sourceRegister' label={t('extractRequest.sourceRegister')} />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input readOnly name='registrationOffice' label={t('extractRequest.registrationOffice')} />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input readOnly name='registrationNumber' label={t('extractRequest.registrationNumber')} />
                                </GridCol>
                            </GridRow>
                        </Filter>
                        <GridRow>
                            <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                <Input readOnly name='protocolBusinessName' label={t('extractRequest.protocolBusinessName')} />
                            </GridCol>
                        </GridRow>
                        <GridRow>
                            <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                <Input readOnly name='protocolIco' label={t('extractRequest.protocolIco')} />
                            </GridCol>
                        </GridRow>
                        <GridRow>
                            <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                <Input readOnly name='protocolFullAddress' label={t('extractRequest.protocolFullAddress')} />
                            </GridCol>
                        </GridRow>
                        <GridRow>
                            <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                <Input readOnly name='deliveryType' label={t('extractRequest.deliveryType')} />
                            </GridCol>
                        </GridRow>
                    </Filter>

                    {requestExtract?.canBeSigned === true && <Inset>{t('extractRequest.paymentNote')}</Inset>}

                    <div className='rpo-org-detail__action-btns'>
                        <Button type='submit'>{t('extractRequest.submit')}</Button>
                        {requestExtract?.canBeSigned === true && (
                            <Button type='button' value={SIGN_AND_SUBMIT} onClick={onSubmit}>
                                {t('extractRequest.signAndSubmit')}
                            </Button>
                        )}
                    </div>

                    <input type='hidden' readOnly {...register('organizationId')} />
                    <input type='hidden' readOnly {...register('protocolLegalFrom')} />
                    <input type='hidden' readOnly {...register('protocolMunicipality')} />
                    <input type='hidden' readOnly {...register('messageID')} />
                    <input type='hidden' readOnly {...register('correlationID')} />
                    <input type='hidden' readOnly {...register('businessID')} />
                    <input type='hidden' readOnly {...register('objectID')} />
                    <input type='hidden' readOnly {...register('canBeSigned')} />
                </form>
            </FormProvider>
        </Loader>
    );
};

export default ExtractRequest;
