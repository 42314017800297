import React, { Fragment, ReactNode, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandableListChildren, ExpandableListItem } from '@mc-ui/idsk-react-components';

import {
    Organization,
    Person,
    Equity,
    Share,
    Deposit,
    RelatedOrganization,
    Activity,
    KuvPersonInfo,
    Register,
    StatisticalCodes,
    Value,
    Address
} from '../../api';
import { addressToString, decode, personNameToString } from '../../utils/string';
import { filterSubstituteDate, isDateFilled } from '../../utils/date';

const ActivityComponent: React.FC<{
    activity: Activity;
}> = ({ activity }) => {
    const { t } = useTranslation();
    const economicActivityDescription = decode(activity.economicActivityDescription);
    const suspendedFrom = filterSubstituteDate(activity.suspendedFrom);
    const suspendedTo = filterSubstituteDate(activity.suspendedTo);
    const some = !!suspendedFrom || !!suspendedTo;

    return (
        <ExpandableListChildren
            validFrom={filterSubstituteDate(activity.establishedDate)}
            validTo={filterSubstituteDate(activity.terminatedDate)}
            localisation={{
                expand: t('orgDetail.showFullContent'),
                collapse: t('orgDetail.hideFullContent'),
                validFrom: t('orgDetail.validFromTitle'),
                validTo: t('orgDetail.validToTitle')
            }}
            secondaryChildren={
                some && (
                    <div className='mc-expandable-list-item__dates'>
                        <div className='mc-expandable-list-item__date'>
                            {suspendedFrom &&
                                t('orgDetail.suspendedFrom', {
                                    suspendedFrom
                                })}
                        </div>
                        <div className='mc-expandable-list-item__date'>
                            {suspendedTo &&
                                t('orgDetail.suspendedTo', {
                                    suspendedTo
                                })}
                        </div>
                    </div>
                )
            }
        >
            {economicActivityDescription}
        </ExpandableListChildren>
    );
};

const AddressComponent: React.FC<{ address: Address }> = ({ address }) => {
    const { t } = useTranslation();
    return (
        <ExpandableListChildren
            localisation={{
                expand: t('orgDetail.showFullContent'),
                collapse: t('orgDetail.hideFullContent'),
                validFrom: t('orgDetail.validFromTitle'),
                validTo: t('orgDetail.validToTitle')
            }}
            validFrom={filterSubstituteDate(address.establishedDate)}
            validTo={filterSubstituteDate(address.terminatedDate)}
        >
            {addressToString(address)}
        </ExpandableListChildren>
    );
};

const DepositComponent: React.FC<{ deposit: Deposit }> = ({ deposit }) => {
    const { t } = useTranslation();
    const fullName = decode(deposit.fullName);
    const personName = personNameToString(deposit.personName);
    const depositType = decode(deposit.depositType);
    const depositAmount = decode(deposit.depositAmount);
    const depositCurrency = decode(deposit.depositCurrency);

    return (
        <ExpandableListChildren
            validFrom={filterSubstituteDate(deposit.establishedDate)}
            validTo={filterSubstituteDate(deposit.terminatedDate)}
            localisation={{
                expand: t('orgDetail.showFullContent'),
                collapse: t('orgDetail.hideFullContent'),
                validFrom: t('orgDetail.validFromTitle'),
                validTo: t('orgDetail.validToTitle')
            }}
        >
            {fullName && (
                <div>
                    {t('orgDetail.depositFullName')} {fullName}
                </div>
            )}
            {personName && (
                <div>
                    {t('orgDetail.depositFullName')} {personName}
                </div>
            )}
            {depositType && (
                <div>
                    {t('orgDetail.depositType')} {depositType}
                </div>
            )}
            {depositAmount && (
                <div>
                    {t('orgDetail.depositAmount')} {depositAmount} {depositCurrency}
                </div>
            )}
        </ExpandableListChildren>
    );
};

const EquityComponent: React.FC<{ equity: Equity }> = ({ equity }) => {
    const { t } = useTranslation();
    const amountOfinvestment = decode(equity.amountOfinvestment);
    const currencyAmount = decode(equity.currencyAmount);
    const paidUp = decode(equity.paidUp);
    const currencyPaid = decode(equity.currencyPaid);
    const approvedAmount = decode(equity.approvedAmount);
    const currencyApproved = decode(equity.currencyApproved);

    return (
        <ExpandableListChildren
            validFrom={filterSubstituteDate(equity.establishedDate)}
            validTo={filterSubstituteDate(equity.terminatedDate)}
            localisation={{
                expand: t('orgDetail.showFullContent'),
                collapse: t('orgDetail.hideFullContent'),
                validFrom: t('orgDetail.validFromTitle'),
                validTo: t('orgDetail.validToTitle')
            }}
        >
            {amountOfinvestment && (
                <div>
                    {t('orgDetail.equityAmount')} {amountOfinvestment} {currencyAmount}
                </div>
            )}
            {paidUp && (
                <div>
                    {t('orgDetail.equityPaidUp')} {paidUp} {currencyPaid}
                </div>
            )}
            {approvedAmount && (
                <div>
                    {t('orgDetail.equityApproved')} {approvedAmount} {currencyApproved}
                </div>
            )}
        </ExpandableListChildren>
    );
};

const KuvPersonInfoComponent: React.FC<{
    kuvPersonInfo: KuvPersonInfo;
}> = ({ kuvPersonInfo }) => {
    const { t } = useTranslation();
    const personName = personNameToString(kuvPersonInfo.personName);
    const country = decode(kuvPersonInfo.country);
    const citizenship = decode(kuvPersonInfo.citizenship);
    const birthDate = filterSubstituteDate(kuvPersonInfo.birthDate);

    return (
        <ExpandableListChildren
            localisation={{
                expand: t('orgDetail.showFullContent'),
                collapse: t('orgDetail.hideFullContent'),
                validFrom: t('orgDetail.validFromTitle'),
                validTo: t('orgDetail.validToTitle')
            }}
        >
            {personName && <div>{personName}</div>}
            {country && (
                <div>
                    {t('orgDetail.country')} {country}
                </div>
            )}
            {citizenship && (
                <div>
                    {t('orgDetail.citizenship')} {citizenship}
                </div>
            )}
            {birthDate && (
                <div>
                    {t('orgDetail.birthDate')} {birthDate}
                </div>
            )}
            <ul className='rpo-kuv-person__list'>
                {kuvPersonInfo.kuvInfo.map((i) => (
                    <li key={i.value}>
                        {decode(i.value)}
                        <div className='mc-expandable-list-item__dates'>
                            <div className='mc-expandable-list-item__date'>
                                {isDateFilled(i.establishedDate) &&
                                    t('orgDetail.validFrom', {
                                        validFrom: i.establishedDate
                                    })}
                            </div>
                            <div className='mc-expandable-list-item__date'>
                                {isDateFilled(i.terminatedDate) &&
                                    t('orgDetail.validTo', {
                                        validTo: i.terminatedDate
                                    })}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </ExpandableListChildren>
    );
};

const PersonComponent: React.FC<{ person: Person }> = ({ person }) => {
    const { t } = useTranslation();
    const stakeholderType = decode(person.stakeholderType);
    const statutoryBodyMember = decode(person.statutoryBodyMember);
    const fullName = decode(person.fullName);
    const ico = decode(person.ico);
    const personName = personNameToString(person.personName);
    const country = decode(person.address?.country);

    return (
        <ExpandableListChildren
            validFrom={filterSubstituteDate(person.establishedDate)}
            validTo={filterSubstituteDate(person.terminatedDate)}
            localisation={{
                expand: t('orgDetail.showFullContent'),
                collapse: t('orgDetail.hideFullContent'),
                validFrom: t('orgDetail.validFromTitle'),
                validTo: t('orgDetail.validToTitle')
            }}
        >
            {stakeholderType && <div>{stakeholderType}</div>}
            {statutoryBodyMember && <div>{statutoryBodyMember}</div>}
            {fullName && <div>{fullName}</div>}
            {ico && (
                <div>
                    {t('orgDetail.ipo')} {ico}
                </div>
            )}
            {personName && <div>{personName}</div>}
            {country && <div>{country}</div>}
        </ExpandableListChildren>
    );
};

const RegisterComponent: React.FC<{
    register: Register;
}> = ({ register }) => {
    const { t } = useTranslation();
    const sourceName = decode(register.sourceName);

    return (
        <section>
            {sourceName && (
                <div className='rpo-flex rpo-two-column-list-item'>
                    <div className='rpo-two-column-list-item__subtitles'>{t('orgDetail.sourceName')}</div>
                    <div>{sourceName}</div>
                </div>
            )}
            {register.regOffices.length > 0 && (
                <div className='rpo-flex rpo-two-column-list-item'>
                    <div className='rpo-two-column-list-item__subtitles'>{t('orgDetail.regOffices')}</div>
                    <div className='rpo-two-column-list-item__data rpo-flex-1'>
                        <ul className='rpo-two-column-list-item__data__list'>
                            {register.regOffices.map((ro) => (
                                <li key={ro.value}>
                                    <div>{decode(ro.value)}</div>
                                    <div className='mc-expandable-list-item__dates'>
                                        <div className='mc-expandable-list-item__date'>
                                            {ro.establishedDate &&
                                                t('orgDetail.validFrom', {
                                                    validFrom: ro.establishedDate
                                                })}
                                        </div>
                                        <div className='mc-expandable-list-item__date'>
                                            {ro.terminatedDate &&
                                                t('orgDetail.validTo', {
                                                    validTo: ro.terminatedDate
                                                })}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            {register.regNumbers.length > 0 && (
                <div className='rpo-flex rpo-two-column-list-item'>
                    <div className='rpo-two-column-list-item__subtitles'>{t('orgDetail.regNumbers')}</div>
                    <div className='rpo-two-column-list-item__data'>
                        {register.regNumbers.map((rn) => (
                            <Fragment key={rn.value}>
                                <div>{decode(rn.value)}</div>
                                <div className='mc-expandable-list-item__dates'>
                                    <div className='mc-expandable-list-item__date'>
                                        {rn.establishedDate &&
                                            t('orgDetail.validFrom', {
                                                validFrom: rn.establishedDate
                                            })}
                                    </div>
                                    <div className='mc-expandable-list-item__date'>
                                        {rn.terminatedDate &&
                                            t('orgDetail.validTo', {
                                                validTo: rn.terminatedDate
                                            })}
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
            )}
        </section>
    );
};

const RelatedOrganizationComponent: React.FC<{
    relatedOrg: RelatedOrganization;
}> = ({ relatedOrg }) => {
    const { t } = useTranslation();
    const fullName = decode(relatedOrg.fullName);
    const ico = decode(relatedOrg.ico);
    const address = addressToString(relatedOrg.address);

    return (
        <ExpandableListChildren
            validFrom={filterSubstituteDate(relatedOrg.establishedDate)}
            validTo={filterSubstituteDate(relatedOrg.terminatedDate)}
            localisation={{
                expand: t('orgDetail.showFullContent'),
                collapse: t('orgDetail.hideFullContent'),
                validFrom: t('orgDetail.validFromTitle'),
                validTo: t('orgDetail.validToTitle')
            }}
        >
            {fullName && (
                <div>
                    {t('orgDetail.fullName')} {fullName}
                </div>
            )}
            {ico && (
                <div>
                    {t('orgDetail.ipo')} {ico}
                </div>
            )}
            {address && <div>{address}</div>}
        </ExpandableListChildren>
    );
};

const ShareComponent: React.FC<{ share: Share }> = ({ share }) => {
    const { t } = useTranslation();
    const fullName = decode(share.fullName);
    const personName = personNameToString(share.personName);
    const shareType = decode(share.shareType);
    const shareForm = decode(share.shareForm);
    const sharePrice = decode(share.sharePrice);
    const priceCurrency = decode(share.priceCurrency);
    const shareAmount = decode(share.shareAmount);
    const shareTransfer = decode(share.shareTransfer);

    return (
        <ExpandableListChildren
            validFrom={filterSubstituteDate(share.establishedDate)}
            validTo={filterSubstituteDate(share.terminatedDate)}
            localisation={{
                expand: t('orgDetail.showFullContent'),
                collapse: t('orgDetail.hideFullContent'),
                validFrom: t('orgDetail.validFromTitle'),
                validTo: t('orgDetail.validToTitle')
            }}
        >
            {fullName && (
                <div>
                    {t('orgDetail.shareName')} {fullName}
                </div>
            )}
            {personName && (
                <div>
                    {t('orgDetail.shareName')} {personName}
                </div>
            )}
            {shareType && (
                <div>
                    {t('orgDetail.shareType')} {shareType}
                </div>
            )}
            {shareForm && (
                <div>
                    {t('orgDetail.shareForm')} {shareForm}
                </div>
            )}
            {sharePrice && (
                <div>
                    {t('orgDetail.sharePrice')} {sharePrice} {priceCurrency}
                </div>
            )}
            {shareAmount && (
                <div>
                    {t('orgDetail.shareAmount')} {shareAmount}
                </div>
            )}
            {shareTransfer && (
                <div>
                    {t('orgDetail.shareNegotiability')} {shareTransfer}
                </div>
            )}
        </ExpandableListChildren>
    );
};

const StatisticalCodesComponent: React.FC<{
    statisticalCodes: StatisticalCodes;
}> = ({ statisticalCodes: sc }) => {
    const { t } = useTranslation();

    return (
        <section>
            <div className='rpo-flex rpo-two-column-list-item'>
                <div className='rpo-two-column-list-item__subtitles'>{t('orgDetail.sourceName')}</div>
                <div>Register organizácii ŠÚ SR</div>
            </div>
            {Object.keys(sc).map(
                (sck) =>
                    sc[sck as keyof StatisticalCodes].length > 0 && (
                        <div className='rpo-flex rpo-two-column-list-item' key={sck}>
                            <div className='rpo-two-column-list-item__subtitles'>{t('orgDetail.' + sck)}</div>
                            <div className='rpo-two-column-list-item__data rpo-flex-1'>
                                <ul className='rpo-two-column-list-item__data__list'>
                                    {sc[sck as keyof typeof sc].map((ma, idx) => (
                                        <li key={ma.code ?? idx}>
                                            <div>{`${decode(ma.code)} - ${decode(ma.name)}`}</div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )
            )}
        </section>
    );
};

const StringArrayComponent: React.FC<{ strings: string[] }> = ({ strings }) => {
    const { t } = useTranslation();
    return (
        <ExpandableListChildren
            localisation={{
                expand: t('orgDetail.showFullContent'),
                collapse: t('orgDetail.hideFullContent'),
                validFrom: t('orgDetail.validFromTitle'),
                validTo: t('orgDetail.validToTitle')
            }}
        >
            <ul className='rpo-org-detail__error-list'>
                {strings.map((v) => (
                    <li key={v}>{decode(v)}</li>
                ))}
            </ul>
        </ExpandableListChildren>
    );
};

const StringComponent: React.FC<{ string: string }> = ({ string }) => {
    const { t } = useTranslation();
    return (
        <ExpandableListChildren
            localisation={{
                expand: t('orgDetail.showFullContent'),
                collapse: t('orgDetail.hideFullContent'),
                validFrom: t('orgDetail.validFromTitle'),
                validTo: t('orgDetail.validToTitle')
            }}
        >
            {decode(string)}
        </ExpandableListChildren>
    );
};

const ValueComponent: React.FC<{ value: Value }> = ({ value }) => {
    const { t } = useTranslation();
    return (
        <ExpandableListChildren
            validFrom={filterSubstituteDate(value.establishedDate)}
            validTo={filterSubstituteDate(value.terminatedDate)}
            localisation={{
                expand: t('orgDetail.showFullContent'),
                collapse: t('orgDetail.hideFullContent'),
                validFrom: t('orgDetail.validFromTitle'),
                validTo: t('orgDetail.validToTitle')
            }}
        >
            {decode(value.value)}
        </ExpandableListChildren>
    );
};

const BooleanComponent: React.FC<{ value: boolean }> = ({ value }) => {
    const { t } = useTranslation();
    return (
        <ExpandableListChildren
            localisation={{
                expand: t('orgDetail.showFullContent'),
                collapse: t('orgDetail.hideFullContent'),
                validFrom: t('orgDetail.validFromTitle'),
                validTo: t('orgDetail.validToTitle')
            }}
        >
            {value === true ? t('orgDetail.booleanValues.true') : t('orgDetail.booleanValues.false')}
        </ExpandableListChildren>
    );
};

const OrganizationAttribute: React.FC<{
    attribute: keyof Organization;
    organization: Organization;
}> = ({ attribute, organization }) => {
    const value = organization[attribute];
    const { t } = useTranslation();
    const id = useId();

    if (
        value === undefined ||
        value === null ||
        (Array.isArray(value) && value.length === 0) ||
        ((attribute === 'establishedDate' || attribute === 'terminatedDate') && !isDateFilled(organization[attribute] as string))
    ) {
        return null;
    }

    let content: ReactNode = null;

    switch (attribute) {
        case 'id':
        case 'hasOrganizationUnit':
        case 'hasOperations':
        case 'mainOrganizationId':
        case 'extractInProgress':
        case 'hasErrors':
            content = null;
            break;
        case 'ipo':
        case 'fullName':
        case 'alterName':
        case 'legalForms':
        case 'legalStatus':
        case 'otherLegalFacts':
        case 'lei':
        case 'authorization':
            content = organization[attribute]!.map((v, idx) => {
                return <ValueComponent key={idx} value={v} />;
            });
            break;
        case 'establishedDate':
        case 'terminatedDate':
        case 'dic':
        case 'icdph':
        case 'orgUnitType':
        case 'actualizationDate':
        case 'statCodesActualization':
            content = <StringComponent string={organization[attribute]!} />;
            break;
        case 'address':
            content = organization[attribute].map((v, idx) => {
                return <AddressComponent key={idx} address={v} />;
            });
            break;
        case 'activity':
            content = organization[attribute].map((v, idx) => {
                return <ActivityComponent key={idx} activity={v} />;
            });
            break;
        case 'statutory':
        case 'stakeholder':
            content = organization[attribute].map((v, idx) => {
                return <PersonComponent key={idx} person={v} />;
            });
            break;
        case 'kuvPersonInfo':
        case 'topManagementInfo':
            content = organization[attribute]!.map((v, idx) => {
                return <KuvPersonInfoComponent key={idx} kuvPersonInfo={v} />;
            });
            break;
        case 'equity':
            content = organization[attribute].map((v, idx) => {
                return <EquityComponent key={idx} equity={v} />;
            });
            break;
        case 'shares':
            content = organization[attribute]!.map((v, idx) => {
                return <ShareComponent key={idx} share={v} />;
            });
            break;
        case 'deposit':
            content = organization[attribute]!.map((v, idx) => {
                return <DepositComponent key={idx} deposit={v} />;
            });
            break;
        case 'predecessor':
        case 'successor':
            content = organization[attribute].map((v, idx) => {
                return <RelatedOrganizationComponent key={idx} relatedOrg={v} />;
            });
            break;
        case 'sourceRegister':
            content = organization[attribute]!.map((v, idx) => {
                return <RegisterComponent key={idx} register={v} />;
            });
            break;
        case 'statisticalCodes':
            content = organization[attribute]!.map((v, idx) => {
                return <StatisticalCodesComponent key={idx} statisticalCodes={v} />;
            });
            break;
        case 'kuvErrors':
        case 'subjectErrors':
            content = <StringArrayComponent strings={organization[attribute]} />;
            break;
        case 'actOnItsOwnBehalf':
            content = organization[attribute] !== null ? <BooleanComponent value={organization[attribute]!} /> : null;
            break;
    }

    if (content === null) {
        return null;
    }

    if (attribute === 'sourceRegister' || attribute === 'statisticalCodes') {
        return (
            <div className='mc-expandable-list-item rpo-two-column-list-item__wrapper'>
                <dt className='mc-expandable-list-item__header'>
                    <div className='mc-expandable-list-item__header-heading' id={'expandable-list-heading-' + id + '__source-register'}>
                        {t('orgDetail.attribute.' + attribute.toString())}
                    </div>
                </dt>
                <dt aria-labelledby={'expandable-list-heading-' + id + '__source-register'} className='mc-expandable-list-item__content'>
                    {content}
                </dt>
            </div>
        );
    }

    return (
        <ExpandableListItem
            title={t('orgDetail.attribute.' + attribute.toString())}
            localisation={{
                itemsCount: t('orgDetail.itemsCount'),
                showMoreItems: t('orgDetail.showMoreItems')
            }}
            paginationLocalisation={{
                goto: t('table.pagination.goto'),
                next: t('table.pagination.next'),
                prev: t('table.pagination.prev')
            }}
        >
            {content}
        </ExpandableListItem>
    );
};

export default OrganizationAttribute;
