import { Typography } from '@mc-ui/idsk-react-components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const Declaration: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <Typography type='h1'>{t('declaration.title')}</Typography>
            <Typography type='normal'>{t('declaration.note_1')}</Typography>
            <Typography type='normal'>
                <Trans i18nKey='declaration.note_2'>
                    Toto vyhlásenie o&nbsp;prístupnosti sa vzťahuje na webové sídlo
                    <a href='https://rpo.statistics.sk' target='_blank' className='govuk-link' rel='noreferrer'>
                        https://rpo.statistics.sk
                    </a>
                    .
                </Trans>
            </Typography>
            <Typography type='h2'>{t('declaration.complianceTitle')}</Typography>
            <Typography type='normal'>{t('declaration.compliance')}</Typography>
            <Typography type='h2'>{t('declaration.developmentTitle')}</Typography>
            <Typography type='normal'>{t('declaration.development1')}</Typography>
            <Typography type='normal'>{t('declaration.development2')}</Typography>
            <Typography type='normal'>{t('declaration.development3')}</Typography>
            <Typography type='h2'>{t('declaration.feedbackTitle')}</Typography>
            <Typography type='normal'>
                <Trans i18nKey='declaration.feedback'>
                    V&nbsp;prípade akýchkoľvek problémov s&nbsp;prístupnosťou webového sídla nás, prosím, kontaktujte na e-mailovej adrese:
                    <a href='mailto:portal@statistics.sk' className='govuk-link'>
                        portal@statistics.sk
                    </a>
                    . Správcom a&nbsp;prevádzkovateľom webového sídla je Štatistický úrad Slovenskej republiky.
                </Trans>
            </Typography>
            <Typography type='h2'>{t('declaration.enforcementTitle')}</Typography>
            <Typography type='normal'>
                <Trans i18nKey='declaration.enforcement'>
                    V&nbsp;prípade neuspokojivých odpovedí na podnety alebo žiadosti zaslané v&nbsp;rámci spätnej väzby subjektu verejného
                    sektora v&nbsp;súlade s&nbsp;čl.&nbsp;7&nbsp;ods.&nbsp;1 písm. b) smernice Európskeho parlamentu 2016/2102 sa môžete
                    obrátiť v&nbsp;rámci vynucovacieho konania na subjekt poverený presadzovaním Smernice, ktorým je Ministerstvo
                    investícií, regionálneho rozvoja a&nbsp;informatizácie Slovenskej republiky a&nbsp;informatizáciu na e-mailovej adrese:
                    <a href='mailto:standard@mirri.gov.sk' className='govuk-link'>
                        standard@mirri.gov.sk
                    </a>
                    .
                </Trans>
            </Typography>
        </>
    );
};

export default Declaration;
