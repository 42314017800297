import React, { useCallback, useContext, useEffect, useId, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { Button, Pagination, Select, Table, Typography } from '@mc-ui/idsk-react-components';

import { SearchApi, OrganizationSearchCriteria, OrganizationResult, UserRole } from '../../api';
import { AuthContext } from '../../store/auth-context';
import { searchParamsToOrganizationSearchCriteria } from '../../utils/searchParams';
import Loader from '../../components/common/Loader';
import { PAGE_SIZES, RESTORE_STATE } from '../../utils/contants';
import { FilterField, filterOrganizationResults, sortOrganizationResults } from '../../utils/organizationResult';
import useOrganizationResultsFilter from '../../hooks/useOrganizationResultsFilter';
import { restoreState } from '../../utils/state';

const searchApi = new SearchApi();

type State = {
    search?: string;
    filterForm?: Partial<OrganizationResult>;
    filter?: FilterField[];
    sort?: string | null;
    page?: number;
    pageSize?: number;
    wide?: boolean;
};

const prevState: State = {};

const OrganizationSearchResults: React.FC = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { state, search: locationSearch } = useLocation();
    const search = restoreState(state, prevState, 'search', locationSearch);
    const { t } = useTranslation();
    const { protect, hasRole } = useContext(AuthContext);
    const uniqueId = useId();
    const tableId = 'table' + uniqueId;

    const isRpoRead = hasRole(UserRole.RPO_READ);

    const searchCriteria = useMemo(() => searchParamsToOrganizationSearchCriteria(new URLSearchParams(search)), [search]);
    const { showHistorical } = searchCriteria;
    const [wide, setWide] = useState(restoreState(state, prevState, 'wide', false));
    const [page, setPage] = useState(restoreState(state, prevState, 'page', 0));
    const [pageSize, setPageSize] = useState<number>(restoreState(state, prevState, 'pageSize', +PAGE_SIZES[0].value));
    const [sort, setSort] = useState<string | null>(restoreState(state, prevState, 'sort', null));

    const [data, setData] = useState<OrganizationResult[]>([]);
    const [orgIds, setOrgIds] = useState<number[]>([]);
    const [showQuestion, setShowQuestion] = useState<boolean>(false);
    const [loadingFirst, setLoadingFirst] = useState(true);
    const [loadingRest, setLoadingRest] = useState(true);
    const [hasError, setHasError] = useState<boolean>(false);

    const { headerCols, filterContent, filterCount, removeAllHandler, removeOneHandler, filter, filterForm } = useOrganizationResultsFilter(
        showHistorical,
        wide,
        restoreState(state, prevState, 'filter', undefined),
        restoreState(state, prevState, 'filterForm', undefined)
    );

    const onFullscreenHandler = useCallback(() => {
        setWide((p) => !p);
    }, []);

    const filteredData = useMemo(() => filterOrganizationResults(data, filter), [data, filter]);

    const sortedData = useMemo(() => sortOrganizationResults(filteredData, sort), [filteredData, sort]);

    const pagedData = useMemo(() => sortedData.slice(page * pageSize, (page + 1) * pageSize), [pageSize, page, sortedData]);

    useEffect(() => {
        return () => {
            prevState.search = search;
            prevState.sort = sort;
            prevState.page = page;
            prevState.pageSize = pageSize;
            prevState.wide = wide;
            prevState.filter = filter;
            prevState.filterForm = filterForm;
        };
    }, [search, sort, page, pageSize, wide, filter, filterForm]);

    useEffect(() => {
        if (locationSearch !== '') {
            // search only if search params provided
            setHasError(false);
            protect(() =>
                queryClient
                    .fetchQuery({
                        queryKey: ['search', searchCriteria, !isRpoRead],
                        queryFn: ({ queryKey, signal }) => {
                            return searchApi.searchOrganizations(queryKey[1] as OrganizationSearchCriteria, queryKey[2] as boolean, signal);
                        }
                    })
                    .then((data) => {
                        setLoadingFirst(false);
                        setData(data.results);
                        if (data.organizationIds) {
                            if (state !== RESTORE_STATE && data.isLimited) {
                                setShowQuestion(data.isLimited);
                            }
                            setOrgIds(data.organizationIds);
                        } else {
                            setShowQuestion(false);
                            setLoadingRest(false);
                        }
                    })
                    .catch((e) => {
                        if (!e.revert) {
                            setHasError(true);
                        }
                    })
            );
        }
    }, [searchCriteria, locationSearch, navigate, protect, queryClient, state, isRpoRead]);

    useEffect(() => {
        if (showQuestion === false && orgIds && orgIds.length > 0) {
            protect(() =>
                queryClient
                    .fetchQuery({
                        queryKey: ['byIds', orgIds, !isRpoRead],
                        queryFn: ({ queryKey, signal }) => {
                            return searchApi.getAllResults(queryKey[1] as number[], queryKey[2] as boolean, signal);
                        }
                    })
                    .then((data) => {
                        setData(data.results);
                        setLoadingRest(false);
                    })
                    .catch((e) => {
                        if (!e.revert) {
                            throw e;
                        }
                    })
            );
        }
    }, [orgIds, protect, queryClient, showQuestion, isRpoRead]);

    // If state is restore (going back using breadcrumb), check if search params is empty and than set search params from previouse remembered component state.
    // if state is not restore and search params is empty, go back to home page
    useEffect(() => {
        if (state === RESTORE_STATE && locationSearch === '' && prevState.search && prevState.search !== '') {
            navigate(
                {
                    search: prevState.search
                },
                {
                    replace: true,
                    state
                }
            );
        } else if (locationSearch === '') {
            navigate('/');
        }
    }, [state, locationSearch, navigate]);

    const question = useMemo(() => {
        if (showQuestion) {
            return {
                text: t('orgSearchResults.tooManyResults'),
                onConfirm: '/',
                onConfirmState: RESTORE_STATE,
                onCancel: () => setShowQuestion(false),
                confirmTitle: t('orgSearchResults.specifyCriteria'),
                cancelTitle: t('orgSearchResults.showLimited')
            };
        }
    }, [showQuestion, t]);

    return (
        <Loader
            title={t('table.heading.title')}
            isLoading={loadingFirst}
            hasError={hasError}
            question={question}
            onCancel='/'
            onCancelState={RESTORE_STATE}
        >
            <Table
                tableId={tableId}
                filter={{
                    title: t('orgSearchResults.table.filter.filterTitle'),
                    children: filterContent,
                    openLabel: t('orgSearchResults.table.filter.openFilterLabel'),
                    closeLabel: t('orgSearchResults.table.filter.closeFilterLabel'),
                    activeFilter: {
                        filters: filter,
                        onRemoveAll: removeAllHandler,
                        onRemove: removeOneHandler,
                        closeLabel: t('orgSearchResults.table.activeFilter.closeLabel'),
                        openLabel: t('orgSearchResults.table.activeFilter.openLabel'),
                        removeAllLabel: t('orgSearchResults.table.activeFilter.removeAllLabel'),
                        removeFilterLabel: t('orgSearchResults.table.activeFilter.removeFilterLabel'),
                        title: t('orgSearchResults.table.activeFilter.title')
                    },
                    initiallyOpen: filterCount !== 0
                }}
                contentAbove={
                    <div className='rpo-search-results__page-size '>
                        <Select
                            value={pageSize}
                            onChange={(e) => setPageSize(+e.target.value)}
                            options={PAGE_SIZES}
                            label={t('table.pagination.pageSize')}
                        />
                        <Button variant='secondary' onClick={onFullscreenHandler} aria-expanded={wide} aria-controls={tableId}>
                            {t(wide ? 'table.heading.narrow' : 'table.heading.fullscreen')}
                        </Button>
                        <div className='rpo-flex-1'></div>
                        <Typography type='normal' forcedElementType='div'>
                            {pagedData.length > 0
                                ? t('table.pagination.shownRecords', {
                                      recordStart: page * pageSize + 1,
                                      recordEnd: page * pageSize + pagedData.length,
                                      records: loadingRest ? t('table.pagination.loading') : filteredData.length
                                  })
                                : t('table.pagination.noRecords')}
                        </Typography>
                    </div>
                }
                heading={{
                    title: t('table.heading.title'),
                    headingType: 'h1'
                }}
                header={{
                    cols: headerCols,
                    localisation: {
                        ascendingLabel: t('table.header.ascendingLabel'),
                        descendingLabel: t('table.header.descendingLabel'),
                        unsortedLabel: t('table.header.unsortedLabel')
                    },
                    onSort: setSort,
                    sorted: sort
                }}
                meta={{
                    actions: (
                        <Pagination
                            page={page}
                            onPageChange={setPage}
                            numOfPages={Math.ceil(filteredData.length / pageSize)}
                            localisation={{
                                prev: t('table.pagination.prev'),
                                next: t('table.pagination.next'),
                                goto: t('table.pagination.goto')
                            }}
                        />
                    )
                }}
                data={pagedData}
                id='id'
                wide={wide}
            />
        </Loader>
    );
};

export default OrganizationSearchResults;
