import { CreateKuvRegReturnObject } from '../model/CreateKuvRegReturnObject';
import { PoPersonData } from '../model/PoPersonData';
import { PoRequest } from '../model/PoRequest';
import { PoRequestResults } from '../model/PoRequestResults';
import { PoRequestSearchCriteria } from '../model/PoRequestSearchCriteria';
import { PoSignedRequest } from '../model/PoSignedRequest';
import { ReqFile } from '../model/ReqFile';
import { handleJSON, handleText } from './common';

export class PoRequestApi {
    async searchPoRequest(criteria: PoRequestSearchCriteria, signal?: AbortSignal): Promise<PoRequestResults> {
        const res = await fetch(ENV.BASE_API_PATH + '/poRequest/getRequests/query', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(criteria),
            signal
        });
        return await handleJSON(res);
    }

    async getPoRequest(requestId: number, signal?: AbortSignal): Promise<PoRequest | null> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/poRequest/getRequest', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                method: 'POST',
                body: new URLSearchParams({
                    requestId: requestId.toString()
                }),
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }

    async uploadFile(file: File, signal?: AbortSignal): Promise<ReqFile | null> {
        try {
            const formData = new FormData();
            formData.append(file.name, file);
            const res = await fetch(ENV.BASE_API_PATH + '/poRequest/upload', {
                method: 'POST',
                body: formData,
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }

    async completeRequest(request: PoRequest, signal?: AbortSignal): Promise<CreateKuvRegReturnObject | null> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/poRequest/updateRequest', {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(request),
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }

    async createRegistration(request: PoRequest, signal?: AbortSignal): Promise<CreateKuvRegReturnObject | null> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/poRequest/registration', {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(request),
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }

    async createDeregistration(request: PoRequest, signal?: AbortSignal): Promise<CreateKuvRegReturnObject | null> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/poRequest/deregistration', {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(request),
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }

    async createReset(request: PoRequest, signal?: AbortSignal): Promise<CreateKuvRegReturnObject | null> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/poRequest/reset', {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(request),
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }

    async signed(request: PoSignedRequest, signal?: AbortSignal): Promise<void> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/poRequest/signed', {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(request),
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return;
            }
            throw e;
        }
    }

    async getPersonDataUpvs(signal?: AbortSignal): Promise<PoPersonData | null> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/poRequest/getPersonDataUpvs', {
                method: 'POST',
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }

    async getRequestYear(signal?: AbortSignal): Promise<number[]> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/poRequest/getRequestYear', {
                method: 'POST',
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return [];
            }
            throw e;
        }
    }

    async getMainIcoFromToken(signal?: AbortSignal): Promise<string> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/poRequest/getMainIcoFromToken', {
                method: 'POST',
                signal
            });
            return await handleText(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return '';
            }
            throw e;
        }
    }
}
