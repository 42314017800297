import { formatIsoDateToddmmyyyy, formatddmmyyyyToIsoDate } from '../../utils/date';
import { CreatePoRequestExtract } from '../model/CreatePoRequestExtract';
import { ExtractResource } from '../model/ExtractResource';
import { ExtractReturnObject } from '../model/ExtractReturnObject';
import { PoExtractRequest } from '../model/PoExtractRequest';
import { PoPersonData } from '../model/PoPersonData';
import { handleJSON, handleText } from './common';

export class PoRequestExtractApi {
    async createXmlFromRequest(request: PoExtractRequest, signal?: AbortSignal): Promise<ExtractReturnObject | null> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/poRequestExtract/createXmlFromRequest', {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    ...request,
                    validFrom: request.validFrom ? formatIsoDateToddmmyyyy(request.validFrom) : null,
                    validTo: request.validTo ? formatIsoDateToddmmyyyy(request.validTo) : null
                }),
                signal
            });
            console.log(res);
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }

    async savePoRequestExtract(request: CreatePoRequestExtract, signal?: AbortSignal): Promise<string | null> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/poRequestExtract/savePoRequestExtract', {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(request),
                signal
            });
            return await handleText(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }

    async getPersonDataUpvs(signal?: AbortSignal): Promise<PoPersonData | null> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/poRequestExtract/getPersonDataUpvs', {
                method: 'POST',
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }

    async searchOrganizationByICO(ico: number, signal?: AbortSignal): Promise<ExtractResource[] | null> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + `/poRequestExtract/searchOrganizationByICO/${ico}`, {
                method: 'POST',
                signal
            });
            const data = await handleJSON(res);
            const formattedData = data.map((d: ExtractResource) => {
                return {
                    ...d,
                    establishedDate: formatddmmyyyyToIsoDate(d.establishedDate),
                    terminatedDate: formatddmmyyyyToIsoDate(d.terminatedDate)
                };
            });
            return formattedData;
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }
}
