import { handleText } from './common';

export class SystemApi {
    async getSystemVersion(signal?: AbortSignal): Promise<string> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/system/version', {
                method: 'POST',
                signal
            });
            return await handleText(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return '';
            }
            throw e;
        }
    }
}
