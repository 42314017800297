import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Notice, Inset, Typography, GridRow, GridCol } from '@mc-ui/idsk-react-components';

import OrganizationSearch from '../components/organizationSearch/OrganizationSearch';
import License from '../components/common/License';
import { decode } from '../utils/string';
import { NoticeApi, UserRole } from '../api';
import { useQueryClient } from '@tanstack/react-query';
import { AuthContext } from '../store/auth-context';

const noticeApi = new NoticeApi();

const Home: React.FC = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { hasRole, isAuthenticated } = useContext(AuthContext);
    const isRpoRead = hasRole(UserRole.RPO_READ);
    const [notices, setNotices] = useState<JSX.Element[]>([]);

    useEffect(() => {
        queryClient
            .fetchQuery({
                queryKey: ['notice'],
                queryFn: ({ signal }) => noticeApi.getActualNoticeDetails(signal)
            })
            .then((data) => {
                if (data) {
                    setNotices(
                        data.map((n) => (
                            <Notice key={n.name + n.text} type={n.outage ? 'error' : 'info'} title={decode(n.name)}>
                                {decode(n.text)}
                            </Notice>
                        ))
                    );
                }
            })
            .catch((e) => console.error(e));
    }, [queryClient]);

    return (
        <>
            <Typography type='h1'>{t('home.title')}</Typography>
            {notices && (
                <GridRow>
                    <GridCol size='2/3'>{notices}</GridCol>
                </GridRow>
            )}
            <Inset>{t('home.note')}</Inset>
            {(!isAuthenticated || isRpoRead) && <OrganizationSearch />}
            {/* <RegisterOvm /> */}
            <License />
        </>
    );
};

export default Home;
