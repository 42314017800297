import { ChangeEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Filter, GridCol, GridRow, RadioGroup, RadioItem, SuccessInfo, Typography } from '@mc-ui/idsk-react-components';

import Loader from '../components/common/Loader';
import { Input } from '../components/form/Input';
import { DatePicker } from '../components/form/DatePicker';
import useSigner from '../hooks/useSigner';
import { addressToString, decode } from '../utils/string';
import { PoRequestExtractApi } from '../api/apis/PoRequestExtractApi';
import { PoExtractRequest } from '../api/model/PoExtractRequest';
import { CreatePoRequestExtract } from '../api/model/CreatePoRequestExtract';
import { ExtractResource } from '../api/model/ExtractResource';
import { useNavigate } from 'react-router-dom';

const poRequestExtractApi = new PoRequestExtractApi();

const defaultValue: PoExtractRequest = {
    applicantOrganizationName: null,
    applicantOrganizationIdentifier: '',
    personName: null,
    organizationIdentifier: null,
    organizationName: '',
    address: null,
    sourceRegister: '',
    validFrom: null,
    validTo: null
};

const PoRequestExtract: React.FC = () => {
    const { t } = useTranslation();
    const { signAsice, ditecErrorToString } = useSigner();
    const navigate = useNavigate();
    const [isSigning, setIsSigning] = useState(false);
    const [hasError, setHasError] = useState<boolean | string | ReactElement>(false);
    const [organizations, setOrganizations] = useState<ExtractResource[] | null>(null);
    const [selectedOrganization, setSelectedOrganization] = useState<ExtractResource | null>();
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState<string | null>(null);

    const methods = useForm<PoExtractRequest>({
        defaultValues: async () => {
            try {
                const personData = await poRequestExtractApi.getPersonDataUpvs();
                if (personData) {
                    const organizationByIco = await poRequestExtractApi.searchOrganizationByICO(+personData.identifier);
                    if (organizationByIco && organizationByIco.length === 1) {
                        setSelectedOrganization(organizationByIco[0]);
                        return {
                            applicantOrganizationName: decode(personData.pofullName),
                            applicantOrganizationIdentifier: decode(personData.identifier),
                            personName: decode(personData.formattedName),
                            organizationIdentifier: decode(organizationByIco[0].identifierValue),
                            organizationName: decode(organizationByIco[0].fullName),
                            address: addressToString(organizationByIco[0].address) ?? null,
                            sourceRegister: decode(organizationByIco[0].sourceRegister),
                            validFrom: organizationByIco[0].establishedDate,
                            validTo: organizationByIco[0].terminatedDate
                        };
                    } else {
                        setOrganizations(organizationByIco);
                        return {
                            applicantOrganizationName: decode(personData.pofullName),
                            applicantOrganizationIdentifier: decode(personData.identifier),
                            personName: decode(personData.formattedName),
                            organizationIdentifier: defaultValue.organizationIdentifier,
                            organizationName: defaultValue.organizationName,
                            address: defaultValue.address,
                            sourceRegister: defaultValue.sourceRegister,
                            validFrom: defaultValue.validFrom,
                            validTo: defaultValue.validTo
                        };
                    }
                } else return defaultValue;
            } catch (e) {
                setHasError(ditecErrorToString(e));
                return defaultValue;
            } finally {
                setIsLoading(false);
            }
        }
    });
    const { handleSubmit, setValue } = methods;

    const onSubmit = handleSubmit(async (data: PoExtractRequest) => {
        setIsSigning(true);
        try {
            const requestForSigning = await poRequestExtractApi.createXmlFromRequest(data);
            if (requestForSigning && selectedOrganization) {
                const formattedRequestForSigning = {
                    xml: requestForSigning.xmlForSigner,
                    xsd: requestForSigning.xsdForSigner,
                    xslt: requestForSigning.xsltForSigner,
                    namespaceUri: requestForSigning.namespaceUri,
                    xsdUrl: requestForSigning.xsdReferenceUri,
                    xsltUrl: requestForSigning.xsltReferenceUri,
                    version: requestForSigning.version
                };
                const signedXML = await signAsice(formattedRequestForSigning, t('poRequestExtract.title'));
                const sendData: CreatePoRequestExtract = {
                    id: requestForSigning.id,
                    organizationId: selectedOrganization.id,
                    organizationIdentifierValue: selectedOrganization.identifierValue,
                    xml: signedXML
                };
                const result = await poRequestExtractApi.savePoRequestExtract(sendData);
                setIsSubmitted(result);
            }
        } catch (e) {
            console.error(e);
            setHasError(ditecErrorToString(e));
        } finally {
            setIsSigning(false);
        }
    });

    const isSigningMessage = useMemo(() => {
        if (isSigning) {
            return (
                <Typography type='normal'>
                    <Trans i18nKey='poRequestExtract.loadingSignerAppMsg'>
                        Počkajte pokiaľ sa spustí aplikácia pre kvalifikovaný elektronický podpis, potom postupujte podľa pokynov v
                        aplikácií. V prípade že aplikáciu ešte nemáte vo vašom počítači nainštalovanú, môžete tak urobiť podľa pokynov na
                        nasledujúcej stránke
                        <a className='govuk-body govuk-link' target='_blank' rel='noreferrer' href={ditec.config.downloadPage.url}>
                            {ditec.config.downloadPage.title}
                        </a>
                        . Po nainštalovaní budete musieť začať proces žiadosti odznova.
                    </Trans>
                </Typography>
            );
        }
        return false;
    }, [isSigning]);

    const choosenOrganization = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const index = +event.currentTarget.value;
            if (organizations) {
                setSelectedOrganization(organizations[index]);
                setValue('organizationIdentifier', organizations[index].identifierValue);
                setValue('organizationName', organizations[index].fullName);
                setValue('address', addressToString(organizations[index].address) ?? null);
                setValue('sourceRegister', organizations[index].sourceRegister);
                setValue('validFrom', organizations[index].establishedDate);
                setValue('validTo', organizations[index].terminatedDate);
            }
        },
        [organizations, setValue]
    );

    const onCancel = useCallback(() => {
        if (isLoading) {
            navigate('/');
        } else {
            setHasError(false);
            setIsSigning(false);
            setSelectedOrganization(null);
            setValue('organizationIdentifier', defaultValue.organizationIdentifier);
            setValue('organizationName', defaultValue.organizationName);
            setValue('address', defaultValue.address);
            setValue('sourceRegister', defaultValue.sourceRegister);
            setValue('validFrom', defaultValue.validFrom);
            setValue('validTo', defaultValue.validTo);
        }
    }, [setValue, isLoading, navigate]);

    if (isSubmitted) {
        return <SuccessInfo title={t('poRequestExtract.submitted') + isSubmitted} />;
    }

    return (
        <Loader title={t('poRequestExtract.title')} isLoading={isLoading || isSigningMessage} hasError={hasError} onCancel={onCancel}>
            <Typography type='h1'>{t('poRequestExtract.title')}</Typography>
            {organizations && organizations.length > 1 && (
                <div className='rpo-block'>
                    <Typography type='normal'>
                        <span className='rpo-warn'>*</span> <span className='rpo-secondary'>{t('poRequestExtract.hint')}</span>
                    </Typography>
                    <GridRow>
                        <GridCol size='full'>
                            <RadioGroup legend={t('poRequestExtract.organization')} legendHeadingType='h2' required legendSize='m'>
                                {organizations.map((o, index) => {
                                    return (
                                        <RadioItem
                                            key={o.id}
                                            label={
                                                <>
                                                    <p className='rpo-margin-t-none'>{o.identifierValue}</p>
                                                    <p>{o.fullName}</p>
                                                    <p>{addressToString(o.address)}</p>
                                                    <p>{o.sourceRegister}</p>
                                                </>
                                            }
                                            value={index}
                                            name='organization'
                                            onChange={choosenOrganization}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </GridCol>
                    </GridRow>
                </div>
            )}
            <FormProvider {...methods}>
                <form noValidate onSubmit={onSubmit} autoComplete='off'>
                    <Filter
                        title={t('poRequestExtract.organizationAndPersonData')}
                        initiallyOpen={true}
                        openLabel={t('poRequestExtract.expand')}
                        closeLabel={t('poRequestExtract.collapse')}
                    >
                        <Filter
                            title={t('poRequestExtract.personData')}
                            initiallyOpen={true}
                            section={true}
                            openLabel={t('poRequestExtract.expandSection')}
                            closeLabel={t('poRequestExtract.collapseSection')}
                            sectionCategoryName={t('poRequestExtract.personData')}
                        >
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Input
                                        name='applicantOrganizationName'
                                        label={t('poRequestExtract.applicantOrganizationName')}
                                        readOnly
                                    />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Input
                                        name='applicantOrganizationIdentifier'
                                        label={t('poRequestExtract.applicantOrganizationIdentifier')}
                                        readOnly
                                    />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Input name='personName' label={t('poRequestExtract.personName')} readOnly />
                                </GridCol>
                            </GridRow>
                        </Filter>

                        <Filter
                            title={t('poRequestExtract.organizationData')}
                            initiallyOpen={true}
                            section={true}
                            openLabel={t('poRequestExtract.expandSection')}
                            closeLabel={t('poRequestExtract.collapseSection')}
                            sectionCategoryName={t('poRequestExtract.organizationData')}
                        >
                            <GridRow>
                                <GridCol size='1/2' additionalClass='idsk-table-filter__filter-inputs'>
                                    <Input name='organizationIdentifier' label={t('poRequestExtract.organizationIdentifier')} readOnly />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Input name='organizationName' label={t('poRequestExtract.organizationName')} readOnly />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Input name='address' label={t('poRequestExtract.address')} readOnly />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <Input name='sourceRegister' label={t('poRequestExtract.sourceRegister')} readOnly />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <DatePicker name='validFrom' label={t('poRequestExtract.validFrom')} readOnly />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol size='1/2'>
                                    <DatePicker name='validTo' label={t('poRequestExtract.validTo')} readOnly />
                                </GridCol>
                            </GridRow>
                        </Filter>
                    </Filter>
                    <div className='rpo-org-detail__action-btns'>
                        <Button type='submit' disabled={!selectedOrganization}>
                            {t('poRequestExtract.submit')}
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Loader>
    );
};

export default PoRequestExtract;
