export const handleJSON = async (res: Response) => {
    if (res && res.ok) {
        if (res.status === 204) {
            return null;
        }
        return await res.json();
    }

    const error = await res.json().catch(() => null);
    throw new Error(error?.message ?? res.statusText);
};

export const handleText = async (res: Response) => {
    if (res && res.ok) {
        if (res.status === 204) {
            return '';
        }
        return await res.text();
    }

    const error = await res.json().catch(() => null);
    throw new Error(error?.message ?? res.statusText);
};

type SortArray<Q> = { attribute: keyof Q; order: 'ASC' | 'DESC' }[];

export const sortAdapter = <Q>(sort: string[]): SortArray<Q> => {
    return sort
        .map((s) => {
            const splited = s.split(',');
            if (splited.length === 2) {
                return {
                    attribute: splited[0] as keyof Q,
                    order: splited[1].toUpperCase() as 'ASC' | 'DESC'
                };
            } else if (splited.length === 1) {
                return {
                    attribute: splited[0] as keyof Q,
                    order: 'ASC' as 'ASC' | 'DESC'
                };
            }
            return null;
        })
        .filter((s) => s !== null) as SortArray<Q>;
};
