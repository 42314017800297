import React, { useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mc-ui/idsk-react-components';

type InitializationProps = {
    visible: boolean;
};

const Initialization: React.FC<InitializationProps> = ({ visible }) => {
    const ref = useRef<HTMLDivElement>(null);
    const id = useId();
    const { t } = useTranslation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const handleTransition = (ev: TransitionEvent) => {
            ev.stopPropagation();
            setInitialized(true);
        };
        const element = ref.current;
        if (element) {
            element.addEventListener('transitionend', handleTransition);
        }
        return () => {
            element?.removeEventListener('transitionend', handleTransition);
        };
    }, [ref]);

    if (initialized) {
        return null;
    }

    return (
        <div
            className={`rpo-initialization ${visible ? '' : 'rpo-initialization__hide'}`}
            role='alert'
            aria-busy='true'
            aria-describedby={id}
            ref={ref}
        >
            <div className='rpo-initialization-content' id={id}>
                <Typography type='h1'>{t('header.main.appName')}</Typography>
                <Typography type='normal' forcedElementType='div'>
                    {t('init.loading')}
                </Typography>
            </div>
        </div>
    );
};

export default Initialization;
