import * as yup from 'yup';
import { NumberSchema, StringSchema, ObjectSchema, ArraySchema } from 'yup';

const isEmpty = (obj: any): boolean => {
    // pre undefined a null vrat true
    if (obj === undefined || obj === null || obj === 'null') {
        return true;
    }
    // ak je prazdny string, vrat true
    if (typeof obj === 'string' || obj instanceof String) {
        return obj.trim() === '';
    }
    // ak je prazdny number, vrat true
    if (typeof obj === 'number' || obj instanceof Number) {
        return obj === null;
    }
    // ak je prazdne pole vrat true
    if (Array.isArray(obj)) {
        if (obj.length === 0) {
            return true;
        }
        return obj.every(isEmpty);
    }
    // ak je objekty otestuj jeho hodnoty
    if (!Array.isArray(obj) && typeof obj === 'object') {
        return Object.values(obj).findIndex((v) => !isEmpty(v)) === -1;
    }
    throw new Error('Chyba validácie, nepodporovaný typ objektu: ' + typeof obj);
};

yup.addMethod(StringSchema, 'emptyAsUndefined', function () {
    return this.transform((val) => (val === '' ? undefined : val)).default(undefined);
});

yup.addMethod(NumberSchema, 'emptyAsUndefined', function () {
    return this.transform((val, oVal) => (oVal === '' || oVal === null ? undefined : val)).default(undefined);
});

yup.addMethod(ObjectSchema, 'emptyAsUndefined', function () {
    return this.transform((val) => {
        return isEmpty(val) ? undefined : val;
    }).default(undefined);
});

yup.addMethod(ArraySchema, 'emptyAsUndefined', function () {
    return this.transform((val) => {
        if (isEmpty(val)) {
            return undefined;
        } else {
            return val;
        }
    }).default(undefined);
});
