import { OrganizationResult } from '../model';
import { Organization } from '../model/Organization';
import { OrganizationSearchCriteria } from '../model/OrganizationSearchCriteria';
import { SearchResult } from '../model/SearchResult';
import { handleJSON } from './common';
import { formatIsoDateToddmmyyyy } from '../../utils/date';

export class SearchApi {
    async searchOrganizations(criteria: OrganizationSearchCriteria, isPublic: boolean, signal?: AbortSignal): Promise<SearchResult> {
        const res = await fetch(ENV.BASE_API_PATH + '/search/search' + (isPublic ? '/anonym' : ''), {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                query: {
                    ...criteria,
                    fullTextSearch: [criteria.fullTextSearch],
                    showHistorical: [criteria.showHistorical],
                    showOrganizationUnit: [criteria.showOrganizationUnit],
                    terminatedFrom: formatIsoDateToddmmyyyy(criteria.terminatedFrom),
                    terminatedTo: formatIsoDateToddmmyyyy(criteria.terminatedTo),
                    establishedFrom: formatIsoDateToddmmyyyy(criteria.establishedFrom),
                    establishedTo: formatIsoDateToddmmyyyy(criteria.establishedTo)
                }
            }),
            signal
        });
        return await handleJSON(res);
    }

    async getAllResults(organizationIds: number[], isPublic: boolean, signal?: AbortSignal): Promise<SearchResult> {
        const res = await fetch(ENV.BASE_API_PATH + '/search/getAllResults' + (isPublic ? '/anonym' : ''), {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                organizationIds: organizationIds
            }),
            signal
        });
        return await handleJSON(res);
    }

    async getOrganizationByIdPublic(
        organizationId: number,
        showHistory: boolean = false,
        isPublic: boolean,
        signal?: AbortSignal
    ): Promise<Organization | null> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + '/search/getOrganization' + (isPublic ? '/anonym' : ''), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                method: 'POST',
                body: new URLSearchParams({
                    organizationId: organizationId.toString(),
                    showHistory: showHistory.toString()
                }),
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }

    async getOrganizationUnitsByOrganizationIdPublic(
        organizationId: number,
        operation: boolean,
        showHistory: boolean,
        isPublic: boolean,
        signal?: AbortSignal
    ): Promise<Array<OrganizationResult>> {
        const res = await fetch(ENV.BASE_API_PATH + '/search/getorgunits' + (isPublic ? '/anonym' : ''), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            body: new URLSearchParams({
                organizationId: organizationId.toString(),
                operation: operation.toString(),
                showHistory: showHistory.toString()
            }),
            signal
        });
        return await handleJSON(res);
    }
}
