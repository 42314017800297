import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Checkbox, GridCol, GridRow, Inset, Typography } from '@mc-ui/idsk-react-components';

import License from '../components/common/License';
import { CookieContext } from '../store/cookie-context';

const Cookies: React.FC = () => {
    const { t } = useTranslation();
    const cookiesCtx = useContext(CookieContext);
    const navigate = useNavigate();
    const [analyticsCookie, setAnalyticsCookie] = useState(cookiesCtx.analyticsAllowed);

    const onSaveHandler = () => {
        cookiesCtx.setAnalyticsAllowed(analyticsCookie);
        navigate('/');
    };

    return (
        <>
            <Typography type='h1'>{t('cookies.title')}</Typography>
            <GridRow>
                <GridCol size='full'>
                    <Checkbox label={t('cookies.basicLabel')} checked={true} disabled={true} />
                </GridCol>
            </GridRow>
            <Inset>{t('cookies.basic')}</Inset>
            {ENV.G_ANALYTICS_ENABLED && (
                <>
                    <GridRow>
                        <GridCol size='full'>
                            <Checkbox
                                label={t('cookies.analyticsLabel')}
                                checked={analyticsCookie}
                                onChange={(e) => setAnalyticsCookie(e.target.checked)}
                            />
                        </GridCol>
                    </GridRow>
                    <Inset>{t('cookies.analytics')}</Inset>
                    <Button type='button' onClick={onSaveHandler} additionalClasses='rpo-no-margin'>
                        {t('cookies.save')}
                    </Button>
                </>
            )}
            <License />
        </>
    );
};

export default Cookies;
