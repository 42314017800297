import React, { useCallback, useContext, useEffect, useId, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { PAGE_SIZES, RESTORE_STATE, WITH_HISTORY } from '../../utils/contants';
import { Button, Pagination, Select, Table, Typography } from '@mc-ui/idsk-react-components';
import OrganizationAttributes from '../../components/organizationSearch/OrganizationAttributes';
import useOrganization from '../../hooks/useOrganization';
import Loader from '../../components/common/Loader';
import { useQueryClient } from '@tanstack/react-query';
import { AuthContext } from '../../store/auth-context';
import { OrganizationResult, SearchApi, UserRole } from '../../api';
import { isNotBlank } from '../../utils/string';
import { FilterField, filterOrganizationResults, sortOrganizationResults } from '../../utils/organizationResult';
import { useTranslation } from 'react-i18next';
import useOrganizationResultsFilter from '../../hooks/useOrganizationResultsFilter';

const searchApi = new SearchApi();

type State = {
    filterForm?: Partial<OrganizationResult>;
    filter?: FilterField[];
    sort?: string | null;
    page?: number;
    pageSize?: number;
    wide?: boolean;
};

const prevState: State = {};

const restoreState = <T extends keyof State, D>(state: any, key: T, defaultValue: D): NonNullable<State[T]> | D => {
    if (state === RESTORE_STATE) {
        const restored = prevState[key];
        if (restored !== undefined && restored !== null) {
            return restored;
        }
    }
    return defaultValue;
};

const OrganizationUnits: React.FC<{
    isOperation?: boolean;
}> = ({ isOperation = false }) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { t } = useTranslation();
    const { organizationId, withHistory } = useParams();
    const { protect, hasRole } = useContext(AuthContext);

    const isRpoRead = hasRole(UserRole.RPO_READ);

    const [hasUnitsError, setHasUnitsError] = useState<boolean>(false);
    const [isUnitsLoading, setIsUnitsLoading] = useState(true);
    const [wide, setWide] = useState(restoreState(state, 'wide', false));
    const [page, setPage] = useState(restoreState(state, 'page', 0));
    const [pageSize, setPageSize] = useState<number>(restoreState(state, 'pageSize', +PAGE_SIZES[0].value));
    const [sort, setSort] = useState<string | null>(restoreState(state, 'sort', null));
    const [data, setData] = useState<OrganizationResult[]>([]);
    const uniqueId = useId();
    const tableId = 'table' + uniqueId;

    const { isLoading, organization, hasError } = useOrganization();

    const showHistorical = withHistory === WITH_HISTORY;

    const { headerCols, filterContent, filterCount, removeAllHandler, removeOneHandler, filter, filterForm } = useOrganizationResultsFilter(
        showHistorical,
        wide,
        restoreState(state, 'filter', undefined),
        restoreState(state, 'filterForm', undefined)
    );

    const onFullscreenHandler = useCallback(() => {
        setWide((p) => !p);
    }, []);

    const filteredData = useMemo(() => filterOrganizationResults(data, filter), [data, filter]);

    const sortedData = useMemo(() => sortOrganizationResults(filteredData, sort), [filteredData, sort]);

    const pagedData = useMemo(() => sortedData.slice(page * pageSize, (page + 1) * pageSize), [pageSize, page, sortedData]);

    useEffect(() => {
        return () => {
            prevState.sort = sort;
            prevState.page = page;
            prevState.pageSize = pageSize;
            prevState.wide = wide;
            prevState.filter = filter;
            prevState.filterForm = filterForm;
        };
    }, [sort, page, pageSize, wide, filter, filterForm]);

    useEffect(() => {
        if (isNotBlank(organizationId)) {
            const numericId = Number(organizationId);
            if (!isNaN(numericId)) {
                setHasUnitsError(false);
                protect(() =>
                    queryClient
                        .fetchQuery({
                            queryKey: ['searchUnits', numericId, isOperation, withHistory === WITH_HISTORY, !isRpoRead],
                            queryFn: ({ queryKey, signal }) => {
                                return searchApi.getOrganizationUnitsByOrganizationIdPublic(
                                    +(queryKey[1] as string),
                                    queryKey[2] as boolean,
                                    queryKey[3] as boolean,
                                    queryKey[4] as boolean,
                                    signal
                                );
                            }
                        })
                        .then((data) => {
                            setIsUnitsLoading(false);
                            setData(data);
                        })
                        .catch((e) => {
                            if (!e.revert) {
                                setHasUnitsError(true);
                            }
                        })
                );
            }
        }
    }, [organizationId, withHistory, navigate, protect, queryClient, isOperation, isRpoRead]);

    const translatePrefix = isOperation ? 'operations' : 'orgUnits';

    return (
        <Loader
            title={t(`${translatePrefix}.title`)}
            isLoading={isLoading || isUnitsLoading}
            hasError={hasError || hasUnitsError}
            onCancel='/'
        >
            <Typography type='h1'>{t(`${translatePrefix}.title`)}</Typography>
            <Typography type='bold'>{t(`${translatePrefix}.mainOrganizationData`)}</Typography>
            <OrganizationAttributes organization={organization} basicView={true} />

            <Table
                tableId={tableId}
                filter={{
                    title: t('orgSearchResults.table.filter.filterTitle'),
                    children: filterContent,
                    openLabel: t('orgSearchResults.table.filter.openFilterLabel'),
                    closeLabel: t('orgSearchResults.table.filter.closeFilterLabel'),
                    activeFilter: {
                        filters: filter,
                        onRemoveAll: removeAllHandler,
                        onRemove: removeOneHandler,
                        closeLabel: t('orgSearchResults.table.activeFilter.closeLabel'),
                        openLabel: t('orgSearchResults.table.activeFilter.openLabel'),
                        removeAllLabel: t('orgSearchResults.table.activeFilter.removeAllLabel'),
                        removeFilterLabel: t('orgSearchResults.table.activeFilter.removeFilterLabel'),
                        title: t('orgSearchResults.table.activeFilter.title')
                    },
                    initiallyOpen: filterCount !== 0
                }}
                contentAbove={
                    <div className='rpo-search-results__page-size '>
                        <Select
                            value={pageSize}
                            onChange={(e) => setPageSize(+e.target.value)}
                            options={PAGE_SIZES}
                            label={t('table.pagination.pageSize')}
                        />
                        <Button variant='secondary' onClick={onFullscreenHandler} aria-expanded={wide} aria-controls={tableId}>
                            {t(wide ? 'table.heading.narrow' : 'table.heading.fullscreen')}
                        </Button>
                        <div className='rpo-flex-1'></div>
                        <Typography type='normal' forcedElementType='div'>
                            {t('table.pagination.shownRecords', {
                                recordStart: page * pageSize + 1,
                                recordEnd: page * pageSize + pagedData.length,
                                records: filteredData.length
                            })}
                        </Typography>
                    </div>
                }
                heading={{
                    title: t(`${translatePrefix}.items`, {
                        count: data.length
                    }),
                    headingType: 'bold'
                }}
                header={{
                    cols: headerCols,
                    localisation: {
                        ascendingLabel: t('table.header.ascendingLabel'),
                        descendingLabel: t('table.header.descendingLabel'),
                        unsortedLabel: t('table.header.unsortedLabel')
                    },
                    onSort: setSort,
                    sorted: sort
                }}
                meta={{
                    actions: (
                        <Pagination
                            page={page}
                            onPageChange={setPage}
                            numOfPages={Math.ceil(filteredData.length / pageSize)}
                            localisation={{
                                prev: t('table.pagination.prev'),
                                next: t('table.pagination.next'),
                                goto: t('table.pagination.goto')
                            }}
                        />
                    )
                }}
                data={pagedData}
                id='id'
                wide={wide}
            />
        </Loader>
    );
};
export default OrganizationUnits;
