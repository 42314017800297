import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, GridCol, GridRow, Notice, Typography } from '@mc-ui/idsk-react-components';

import { AuthContext } from '../store/auth-context';
import { LoginRequest } from '../api';
import { Input } from '../components/form/Input';
import License from '../components/common/License';
import Loader from '../components/common/Loader';

const schema = yup.object({
    username: yup.string().trim().required(),
    password: yup.string().trim().required()
});

const Login: React.FC = () => {
    const { t } = useTranslation();
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);

    const methods = useForm<LoginRequest>({
        resolver: yupResolver(schema),
        defaultValues: {
            username: '',
            password: ''
        }
    });
    const {
        handleSubmit,
        formState: { isSubmitting }
    } = methods;

    const onSubmit = handleSubmit(async (data: LoginRequest) => {
        await authCtx
            .login(data)
            .then(() => {
                navigate('/');
            })
            .catch((e) => {
                setError(e.message);
            });
    });

    useEffect(() => {
        if (authCtx.user) {
            navigate('/'); // if already logged in, redirect from login screen to home
        }
    }, [navigate, authCtx.user]);

    return (
        <Loader title={t('login.title')} isLoading={isSubmitting} onCancel='/'>
            <Typography type='h1'>{t('login.title')}</Typography>
            {error && (
                <Notice type='error' title={t('error.error')}>
                    {error}
                </Notice>
            )}
            <FormProvider {...methods}>
                <form noValidate onSubmit={onSubmit}>
                    <GridRow>
                        <GridCol size='1/2'>
                            <Input name='username' label={t('login.username')} />
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol size='1/2'>
                            <Input name='password' label={t('login.password')} type='password' />
                        </GridCol>
                    </GridRow>
                    <Button type='submit' iconPosition='end' icon={'arrowRight'} additionalClasses='rpo-no-margin'>
                        <b>{t('login.loginBtn')}</b>
                    </Button>
                </form>
            </FormProvider>
            <License />
        </Loader>
    );
};

export default Login;
