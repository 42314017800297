import { Button, ButtonGroup, GridCol, GridRow, Typography } from '@mc-ui/idsk-react-components';
import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '../../components/form/Autocomplete';
import { Select } from '../../components/form/Select';
import { CodelistApi } from '../../api';
import { codelistCodeToLabel } from '../../utils/string';
import { PoRequestApi } from '../../api/apis/PoRequestApi';
import Loader from '../../components/common/Loader';
import { MandatoryPersonApi } from '../../api/apis/MandatoryPersonApi';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PoRequestSearchCriteria } from '../../api/model/PoRequestSearchCriteria';
import { Input } from '../../components/form/Input';
import { objectToSearchParams } from '../../utils/searchParams';
import { useLocation, useNavigate } from 'react-router-dom';
import { RESTORE_STATE } from '../../utils/contants';
import { CodelistAcronymName } from '../../hooks/useCachedCodelist';
import { useQueryClient } from '@tanstack/react-query';

type FetchCodelistCodes = (
    label: string,
    page: number,
    pageSize: number,
    signal: AbortSignal
) => Promise<
    {
        value: string;
        label: string;
    }[]
>;

type FetchCodelistCode = (
    value: string,
    signal: AbortSignal
) => Promise<{
    value: string;
    label: string;
}>;

const schema = yup.object({
    poOrganizationIdentifier: yup.string().trim().defined().required(),
    poName: yup.string().trim().nullable().emptyAsUndefined(),
    poRequestTypeId: yup.number().nullable().emptyAsUndefined(),
    poRequestStatusId: yup.number().defined().required(),
    poRequestNumber: yup.number().typeError('number').nullable().emptyAsUndefined(),
    poRequestNumberYear: yup.number().nullable().emptyAsUndefined()
});

const formDefaultValues = {
    poOrganizationIdentifier: '',
    poName: null,
    poRequestTypeId: null,
    poRequestStatusId: NaN,
    poRequestNumber: null,
    poRequestNumberYear: null
};

const prevState: { query?: PoRequestSearchCriteria['query'] } = {};

const codelistApi = new CodelistApi();
const poRequestApi = new PoRequestApi();
const mandatoryPersonApi = new MandatoryPersonApi();

const SearchRequest: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [years, setYears] = useState<number[]>([]);
    const [mainIco, setMainIco] = useState<string>();
    const [loadingError, setLoadingError] = useState<boolean>(false);
    const queryClient = useQueryClient();

    const [defaultValues, setDefaultValues] = useState<PoRequestSearchCriteria['query']>(formDefaultValues);

    const yearOptions = useMemo(() => {
        if (!years) {
            return [];
        }
        const mappedYears = years.map((year) => ({
            label: year.toString(),
            value: year.toString()
        }));

        mappedYears.unshift({
            label: ' ',
            value: ''
        });
        return mappedYears;
    }, [years]);

    const fetchPoNames = useCallback(
        async (name: string, page: number, pageSize: number, signal: AbortSignal) => {
            if (!mainIco) {
                throw new Error('Identifier is required!');
            }
            const data = await mandatoryPersonApi.getMandatoryPersonNames(
                {
                    query: {
                        identifier: mainIco,
                        name: name
                    },
                    start: page * pageSize,
                    count: pageSize,
                    sort: []
                },
                signal
            );
            // TODO chybova hlaska ak nejde server ?
            return (
                data.results?.map((poNames) => {
                    return {
                        value: poNames.name,
                        label: poNames.name
                    };
                }) ?? []
            );
        },
        [mainIco]
    );

    const fetchPoName = useCallback(async (value: string) => {
        return {
            value: value ?? '',
            label: value
        };
    }, []);

    const fetchCodelistCodes = useCallback(
        (codelistAcronymName: 'REGISTER_APPL_TYPE' | 'REGISTER_APPL_STATUS'): FetchCodelistCodes =>
            async (label, page, pageSize, signal) => {
                const data = await codelistApi.getCodelistCodesPublic(
                    {
                        count: pageSize,
                        start: page * pageSize,
                        sort: [],
                        query: {
                            codelistAcronymName,
                            label,
                            skippedIds: []
                        }
                    },
                    false,
                    signal
                );
                // TODO chybova hlaska ak nejde server ?
                return (
                    data.results?.map((clc) => {
                        return {
                            value: clc.codeId?.toString() ?? '',
                            label: codelistCodeToLabel(clc)
                        };
                    }) ?? []
                );
            },
        []
    );

    const fetchCodelistCode = useCallback<FetchCodelistCode>(async (value, signal) => {
        const clc = await codelistApi.getCodelistCodeById(+value, signal);
        // TODO chybova hlaska ak nejde server ?
        return {
            value: clc?.codeId.toString() ?? '',
            label: codelistCodeToLabel(clc)
        };
    }, []);

    const fetchCodelistCodeId = useCallback(() => {
        return queryClient.fetchQuery({
            queryKey: ['codelistItem', 'ID', CodelistAcronymName.REGISTER_APPL_STATUS, '3'] as const,
            queryFn: ({ queryKey, signal }) =>
                codelistApi
                    .getCodelistCodesPublic(
                        {
                            query: {
                                skippedIds: [],
                                codelistAcronymName: queryKey[2],
                                code: queryKey[3],
                                validOnly: true
                            },
                            count: 1,
                            sort: [],
                            start: 0
                        },
                        false,
                        signal
                    )
                    .then((res) => {
                        if (res.results && res.results.length === 1) {
                            return res.results[0].codeId;
                        }
                        return undefined;
                    })
        });
    }, [queryClient]);

    const methods = useForm<PoRequestSearchCriteria['query']>({
        resolver: yupResolver(schema),
        defaultValues: async () => {
            setLoadingError(false);
            try {
                const years = await poRequestApi.getRequestYear();
                const mainIco = await poRequestApi.getMainIcoFromToken();
                const actualYear = new Date().getFullYear();
                const poRequestStatusId = (await fetchCodelistCodeId()) ?? NaN;
                setYears(years);
                setMainIco(mainIco);
                const updatedDefaults = {
                    ...defaultValues,
                    poOrganizationIdentifier: mainIco,
                    poRequestStatusId: poRequestStatusId,
                    poRequestNumberYear: years.includes(actualYear) ? actualYear : null
                };
                setDefaultValues(updatedDefaults);
                if (state === RESTORE_STATE && prevState.query) {
                    return {
                        ...updatedDefaults,
                        ...prevState.query
                    };
                }
                return updatedDefaults;
            } catch (e) {
                setLoadingError(true);
                return defaultValues;
            }
        }
    });
    const {
        handleSubmit,
        reset,
        formState: { isLoading }
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        prevState.query = data;
        const searchParams = objectToSearchParams(data);
        navigate('/mandatoryPerson/results?' + searchParams.toString());
    });

    const onReset = useCallback(() => {
        reset(defaultValues);
    }, [reset, defaultValues]);

    return (
        <Loader
            title={t('mandatoryPerson.search')}
            isLoading={isLoading}
            hasError={loadingError}
            onCancel={{
                pathname: '/'
            }}
        >
            <Typography type='h1'>{t('mandatoryPerson.search')}</Typography>

            <FormProvider {...methods}>
                <form noValidate onSubmit={onSubmit}>
                    <GridRow>
                        <GridCol size='1/2'>
                            <Input
                                name='poOrganizationIdentifier'
                                label={t('mandatoryPerson.poOrganizationIdentifier')}
                                hint={t('mandatoryPerson.poOrganizationIdentifierHint')}
                                disabled
                                required
                            />
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol size='1/2'>
                            <Autocomplete
                                name='poName'
                                label={t('mandatoryPerson.poName')}
                                fetchPage={fetchPoNames}
                                fetchSingle={fetchPoName}
                            />
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol size='1/2'>
                            <Autocomplete
                                name='poRequestTypeId'
                                label={t('mandatoryPerson.poRequestTypeId')}
                                fetchPage={fetchCodelistCodes('REGISTER_APPL_TYPE')}
                                fetchSingle={fetchCodelistCode}
                            />
                        </GridCol>
                        <GridCol size='1/2'>
                            <Autocomplete
                                name='poRequestStatusId'
                                label={t('mandatoryPerson.poRequestStatusId')}
                                fetchPage={fetchCodelistCodes('REGISTER_APPL_STATUS')}
                                fetchSingle={fetchCodelistCode}
                                required
                            />
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol size='1/2'>
                            <Input name='poRequestNumber' label={t('mandatoryPerson.poRequestNumber')} />
                        </GridCol>
                        <GridCol size='1/2'>
                            <Select label={t('mandatoryPerson.poRequestNumberYear')} name='poRequestNumberYear' options={yearOptions} />
                        </GridCol>
                    </GridRow>

                    <ButtonGroup>
                        <Button type='submit'>{t('mandatoryPerson.searchBtn')}</Button>
                        <Button type='button' variant='secondary' onClick={onReset}>
                            {t('mandatoryPerson.cancelBtn')}
                        </Button>
                    </ButtonGroup>
                </form>
            </FormProvider>
        </Loader>
    );
};

export default SearchRequest;
