import React from 'react';

import { ExpandableList } from '@mc-ui/idsk-react-components';

import OrganizationAttribute from './OrganizationAttribute';
import { Organization } from '../../api';

type OrganizationAttributesProps = {
    organization: Organization | null;
    basicView?: boolean;
};

const OrganizationAttributes: React.FC<OrganizationAttributesProps> = ({ organization, basicView = false }) => {
    if (!organization) {
        return null;
    }

    if (basicView) {
        return (
            <ExpandableList>
                {Object.keys(organization)
                    .filter((a) => ['ipo', 'fullName', 'address'].includes(a))
                    .map((a) => (
                        <OrganizationAttribute key={a} attribute={a as keyof Organization} organization={organization} />
                    ))}
            </ExpandableList>
        );
    }

    return (
        <ExpandableList>
            {Object.keys(organization).map((a) => (
                <OrganizationAttribute key={a} attribute={a as keyof Organization} organization={organization} />
            ))}
        </ExpandableList>
    );
};

export default OrganizationAttributes;
