export enum UserRole {
    RPO_ADMIN = 'rpo_admin',
    RPO_REGISTRATION = 'rpo_registration',
    RPO_READ = 'rpo_read',
    RPO_SYNC = 'rpo_sync',
    RPO_ISVS_ADMIN = 'rpo_isvs_admin',
    RPO_ANALYSIS = 'rpo_analysis',
    RPO_SUPERADMIN = 'rpo_superadmin',
    RPO_GUI_GENERATE = 'rpo_gui_generate',
    RPO_OVM = 'rpo_ovm',
    RPO_NOTIFICATION = 'rpo_notification',
    RPO_KUV_ADMIN = 'rpo_kuv_admin',
    RPO_KUV_REGISTRATION = 'rpo_kuv_registration',
    RPO_KUV_WS_READ = 'rpo_kuv_ws_read',
    RPO_KUV_WS_OVM = 'rpo_kuv_ws_ovm',
    RPO_KUV_PO_ADMIN = 'rpo_kuv_po_admin',
    RPO_KUV_READ = 'rpo_kuv_read',
    RPO_KUV_OVM_ADMIN = 'rpo_kuv_ovm_admin',
    RPO_KUV_OVM = 'rpo_kuv_ovm',
    RPO_KUV_FSJ_ADMIN = 'rpo_kuv_fsj_admin',
    RPO_KUV_FSJ = 'rpo_kuv_fsj',
    RPO_KUV_FSJ_BATCH = 'rpo_kuv_fsj_batch',
    RPO_KUV_PO_REPRESENTATIVE = 'rpo_kuv_po_representative',
    RPO_SYNC_HISTORY = 'rpo_sync_history',
    RPO_ISVS_ADMIN_HISTORY = 'rpo_isvs_admin_history',
    RPO_OVM_HISTORY = 'rpo_ovm_history',
    RPO_GENERATE = 'rpo_generate',
    RPO_ISVS_ADMIN_V2 = 'rpo_isvs_admin_v2',
    RPO_ISVS_ADMIN_HISTORY_V2 = 'rpo_isvs_admin_history_v2',
    RPO_SYNC_V2 = 'rpo_sync_v2',
    RPO_SYNC_HISTORY_V2 = 'rpo_sync_history_v2',
    RPO_NOTICE = 'rpo_notice',
    RPO_KUV_BORIS = 'rpo_kuv_boris',
    RPO_QUALITY_ADMIN = 'rpo_quality_admin'
}

export type User = {
    login: string;
    roles: UserRole[];
    external: boolean;
};

export type ExtendedUser = User & {
    isUPVS: boolean;
};
