import { handleJSON } from './common';
import { CodelistCode } from '../model/CodelistCode';
import { CodelistCodeSearchResult } from '../model/CodelistCodeSearchResult';
import { CodelistCodesCriteria } from '../model/CodelistCodesCriteria';

export class CodelistApi {
    async getCodelistCodesPublic(
        criteria: CodelistCodesCriteria,
        isPublic: boolean,
        signal?: AbortSignal
    ): Promise<CodelistCodeSearchResult> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + `/codelist/codes${isPublic ? '/anonym' : ''}/query`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    ...criteria,
                    query: {
                        ...criteria.query,
                        label: criteria.query.label ? criteria.query.label : '*'
                    }
                }),
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return { totalCount: 0, results: [] };
            }
            throw e;
        }
    }

    async getCodelistCodeById(codeId: number, signal?: AbortSignal): Promise<CodelistCode | null> {
        try {
            const res = await fetch(ENV.BASE_API_PATH + `/codelist/codes/get/${codeId}`, {
                method: 'POST',
                signal
            });
            return await handleJSON(res);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return null;
            }
            throw e;
        }
    }
}
