import React from 'react';
import { useFormContext } from 'react-hook-form';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

interface WithReactHookFormProps {
    name: string;
    label?: string;
}

export const withReactHookForm =
    <P extends object>(Component: React.ComponentType<P & { error?: string }>): React.FC<P & WithReactHookFormProps> =>
    ({ name, label, ...props }: WithReactHookFormProps) => {
        const { t } = useTranslation();

        const {
            register,
            formState: { errors }
        } = useFormContext();

        const errorObj = get(errors, name);

        const errorMsg =
            errorObj &&
            t(`error.${errorObj.type}`, {
                label: label,
                message: t(`error.${errorObj.message}`, {
                    defaultValue: errorObj.message
                })
            });

        return <Component {...(props as P)} label={label} {...register(name)} error={errorMsg} />;
    };
