import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

interface WithReactHookFormControlledProps {
    name: string;
    label?: string;
}

export const withReactHookFormControlled =
    <P extends { localisation?: any } & { [key: string]: any }>(
        Component: React.ComponentType<P & { error?: string }>,
        localisation?: P['localisation']
    ): React.FC<Omit<P, 'name' | 'value' | 'onChange'> & Partial<Pick<P, 'onChange'>> & WithReactHookFormControlledProps> =>
    ({ name, label, ...props }: WithReactHookFormControlledProps) => {
        const { t } = useTranslation();

        const transaltedLocalisation = useMemo(() => {
            if (!localisation || Object.keys(localisation).length === 0) {
                return undefined;
            }
            return Object.entries(localisation).reduce(
                (prev, curr) => {
                    prev[curr[0]] = t(curr[1]);
                    return prev;
                },
                {} as Record<string, string>
            );
        }, [t]);

        const {
            control,
            formState: { errors }
        } = useFormContext();

        const errorObj = get(errors, name);
        const errorMsg =
            errorObj &&
            t(`error.${errorObj.type}`, {
                label: label,
                message: errorObj.message
            });

        const onChangeExternal = useMemo(() => {
            if ('onChange' in props) {
                return props.onChange;
            }
        }, [props]);

        const onBlurExternal = useMemo(() => {
            if ('onBlur' in props) {
                return props.onBlur;
            }
        }, [props]);

        return (
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Component
                        {...(props as P)}
                        localisation={transaltedLocalisation}
                        label={label}
                        value={value}
                        onChange={(p: any) => {
                            onChange(p);
                            if (typeof onChangeExternal === 'function') {
                                onChangeExternal(p);
                            }
                        }}
                        onBlur={() => {
                            onBlur();
                            if (typeof onBlurExternal === 'function') {
                                onBlurExternal();
                            }
                        }}
                        error={errorMsg}
                    />
                )}
            />
        );
    };
